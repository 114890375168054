import React, { useEffect, useState } from 'react'
import { Form, FormGroup } from 'react-bootstrap'
import { CButton, Loading } from '../Components'
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { subscribe, subscribeWithCard } from '../Services/Subscription';
import { getCards, removeCard, updateDefaultCard } from '../Services/User';
import { urlPrefix } from '../Helpers/Common';
import { Link } from 'react-router-dom';

const CheckOutPage = ({ cards, client_secret, membership_plan_id, setShow, getInitialPageData, setSelectedMembershipPlan,paymentDetails,refundableAmount}) => {
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [selectedCard, setSelectedCard] = useState(null);
    const [userCards, setUserCards] = useState(cards);
    const [recurring,setRecurring] = useState(true);

    useEffect(() => {
        if (!elements) {
            return;
        }
        const cardNumberElement = elements.create("cardNumber", {
            //   style: style,
            placeholder: "Card number",
        });
        cardNumberElement.mount("#card-number-element");

        const cardExpiryElement = elements.create("cardExpiry", {
            //   style: style,
            placeholder: "Expiry date",
        });
        cardExpiryElement.mount("#card-expiry-element");

        const cardCvcElement = elements.create("cardCvc", {
            //   style: style,
            placeholder: "CVV",
        });
        cardCvcElement.mount("#card-cvc-element");

    }, [elements]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateUserCards = async () => {
        setLoading(true)
        setUserCards((await getCards()).data.cards);
        setLoading(false)
    }

    const handlePaymentWithNewCard = async () => {
        try {
            setLoading(true)
            const cardNumberElement = elements.getElement("cardNumber");
            const createdCard = await stripe.createToken(cardNumberElement);
            const { error, paymentIntent } = await stripe.confirmCardPayment(client_secret, { payment_method: { card: cardNumberElement } });
            if (paymentIntent) {
                const { id, status, created, amount, payment_method } = paymentIntent;
                let result = await subscribe({ recurring ,membership_plan_id, payment_intent_id: id, card_token: createdCard.token.id, payment_status: status, payment_date: created, amount: (amount / 100), payment_method });
                if (result?.success) {
                    getInitialPageData()
                    toast.success(result.message)
                } else {
                    toast.error(result.error.message)
                }
            } else {
                toast.error(error.message)
            }
            setSelectedMembershipPlan(null)
            setLoading(false)
            setShow(false)
        } catch (error) {
            setLoading(false)
            toast.error(error.message)
            setShow(false)
        }
    }

    const handlePaymentWithSavedCard = async (user_card_id) => {
        if (!selectedCard || user_card_id !== selectedCard?.user_card_id) {
            return
        }
        setLoading(true);
        let result = await subscribeWithCard({ user_card_id, membership_plan_id, recurring })
        if (result?.success) {
            getInitialPageData()
            setSelectedMembershipPlan(null)
            toast.success(result.message)
        } else {
            toast.error(result.error.message)
        }
        setSelectedMembershipPlan(null)
        setLoading(false)
        setShow(false)
    }

    const handleCardDeletion = async (user_card_id) => {
        if (!selectedCard || user_card_id !== selectedCard?.user_card_id) {
            return
        }
        setLoading(true)
        let result = await removeCard({ user_card_id });
        if (result?.success) {
            toast.success(result.message)
            updateUserCards()
            setSelectedCard(null)
        }
        else {
            toast.error(result.error.message)
        }
        setLoading(false)
    }

    const handleDefaultCardUpdate = async () => {
        if (!selectedCard || selectedCard.is_default === "yes") {
            return
        }
        setLoading(true)
        let result = await updateDefaultCard({ user_card_id: selectedCard?.user_card_id });
        if (result?.success) {
            toast.success(result.message)
            updateUserCards()
            setSelectedCard(null)
        }
        else {
            toast.error(result.error.message)
        }
        setLoading(false)
    }

    return (
        <div className="add-new">
            <div className="top">
                <p className="new-title">Add New Card</p>
                <img src={urlPrefix() + "/assets/image/visa-mastercard.png"} alt='book-image' />
            </div>
            <div className="c-form">
                <div className="contact-form payment-card-v1">
                    <Form className="login-form-main chose-pln">
                        <div className="cost-form-input">
                            <FormGroup className="cost-form-group">
                                <Form.Label>Card Number:</Form.Label>
                                <div
                                    className="SplitCardField"
                                    type="text"
                                    id="card-number-element"
                                ></div>
                                {/* <input id="name"
                                    name="name"
                                    type="text"
                                    placeholder="Card Number"
                                    className="form-controla"
                                    autoComplete="new-password"
                                /> */}
                            </FormGroup>
                        </div>
                        <div className="cost-form-input">
                            <FormGroup className="cost-form-group">
                                <Form.Label>Expiry Date</Form.Label>
                                <div
                                    className="SplitCardField"
                                    type="text"
                                    id="card-expiry-element"
                                ></div>
                                {/* <input
                                    id="phone_number"
                                    name="phone_number"
                                    type="date"
                                    placeholder="Expiry Date"
                                    className="form-controla"
                                    autoComplete="new-password"
                                /> */}
                            </FormGroup>
                        </div>
                        <div className="cost-form-input">
                            <FormGroup className="cost-form-group">
                                <Form.Label>CVV</Form.Label>
                                <div
                                    className="SplitCardField"
                                    type="text"
                                    id="card-cvc-element"
                                ></div>
                                {/* <input
                                    id="phone_number"
                                    name="phone_number"
                                    type="text"
                                    placeholder="cvv"
                                    className="form-controla"
                                    autoComplete="new-password"
                                /> */}
                            </FormGroup>
                        </div>

                        <div className="submit-cta">
                        {
                            (refundableAmount.length > 1) ? 
                            <>
                                <div className="refundable-amount">
                                    <p>Monthly Subscription fee: <span>{(parseFloat(paymentDetails?.amount) - parseFloat(refundableAmount)).toFixed(2)} {paymentDetails?.currency}</span></p>
                                    <p>Refundable Deposit: <span>{refundableAmount} {paymentDetails?.currency}</span></p>
                                </div>
                            </> : null
                        }
                            <CButton label={`Pay ${paymentDetails?.amount} ${paymentDetails?.currency}`} preventDefault={true} fxToRun={() => { handlePaymentWithNewCard() }} custClassName="login-button t-btn"></CButton>
                        </div>
                    </Form>
                </div>
                {/* <Form className="cost-form">
                    Allow recurring payment
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check label=" " checked={recurring} onClick={()=> setRecurring(!recurring)}/>
                    </Form.Group>
                </Form> */}
            </div>
            {
                userCards && userCards.length ?
                    <div className="choose-cards">
                        <div className="top-title">
                            <p><span>Note:</span> The deposit will be refunded upon request to cancel the subscription and the amount is subject to the return of all borrowed books without damage before due dates. For more information visit <a href={"/term#cancellation"} target='_blank'> Return and Cancellation</a></p>
                        </div>
                        <div className="top1">
                            <p>Choose From Saved Cards.</p>
                        </div>
                        <div className="plan-detail-group">
                        {
                            userCards.map((card) => 
                                <div className="plan-detail">
                                    <div className="cost-form-input">
                                        <FormGroup className="cost-form-group choose-cd">
                                            <div className="c-image">
                                                <img src={urlPrefix() + "/assets/image/visa-card-icon.png"} alt='book-image' />
                                            </div>
                                            <div className="detail">
                                                <label for={`card${card.user_card_id}`}>{card.type} **** {card.last4} {card.is_default === "yes" ? "Primary Card" : ""}</label>
                                                <label for={`card${card.user_card_id}`}>Expires {card.exp_month}/{card.exp_year}</label>
                                            </div>
                                            <div className="pay-now" onClick={() => setSelectedCard(card)}>
                                                <input type="radio" id={`card${card.user_card_id}`} checked={card.user_card_id === selectedCard?.user_card_id ? true : false} />
                                                <CButton fxToRun={() => handlePaymentWithSavedCard(card.user_card_id)} label="Pay" custClassName="login-button t-btn"></CButton>
                                            </div>
                                            {
                                                card.is_default === "no" ?
                                                    <div className="delete-icon" onClick={() => handleCardDeletion(card.user_card_id)}>
                                                        <img src={urlPrefix() + "/assets/image/trash.svg"} alt='book-image' />
                                                    </div> : null
                                            }
                                        </FormGroup>
                                    </div>
                                </div>
                            )
                        }
                        </div>
                        {
                            selectedCard && selectedCard.is_default === "no" ?
                                <CButton fxToRun={() => { handleDefaultCardUpdate() }} label="Set Default" custClassName="login-button t-btn"></CButton> :
                                null
                        }
                    </div> :
                    null
            }
            {
                loading ? <Loading /> : null
            }
        </div>
    )
}

export default CheckOutPage