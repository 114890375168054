import React, { useEffect, useState } from 'react'
import { GeneralFilter, Loading } from '../../Components';
import AdminNavbar from '../Navbar/AdminNavbar';
import { Button, Form, InputGroup } from 'react-bootstrap';
import MakePagination from '../../Components/MakePagination';
import { urlPrefix } from '../../Helpers/Common';
import { getAdminOrders } from '../Services/Order';
import { find ,upperCase} from 'lodash';
import StatusDropDown from './Components/StatusDropDown';
import { useDebounce } from '../../../CustomHooks/DebounceHook';

const ListOrders = () => {
    const dropDownOptions = [{ id: 1, name: "pending", filter: "status" }, { id: 2, name: "approved", filter: "status" }, { id: 3, name: "completed", filter: "status" }, { id: 4, name: "cancelled", filter: "status" }, { id: 5, name: "retrieved", filter: "status" }]
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [pageFilters, setPageFilters] = useState({})
    const [pagination, setPagination] = useState({ limit: 10, start_from: 0, search: null, order_by: [{ column: 'orders.id', order: 'desc' }] });
    const [openedDropdown, setOpenedDropDown] = useState(null)
    const debouncedSearch = useDebounce(search, 500);

    const selectFilter = (filter, data) => {
        if (filter === "order") {
            setPagination({ ...pagination, order_by: [data] })
        }
        else if (filter === "status") {
            setPageFilters({ ...pageFilters, status: data })
            setPagination({ ...pagination, start_from: 0 })
        }
        else if (filter === "pagination") {
            setPagination({ ...pagination, start_from: data })
        }
        else if (filter === "search") {
            setPagination({ ...pagination, start_from: 0, search: data && data.length ? data.trim() : null })
        }
    }

    const getInitialPageData = async () => {
        setLoading(true)
        const result = await getAdminOrders({ ...pagination, filters: pageFilters, with_user: true, total_count_of: "orders.id", with_order_detail: true });
        setOrders(result.data.orders)
        setTotalOrders(result.data.totalOrderRecords)
        setLoading(false)
    }

    const generalFilterFx = (option) => {
        selectFilter("status", option?.name)
    }

    useEffect(() => {
        getInitialPageData()
    }, [pagination, pageFilters])

    useEffect(() => {
        selectFilter("search", search)
    }, [debouncedSearch])

    return (
        <div className="admin-panel-main">
            <div className="panel-row">
                <div className="left">
                    <AdminNavbar></AdminNavbar>
                </div>
                <div className="right">
                    <div className="panel-title-top all-book">
                        <div className="top-left">
                            <h3 className="heading-h3">Orders list</h3>
                        </div>
                    </div>

                    <div className="dash-filter filter">
                        <div className="left-fil">
                            <GeneralFilter options={dropDownOptions} value="name" defaultValue="Status" fxToRun={generalFilterFx} clearText={"Select Status"} />
                        </div>
                        <div className="search">
                            <InputGroup className="admin-search-input" onChange={(e) => setSearch(e.target.value)} >
                                <Form.Control placeholder="Search User name" value={search || ""} />
                            </InputGroup>
                        </div>
                    </div>

                    <div className="admin-panel-data order-list85">
                        <div className="tb-res-v1">
                            <div className="top-title">
                                <div className="order-no">
                                    <p className="top-t">Order Id</p>
                                </div>
                                <div className="order-no">
                                    <p className="top-t">Date</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">User Name</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Email</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Books</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Quantity</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Delivery Date</p>
                                </div>
                                <div className="e-status">
                                    <p className="top-t">Address</p>
                                </div>
                                <div className="e-status">
                                    <p className="top-t">Return Date</p>
                                </div>
                                <div className="e-status">
                                    <p className="top-t">Status</p>
                                </div>
                                <div className="e-edit-delete">
                                    <p className="top-t">Note</p>
                                </div>
                            </div>
                            <div className="book-list">
                                {
                                    orders && orders.length ?
                                        orders.map((order) => <div className="book-row" key={order.order_id}>
                                            <div className="order-no-value">
                                                <p className="top-value">{order.order_id}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{order.order_made_on}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{order.first_name} {order.last_name}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value email">{order.email}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{order.books_ordered.map((book) => book.title).join(", ")}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{order.books_ordered.length}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">
                                                {
                                                    order?.scheduled_order_date ? (
                                                        <>
                                                            {order.scheduled_order_date} AT {upperCase(order.scheduled_time_slot)}
                                                        </>
                                                    ) : "---"
                                                }
                                                </p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{order.zipcode} {order.street_address} {order.city} {order.country}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{order.borrow_return_date} </p>
                                            </div>
                                            <div className="title-value">
                                                <div className="fil-drop">
                                                    <StatusDropDown note={order.detail} getInitialPageData={getInitialPageData} setOpenedDropDown={setOpenedDropDown} dropdownId={order.order_id} openedDropdown={openedDropdown} options={dropDownOptions} currentSelected={find(dropDownOptions, { name: order.status })} />
                                                </div>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{order.detail ?? "---"}</p>
                                            </div>
                                        </div>) :
                                        <div className="no-data">
                                            No Orders found.
                                        </div>
                                }
                            

                            </div>
                        </div>
                    </div>

                    {
                        orders && orders.length ?
                            <div className="books-pagination">
                                <MakePagination totalRecords={totalOrders} limiter={10} selectFilter={selectFilter}></MakePagination>
                            </div>
                            : null
                    }
                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
        </div>
    )
}

export default ListOrders
