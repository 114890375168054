import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDebounce } from '../../CustomHooks/DebounceHook';
import useClickAway from '../../CustomHooks/ClickAwayHook';

const CategoryFilter = ({ options, selectFilter, openedDropdown, dropdownId, setOpenedDropDown, catid, clearData, setClearData }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(catid || null);
  const [sortableOptions,setSortableOptions] = useState([])
  const [search, setSearch]  = useState("");
  const debouncedSearch = useDebounce(search, 700);
  const clickAwayRef = useClickAway(()=>{
    if(setOpenedDropDown && openedDropdown && dropdownId){
      if(openedDropdown === dropdownId){
        setOpenedDropDown(null)
      }
    }
  })
  const toggleDropdown = () => {
    if (dropdownId) {
      if (isOpen) {
        setOpenedDropDown(null)
      }
      else {
        setOpenedDropDown(dropdownId)
      }
    }
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    selectFilter("category", option.category_id)
    setSelectedOption(option.name);
    setIsOpen(false);
  };

  useEffect(() => {
    if (clearData) {
      setSelectedOption(null);
      setClearData(false)
    }
  }, [clearData])

  useEffect(() => {
    if (dropdownId && openedDropdown !== dropdownId) {
      setIsOpen(false)
    }
  }, [openedDropdown])

  useEffect(()=>{
    setSortableOptions(options)
  },[options])

  const sortOptions = (searchInput) => {
    searchInput = searchInput && searchInput.length ? searchInput.trim() : null
    if (searchInput && searchInput.length) {
      setSortableOptions(filter(options, (item) => {
        return item.name.toLowerCase().includes(searchInput.toLowerCase());
      }))
    }
    else{
      setSortableOptions(options)
    }
  }

  useEffect(() => {
    sortOptions(search)
  }, [debouncedSearch]);

  useEffect(() => {
        setSelectedOption(catid);
    }, [catid]);

  return (
    <div className={`custom-dropdown ${isOpen ? 'open' : ''}`} ref={clickAwayRef}>
      <div className="selected-option category" onClick={toggleDropdown}>
        {selectedOption ? selectedOption : 'Category'}
      </div>
      {isOpen && (
        <div className="dropdown-options">
          <div className="drop-filter-main">
          <input type="text" className="drop-input" value={search}  onChange={(e)=> setSearch(e.target.value)}/>
          </div>
          <div className="option" onClick={() => {selectOption({ category_id: null, name: "Category" });setSearch("")}}>
            Select Category
          </div>
          {sortableOptions.map((option) => (
            <div key={option.category_id} className="option" onClick={() => {selectOption(option);setSearch("")}}>
            {selectedOption === option.name ? <b>{option.name}</b> : <>{option.name}</>}
            </div>
          ))}
          {
            !sortableOptions.length ? <div className="option">No result found</div> : null
          }
        </div>
      )}
    </div>
  );
};

export default CategoryFilter;
