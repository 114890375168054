
export const validateCategory = values => {
    const errors = {};

    if (!values.name) {
        errors.name = 'This field is required';
    }
    if (!values.description) {
        errors.description = 'This field is required';
    }

    return errors;
};