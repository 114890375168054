import axios from "axios";
import { provideToken } from "../../Helpers/Common";


export const getAdminUsers = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/admin/users`, { params, headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const getAdminDefaulters = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/admin/users/defaulters`, { params, headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const adminCreateDefaulter = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/users/defaulters/create`, params, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const adminRemoveDefaulter = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/users/defaulters/remove`, params, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}