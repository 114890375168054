import React from "react";
import { useNavigate } from "react-router-dom";
import { urlPrefix } from "../Helpers/Common";
const CButton = (props) => {
  const navigate = useNavigate();
  const { label, custClassName, redirectTo = false, fxToRun, data, preventDefault, disabled } = props;
  const handleClick = (e) => {
    if (preventDefault){
      e.preventDefault()
    }
    
    if (redirectTo) {
      navigate(urlPrefix() +  `/${redirectTo}`);
    }
    else if(fxToRun){ 
      if(data){
        fxToRun(data)
      }
      else{
        fxToRun()
      }
    }
  };
      return (
      <React.Fragment>
        <button disabled={disabled} onClick={handleClick} type="submit" className={custClassName}>
            {label}  <span>&nbsp;</span>
        </button>              
      </React.Fragment>
    );
  };
  export default CButton;

  // import CButton from "../Components/elements/Buttons";

  // import CButton from "../Components/elements/Buttons";


  //  <button className={`cus-primary-btn` + ' ' + custClassName}>
  // {label}  <span>&nbsp;</span>
  // </button>    