import React, { useEffect, useState } from 'react';
import { CButton, Loading } from '../Components';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart } from '../Services/Cart';
import { toast } from 'react-toastify';
import { urlPrefix } from '../Helpers/Common';
import { Form } from 'react-bootstrap';
import { Tab, Tabs } from 'react-bootstrap';
import { placeOrder } from '../Services/Order';
import { decreaseCartQuantity } from '../../Redux/Slices/UserSlice';
import { insertBookInReadingList } from '../Services/Books';
import { useNavigate } from 'react-router-dom';

const OrderNow = ({ cartBooks, updateCartBooks, address , nextSevenDays, timeSlots}) => {
    const navigate= useNavigate()
    const [tabKey, setTabKey] = useState('LiteraryKey');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.UserReducer.value);
    const [booksToOrder, setBooksToOrder] = useState({});
    const [scheduled_order_date,set_scheduled_order_date] = useState("");
    const [scheduled_time_slot,set_scheduled_time_slot] = useState("");

    const removeFromUsersCart = async (book_id) => {
        setLoading(true)
        const result = await removeFromCart({ cart_id: user.cart_id, book_id });
        if (result.success) {
            updateCartBooks()
            dispatch(decreaseCartQuantity())
            setBooksToOrder(prev => { const { [book_id]: _, ...updatedBooksToOrder } = prev; return updatedBooksToOrder; })
        }
        else {
            toast.error(result.error.message)
        }
        setLoading(false)
    }

    const moveToReadingList = async (book_id) => {
        setLoading(true)
        const request = [insertBookInReadingList({ cart_id: user.cart_id, book_id }),removeFromCart({ cart_id: user.cart_id, book_id })];
        const result = await Promise.all(request);
        updateCartBooks()
        dispatch(decreaseCartQuantity())
        setBooksToOrder(prev => { const { [book_id]: _, ...updatedBooksToOrder } = prev; return updatedBooksToOrder; })
        toast.success("Book moved to reading list.")
        setLoading(false)
    }

    const handleBookToOrder = (book) => {
        if (booksToOrder[book.book_id]) {
            setBooksToOrder(prev => { const { [book.book_id]: _, ...updatedBooksToOrder } = prev; return updatedBooksToOrder; })
        } else {
            setBooksToOrder({ [book.book_id]: { title: book.title }, ...booksToOrder })
        }
    }

    const handleOrderPlacement = async () => {
        if (!booksToOrder || !Object.getOwnPropertyNames(booksToOrder).length) {
            toast.error("Please select books first.")
            return
        }
        let books = []
        Object.entries(booksToOrder).map(([key, value]) => { books.push({ id: key, quantity: 1 }) })
        setLoading(true)
        let result = await placeOrder({ books, user_detail_id: address.id, cart_id: user.cart_id , scheduled_order_date, scheduled_time_slot});
        if (result.success) {
            updateCartBooks()
            dispatch(decreaseCartQuantity(result.data.success_book_order.length))
            setBooksToOrder({})
            toast.success(result.message)
        }
        else {
            toast.error(result.error.message)
        }
        setLoading(false)
    }

    useEffect(()=>{
        if(nextSevenDays.length){
            set_scheduled_order_date(nextSevenDays[0].split(" ")[0])
        }
        if(timeSlots.length){
            set_scheduled_time_slot(timeSlots[0].time_slot)
        }
    },[timeSlots,nextSevenDays])

    return (
        <div className="literary-cart">
            <div className="literary">
                <div class="top">
                    <h3 className="top-heading">Cart</h3>
                </div>
                <div className="literary-row">

                    {
                        cartBooks && cartBooks.length ?
                            cartBooks.map((book) => <>
                                <div class="l-row">
                                    <div className="icon">
                                        <div className="c-icon">
                                            <img src={urlPrefix() + "/assets/image/trash.svg"} alt="book imag" onClick={() => removeFromUsersCart(book.book_id)}></img>
                                            {
                                                book.quantity_available > 0 ?
                                                    <Form className="cost-form">
                                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                            <Form.Check id={`book_${book.book_id}`} name={`book_${book.book_id}`} checked={booksToOrder[book.book_id] ? true : false} type="checkbox" label=" " onClick={() => handleBookToOrder(book)} />
                                                        </Form.Group>
                                                    </Form> : null
                                            }
                                        </div>
                                    </div>

                                    <div className="ico-card">
                                        <div className="order-book-card">
                                            <div className="book">
                                                <img src={book.image_url} alt="book imag" onClick={()=> navigate(urlPrefix() + "/library-detail/" + book.slug)}></img>
                                            </div>
                                            <div className="detail">
                                                <div className="book-name">
                                                    <h3 className="heading-h3 title" onClick={()=> navigate(urlPrefix() + "/library-detail/" + book.slug)}>{book.title}</h3>
                                                </div>
                                                <div className="book-detail">
                                                    <p className="s-title">By {book?.authors?.map((author) => author).join(" ,")}</p>
                                                </div>

                                                <div className="reading-list"  onClick={()=>moveToReadingList(book.book_id)}>
                                                    <p>Move to Reading List</p>
                                                </div> 

                                                {
                                                    book.quantity_available > 0 ?
                                                        <div className="in-stock">
                                                            <p>In Stock</p>
                                                        </div> :
                                                        <div className="out-stock">
                                                            <p className="out-stock">Out of Stock</p>
                                                            {/* <div className="cta">
                                                                <CButton label="Notify when available" custClassName="notify-btn t-btn"></CButton>
                                                            </div> */}
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            ) :
                            <div className="no-data">
                                <p>Cart is Empty.</p>
                            </div>
                    }
                </div>
            </div>
            <div className="delivery">
                <div className="place-order-card">
                    <h3 className="heading-h3 card-heading">Delivery Details</h3>
                    <div className="address">
                        <div className="left">
                            <p className="l-side">Name</p>
                        </div>
                        <div className="right">
                            <p className="r-side">{user.first_name} {user.last_name}</p>
                        </div>
                        <div className="left">
                            <p className="l-side">Address:</p>
                        </div>
                        <div className="right">
                            <p className="r-side">
                                {address ? <span>{address.city}, {address.state}</span> : <span>Address Line 1</span>}
                            </p>
                        </div>
                        
                        {/* <div className="left">
                            <p className="l-side">Expected date of delivery:</p>
                        </div>
                        <div className="right">
                            <p className="r-side">14.09.2023</p>
                        </div> */}
                    </div>
                    <div className="cust-delivery-time">
                        <div className="title">
                            <p>Deliver Between</p>
                        </div>
                        <div className="selct-col">
                            <select onChange={(e)=> set_scheduled_order_date(e.target.value)}>
                                {nextSevenDays.map((i,index)=> <option key={index} value={i.split(' ')[0]} >{i}</option>)}
                            </select>
                            <select onChange={(e)=> set_scheduled_time_slot(e.target.value)}>
                                {timeSlots.map((i,index)=> <option key={index} value={i.time_slot}>{i.slug}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="book-ordered">
                        <h5 className="heading-h5">Book ordered:</h5>
                        <ul className="ordered">
                            {
                                booksToOrder && Object.values(booksToOrder).length ?
                                    Object.values(booksToOrder).map((book) => <li>{book.title}</li>) :
                                    <div className='no-data'>No Books Ordered Yet.</div>
                            }
                        </ul>
                    </div>
                    <div className="place-order">
                        <CButton preventDefault={true} fxToRun={() => { handleOrderPlacement() }} label="Place Order" custClassName="login-button t-btn"></CButton>
                    </div>
                </div>
            </div>

            <div className="literary-cart mobile">
                <div className="mobile-tab">
                    <Tabs activeKey={tabKey} defaultActiveKey="LiteraryKey" id="LiteraryCart" className="cost-order-tab" fill onSelect={(k) => setTabKey(k)}>
                        <Tab eventKey="LiteraryKey" title="1. Literary Cart">
                            <div className="literary">
                                {/* <div class="top">
                                    <h3 className="top-heading">Literary Cart</h3>
                                </div> */}
                                <div className="literary-row">
                                    {
                                        cartBooks && cartBooks.length ?
                                            cartBooks.map((book) => <>
                                                <div class="l-row">
                                                    <div className="icon">
                                                        <div className="c-icon">
                                                            <img src="/assets/image/trash.svg" alt="book imag" onClick={() => removeFromUsersCart(book.book_id)}></img>
                                                            {
                                                                book.quantity_available > 0 ?
                                                                    <Form className="cost-form">
                                                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                                            <Form.Check id={`book_${book.book_id}`} name={`book_${book.book_id}`} checked={booksToOrder[book.book_id] ? true : false} type="checkbox" label=" " onClick={() => handleBookToOrder(book)} />
                                                                        </Form.Group>
                                                                    </Form> : null
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="ico-card">
                                                        <div className="order-book-card">
                                                            <div className="book">
                                                                <img src={book.image_url} alt="book imag" onClick={()=> navigate(urlPrefix() + "/library-detail/" + book.slug)}></img>
                                                            </div>
                                                            <div className="detail">
                                                                <div className="book-name">
                                                                    <h3 className="heading-h3 title" onClick={()=> navigate(urlPrefix() + "/library-detail/" + book.slug)}>{book.title}</h3>
                                                                </div>
                                                                <div className="book-detail">
                                                                    <p className="s-title">By {book?.authors?.map((author) => author).join(" ,")}</p>
                                                                </div>
                                                                <div className="reading-list" onClick={()=> moveToReadingList(book.book_id)}>
                                                                    <p>Move to Reading List</p>
                                                                </div> 
                                                                {
                                                                    book.quantity_available > 0 ?
                                                                        <div className="in-stock">
                                                                            <p>In Stock</p>
                                                                        </div> :
                                                                        <div className="out-stock">
                                                                            <p className="out-stock">Out of Stock</p>
                                                                            {/* <div className="cta">
                                                                                <CButton label="Notify when available" custClassName="notify-btn t-btn"></CButton>
                                                                            </div> */}
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            ) :

                                            <div className="no-data">
                                                <p>Cart is Empty.</p>
                                            </div>

                                    }

                                </div>

                                <div className="proceed-details">
                                    <CButton fxToRun={()=> Object.values(booksToOrder).length ? setTabKey("DeliveryDetails") : toast.error("Please choose a book first.")} label="Proceed to Delivery Details" custClassName="login-button t-btn"></CButton>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="DeliveryDetails" title="2. Delivery Details">
                            <div className="delivery">
                                <div className="place-order-card">
                                    {/* <h3 className="heading-h3 card-heading">Delivery Details</h3> */}
                                    <div className="address">
                                        <div className="left">
                                            <p className="l-side">Name</p>
                                        </div>
                                        <div className="right">
                                            <p className="r-side">{user.first_name} {user.last_name}</p>
                                        </div>
                                        <div className="left">
                                            <p className="l-side">Address:</p>
                                        </div>
                                        <div className="right">
                                            <p className="r-side">
                                                {address ? <span>{address.city}, {address.state}</span> : <span>Address Line 1</span>}
                                            </p>
                                        </div>
                                        
                                        {/* <div className="left">
                                            <p className="l-side">Expected date of delivery:</p>
                                        </div>
                                        <div className="right">
                                            <p className="r-side">14.09.2023</p>
                                        </div> */}
                                        <div className="cust-delivery-time">
                                            <div className="title">
                                                <p>Deliver Between</p>
                                            </div>
                                            <div className="selct-col">
                                                <select onChange={(e)=> set_scheduled_order_date(e.target.value)}>
                                                    {nextSevenDays.map((i,index)=> <option key={index} value={i.split(' ')[0]} >{i}</option>)}
                                                </select>
                                                <select onChange={(e)=> set_scheduled_time_slot(e.target.value)}>
                                                    {timeSlots.map((i,index)=> <option key={index} value={i.time_slot}>{i.slug}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="book-ordered">
                                        <h5 className="heading-h5">Book ordered:</h5>
                                        <ul className="ordered">
                                            {
                                                booksToOrder && Object.values(booksToOrder).length ?
                                                    Object.values(booksToOrder).map((book) => <li>{book.title}</li>) :
                                                    <div className='no-data'>No Books Ordered Yet.</div>
                                            }
                                        </ul>
                                    </div>
                                    {/* <div className="book-return">
                                        <h5 className="heading-h5">Book to return</h5>
                                        <p className="return-detail">None</p>
                                    </div> */}

                                    <div className="place-order">
                                        <CButton preventDefault={true} fxToRun={() => { handleOrderPlacement() }} label="Place Order" custClassName="login-button t-btn"></CButton>
                                    </div>
                                    <div className="previous-step">
                                        <button onClick={()=>setTabKey("LiteraryKey")} type="submit" class="cost-btn t-btn"><span>&nbsp;</span> Previous step </button>
                                    </div>
                                </div>

                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
        </div>

    )
}
export default OrderNow;