import { PublicValidator } from "./Validators/PublicValidator";
import Login from "../Modules/Auth/Login";
import Register from "../Modules/Auth/Register";
import ForgotPassword from "../Modules/Auth/ForgotPassword";
import ResetPassword from "../Modules/Auth/ResetPassword";
import VerifyEmail from "../Modules/Auth/VerifyEmail";
import Home from "../Modules/Layout/Home";
import PageNotFound from "../Modules/Layout/404";

export const Public = [
    {
        path: process.env.REACT_APP_FRONT_URL + "/",
        element: <PublicValidator/>,
        errorElement:<PageNotFound/>,
        children: [
            {
                path: '',
                element: <Home/>
            },
            {
                path: 'login',
                element: <Login/>
            },
            {
                path: 'register',
                element: <Register/>
            },
            {
                path: 'forgot-password',
                element: <ForgotPassword/>
            },
            {
                path: 'reset-password/:token',
                element: <ResetPassword/>
            },
            {
                path: 'verify-email/:token',
                element: <VerifyEmail/>
            },
        ]
    }
]