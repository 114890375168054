import React, { useEffect, useState } from "react";
import { Tab, Tabs } from 'react-bootstrap';
import SubscriptionPay from "../MyAccount/SubscriptionPay";
import ProfilePay from "../MyAccount/ProfilePay";
import PaymentDone from "../MyAccount/PaymentDone";
import ReadingList from "../MyAccount/ReadingList";
import OrderHistory from "../MyAccount/OrderHistory";
import CurrentStack from "../MyAccount/CurrentStack";
import { useDispatch } from "react-redux";
import { terminateSession } from "../../Redux/Slices/UserSlice";
import { useNavigate } from "react-router-dom";
import { urlPrefix } from "../Helpers/Common";

const SubscriptionAccount = () => {
  const urlKey = new URLSearchParams(window.location.search).get("key");
  const [key,setKey] = useState(["Order History","Current Stack","Reading List","Payment","profile","Subscription"].includes(urlKey) ? urlKey : "Subscription");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(terminateSession())  
    navigate(urlPrefix() + "/")
  }

  return (
    <React.Fragment>
      <div className="my-account-hero">
        <h5 className="heading-h5 ac-sub-title">My account</h5>
        {/* <h1 className="heading-h1 ac-title">manage your literary journey with ease!</h1> */}
        <h5 className="heading-h5 for-mobile450" onClick={() => logOut()}>log out</h5>
      </div>

      <div className="subscription-main account">
        <Tabs id="SubscriptionMain" className="cost-tab" fill activeKey={key} onSelect={(e)=>setKey(e)}>

          <Tab eventKey="Subscription" title="Subscription">
            <SubscriptionPay></SubscriptionPay>
          </Tab>

          <Tab eventKey="profile" title="Profile">
            <ProfilePay></ProfilePay>
          </Tab>

          <Tab eventKey="Payment" title="Payment">
            <PaymentDone></PaymentDone>
          </Tab>

          <Tab eventKey="Reading List" title="Reading List">
            <ReadingList setKey={setKey}></ReadingList>
          </Tab>

          <Tab eventKey="Current Stack" title="Current Stack">
            <CurrentStack></CurrentStack>
          </Tab>

          <Tab eventKey="Order History" title="Order History">
            <OrderHistory></OrderHistory>
          </Tab>

        </Tabs>
      </div>

    </React.Fragment>
  );
};
export default SubscriptionAccount;