import React, { useState } from "react";
import { CButton, Loading } from "../Components";
import { Form, FormGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { resetPassword } from "../Services/Auth";
import { validateResetPasswordForm } from "./Validations";
import { urlPrefix } from "../Helpers/Common";

const ResetPassword = () => {
  const navigate = useNavigate()
  const {token} = useParams();
  const [passVisibility,setPassVisibility] = useState(false);
  const [confirmPassVisibility,setConfirmPassVisibility] = useState(false);
  const [loading,setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      newPassword: "",
      confirmPassword:"",
    },
    validate: validateResetPasswordForm,
    onSubmit: async (values) => {
      setLoading(true);
      let result = await resetPassword({...values,token});
      setLoading(false);
      result?.success ? toast.success(result.message) : toast.error(result.error.message)
      navigate(urlPrefix() + "/login")
    }
  });

  
  const errorElement = (msg) => {
    return <div className='small text-danger'>{msg}</div>
  }

  return (
    <React.Fragment>

      <div className="sign-in-form-wrap">

        <div className="login-main-wrap">
          <h5 className="heading-h5 login-subtitle">Login</h5>
          <h1 className="heading-h2 login-title">Reset Password</h1>
        </div>

        <div className="sign-form-main">
          <Form id="loginForm" className="login-form-main" onSubmit={formik.handleSubmit}>

            <div className="cost-form-input">
              <FormGroup className="cost-form-group login-show10">
                <Form.Label>New Password</Form.Label>
                <div className="inp-msg">
                <input
                  id="newPassword"
                  name="newPassword"
                  type={passVisibility ? "text" : "password"}
                  onChange={formik.handleChange}
                  value={formik.values.newPassword}
                  placeholder="Enter your New Password"
                  className="form-controla"
                  autoComplete="new-password"
                />
                <span className="pd-sh86" onClick={()=>setPassVisibility(!passVisibility)} >{passVisibility ? 
                  <div className="show-icon">
                    <img src={urlPrefix() + "/assets/image/view-green.png"} alt="book imag"></img>
                  </div> 
                  : 
                  <div className="hide-icon">
                    <img src={urlPrefix() + "/assets/image/eye-green.png"} alt="book imag"></img>
                  </div> 
                  }
                </span>
                {formik.errors.newPassword ? errorElement(formik.errors.newPassword) : null}
                </div>
              </FormGroup>
            </div>

            <div className="cost-form-input">
              <FormGroup className="cost-form-group login-show10">
                <Form.Label>Confirm Password</Form.Label>
                <div className="inp-msg">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type={confirmPassVisibility ? "text" : "password"}
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                  placeholder="Confirm Password"
                  className="form-controla"
                  autoComplete="new-password"
                />
                <span className="pd-sh86" onClick={()=>setConfirmPassVisibility(!confirmPassVisibility)} >{confirmPassVisibility ? 
                  <div className="show-icon">
                    <img src={urlPrefix() + "/assets/image/view-green.png"} alt="book imag"></img>
                  </div> 
                  : 
                  <div className="hide-icon">
                    <img src={urlPrefix() + "/assets/image/eye-green.png"} alt="book imag"></img>
                  </div> 
                  }
                  </span>
                {formik.errors.confirmPassword ? errorElement(formik.errors.confirmPassword) : null}
                </div>
              </FormGroup>
            </div>

            <div className="submit-cta">
              <CButton label="Reset Password" custClassName="login-button t-btn w-75 m-auto"></CButton>
            </div>

          </Form>

        </div>
      </div>
      {
        loading ? <Loading /> : null
      }
    </React.Fragment>
  );
};
export default ResetPassword;