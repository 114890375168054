import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

const NoteModal = ({ noteModal }) => {
    const { show, rejectFx, message = null } = noteModal;

    return (
        <Modal className="cost-book-modal order-list85-modal " show={show} size="lg" onHide={() => rejectFx()} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title>Note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>{message ?? "--"}</p>
                <div className="mod-foter">
                    <button onClick={() => rejectFx()} className="accept-reject">Cancel</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default NoteModal;



