import axios from "axios";
import { provideToken } from "../Helpers/Common";


export const addToCart = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/cart/insert`, params , { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const getCartBooks = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/cart`, { params, headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const removeFromCart = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/cart/remove`, params, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}