import React, { useEffect, useState } from 'react'
import { cancelRecurringPayments, currentSubscription } from '../Services/Subscription';
import { CButton, Loading } from '../Components';
import { toast } from "react-toastify"
import { useNavigate } from 'react-router-dom';
import { urlPrefix } from '../Helpers/Common';
import { refundStatus } from '../../Config/Constant';
import { capitalize } from 'lodash';
import ConfirmationModal from "./Components/ConfirmationModal";

const SubscriptionPay = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [confirmationModal,setConfirmationModal] = useState({show:false, data: null, acceptFx: null, rejectFx: null,message:null});

  const getInitialPageData = async () => {
    setLoading(true)
    const result = await currentSubscription();
    console.log("current plan here:-",result);
    if (result?.success) {
      setCurrentPlan(result.data.userSubscribedPlan)
    }
    setLoading(false);
  }

  const handleCancelRecurringPayments = async () => {
    setLoading(true)
    const result = await cancelRecurringPayments({});
    if (result.success) {
      getInitialPageData()
      toast.success(result.message)
    }
    else {
      toast.error(result.error.message)
    }
    setLoading(false);
    setConfirmationModal({show:false, data: null, acceptFx: null, rejectFx: null,message:null})
  }

  useEffect(() => {
    getInitialPageData()
  }, [])

  return (
    <div className="profile1-main">
      <h3 className="heading-h3 pro1-title">Subscription</h3>
      {
        currentPlan ?
          <div className="s-plan">
            <div className="plan-row">
              <p>Subscription Plan:</p>
              <p>{currentPlan.name}</p>
            </div>

            <div className="plan-row">
              <p>Plan type:</p>
              <p>{currentPlan.plan_type}</p>
            </div>

            <div className="plan-row">
              <p>Borrow limit:</p>
              <p>{currentPlan.borrow_limit} Books</p>
            </div>

            <div className="plan-row">
              <p>Amount paid:</p>
              <p>AED {currentPlan.chargeable_price}</p>
            </div>

            <div className="plan-row">
              <p>Refundable deposit:</p>
              <p>AED {currentPlan.refundable_deposit}</p>
            </div>

            <div className="plan-row">
              <p>Start Date:</p>
              <p>{currentPlan.start_date}</p>
            </div>

            <div className="plan-row">
              <p>Expiration Date:</p>
              <p>{currentPlan.end_date}</p>
            </div>

            {
              currentPlan.recurring ?
                <div className="plan-row pay">
                  <p>You are on recurring payments:</p>
                  <div className="ml-auto">
                    <button className="accept-reject" onClick={() => setConfirmationModal({
                      show:true,
                      data:{},
                      acceptFx:handleCancelRecurringPayments,
                      message:`Are you sure you want to cancel your membership? Once cancelled you will not be able to borrow any more books from the library and you will have to return the borrowed books before the due date. Once all the books are returned without damage your deposit will be refunded to your account.`,
                      rejectFx:()=>{setConfirmationModal({ show:false, data: null, acceptFx: null, rejectFx: null, message:null})}
                  })}>
                    Cancel Subscription</button>
                    </div>
                  
                </div>
                : null
            }
            {
              currentPlan?.refund_status && currentPlan.recurring==0 ?
                <div className="plan-row pay">
                  <p>Your Refund Progress:</p>
                  <div className="ml-auto"><button className="accept-reject">{capitalize(refundStatus[currentPlan?.refund_status])}</button></div>
                </div>
                : null
            }

            

          </div> : 
          
          <div className="suits89ty">
            <p className="sut7">Currently not subscribed to any plan.</p>
            <button className="accept-reject" onClick={() => navigate(urlPrefix() + "/join-us")} label="Action">Subscribe</button>
          </div>
      }
      {
        loading ? <Loading /> : null
      }
      <ConfirmationModal confirmationModal={confirmationModal}/>
    </div>
  )
}

export default SubscriptionPay;