import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

const ConfirmationModal = ({ confirmationModal }) => {

    const { show, data, acceptFx, rejectFx, message = null } = confirmationModal;
    const [reason, setReason] = useState("");

    return (
        <Modal className="cost-book-modal order-list85-modal " show={show} size="md" onHide={() => rejectFx()} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title>{message ?? "Are you sure, You want to proceed?"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mod856">
                    <p>Add A Reason</p>
                    <textarea value={reason} onChange={(e) => setReason(e.target.value)}></textarea>
                </div>
                <div className="mod-foter">
                    <button onClick={() => acceptFx({...data, ...(reason ? { reason } : {} )})} className="accept-reject">Yes</button>
                    <button onClick={() => rejectFx(data)} className="accept-reject">No</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmationModal;



