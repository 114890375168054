import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AdminInsertScheduledReturnDate, AdminUpdateRetrievalOrderStatus } from '../../Services/Order'
import { Loading } from '../../../Components';
import { toast } from 'react-toastify'
import { upperCase } from 'lodash';

const ViewRetrievalOrderDetail = ({ show, setShow, retrievalOrderDetail, userId, getInitialPageData }) => {
    const [loading, setLoading] = useState(false);
    const [scheduledDate,setScheduledDate] = useState(false);

    const handleBooksRetrieval = async (books) => {
        setLoading(true);
        const retrieved_books = books.map((book) => ({ book_id: book.book_id }));
        let result = await AdminUpdateRetrievalOrderStatus({ retrieved_books, user_id: userId, status: "retrieved" });
        if (result?.success) {
            setShow(false)
            getInitialPageData()
            toast.success(result.message)
        }
        else {
            toast.error(result.error.message)
        }
        setLoading(false);
    }

    const handleScheduledReturnDate = async(books) => {
        if(!scheduledDate){
            toast.error("Please choose date first.")
            return
        }
        else{
            setLoading(true);
            const books_to_retrieve = books.map((book) => ({ book_id: book.book_id }));
            let result = await AdminInsertScheduledReturnDate({books_to_retrieve, user_id:userId,scheduled_return_date:scheduledDate})
            if (result?.success) {
                setShow(false)
                getInitialPageData()
                toast.success(result.message)
            }
            else {
                toast.error(result.error.message)
            }
            setLoading(false);
        }
    }

    useEffect(()=>{
        if(retrievalOrderDetail && retrievalOrderDetail.length){
            setScheduledDate(retrievalOrderDetail[0].scheduled_return_date)
        }
    },[retrievalOrderDetail,userId])

    return (
        <Modal className="cost-book-modal retrieval-modal" show={show} size="lg" onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title>Retrieval Order Detail <button className="accept-reject ml-5" onClick={() => handleBooksRetrieval(retrievalOrderDetail)}>Retrieved All</button></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <div className="r-date">
                    <p>Scheduled Return Date </p>
                    <input type='date' value={scheduledDate} onChange={(e)=> setScheduledDate(e.target.value)}></input>
                    <button className="accept-reject" onClick={()=>handleScheduledReturnDate(retrievalOrderDetail)}> Submit</button>
                </div> */}
                {retrievalOrderDetail && retrievalOrderDetail.length ?
                    retrievalOrderDetail.map((book) => 
                    <div className="retrieval-m">
                        <div className="book-image">
                            <img src={book.image_url} alt='book-image' />
                        </div>
                        <p className="top-value">{book.ISBN13 ?? book.ISBN10}</p>
                        <p className="top-value">{book.title}</p>
                        <p className="top-value">{book.scheduled_return_date} AT {upperCase(book.return_time_slot)}</p>
                        <button className="accept-reject" onClick={() => handleBooksRetrieval([{ book_id: book.book_id }])}>Retrieved</button>
                    </div>) :
                    null
                }
                {
                    loading ? <Loading /> : null
                }
            </Modal.Body>
        </Modal>
    )
}

export default ViewRetrievalOrderDetail



