import { ProtectedValidator } from "./Validators/ProtectedValidator";
import SubscriptionAccount from "../Modules/User/SubscriptionAccount";
import PageNotFound from "../Modules/Layout/404";
import OrderDetail from "../Modules/User/Order";
import Home from "../Modules/Layout/Home";

export const Protected = [
    {
        path: process.env.REACT_APP_FRONT_URL + "/",
        element: <ProtectedValidator/>,
        errorElement:<PageNotFound/>,
        children: [
            {
                path: '',
                element: <Home/>
            },
            {
                path: 'subscription-account',
                element: <SubscriptionAccount/>
            },
            {
                path: 'order',
                element: <OrderDetail/>
            },
        ]
    }
]