import React, { useEffect, useState } from 'react';
import AdminNavbar from '../Navbar/AdminNavbar'
import { CButton, Loading } from '../../Components'
import { Form, FormGroup } from "react-bootstrap";
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { validateFaqForm } from './Validators';
import { useNavigate } from 'react-router-dom';
import { urlPrefix } from '../../Helpers/Common';
import { AdminCreateFaq } from '../Services/Cms';

const AddFaq = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            question: "",
            answer: ""
        },
        validate: validateFaqForm,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true)
            let result = await AdminCreateFaq(values);
            result?.success ? toast.success(result.message) : toast.error(result?.error?.message)
            navigate(urlPrefix() + "/admin/faqs")
            setLoading(false)
        }
    });

    const errorElement = (msg) => {
        return <div className='small text-danger'>{msg}</div>
    }


    return (
        <div className="admin-panel-main">
            <div className="panel-row">
                <div className="left">
                    <AdminNavbar></AdminNavbar>
                </div>
                <div className="right">

                    <div className="add-book">
                        <div className="panel-title-top">
                            <div className="top-left">
                                <button className='top-back-12' onClick={() => navigate(urlPrefix() + "/admin/faqs")}>Back</button>
                                <h3 className="heading-h3">Add Faq</h3>
                            </div>
                            <div className="top-right">
                                <div className="right-side">
                                    {/* <CButton label="Add Book" custClassName="login-button t-btn"></CButton> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="add-book-form">
                        <Form className="book-panel-form" onSubmit={formik.handleSubmit}>
                            <div className="pan-form-row">
                            <div className="cost-form-input">
                                    <FormGroup className="cost-form-group">
                                        <Form.Label>Question:</Form.Label>
                                        <input
                                            id="question"
                                            name="question"
                                            type="question"
                                            onChange={formik.handleChange}
                                            value={formik.values.question}
                                            placeholder=""
                                            className="form-control"
                                            autoComplete="new-password"
                                        />
                                        {formik.errors.question ? errorElement(formik.errors.question) : null}
                                    </FormGroup>
                                </div>

                                <div className="cost-form-input">
                                    <FormGroup className="cost-form-group">
                                        <Form.Label>Answer:</Form.Label>
                                        <input
                                            id="answer"
                                            name="answer"
                                            type="answer"
                                            onChange={formik.handleChange}
                                            value={formik.values.answer}
                                            placeholder=""
                                            className="form-control"
                                            autoComplete="new-password"
                                        />
                                        {formik.errors.answer ? errorElement(formik.errors.answer) : null}
                                    </FormGroup>
                                </div>

                            </div>
                            <div className="submit-cta">
                                <CButton label="Submit" type="submit" custClassName="login-button t-btn"></CButton>
                            </div>

                        </Form>
                    </div>

                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
        </div>
    )
}

export default AddFaq