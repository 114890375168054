import ContactUs from "../Modules/Cms/ContactUs";
import FAQ from "../Modules/Cms/FAQ";
import Term from "../Modules/Cms/Terms";
import LibraryDetail from "../Modules/Library/LibraryDetail";
import LibraryOverview from "../Modules/Library/LibraryOverview";
import JoinUs from "../Modules/Plans/JoinUs";
import PageNotFound from "../Modules/Layout/404";
import OurStory from "../Modules/Cms/OurStory";
import { CommonValidator } from "./Validators/CommonValidator";
import PrivacyPolicy from "../Modules/Cms/PrivacyPolicy";


export const Common = [
    {
        path: process.env.REACT_APP_FRONT_URL + "/",
        element: <CommonValidator/>,
        errorElement:<PageNotFound/>,
        children: [
            {
                path: 'library-overview',
                element: <LibraryOverview/>
            },
            {
                path: 'library-detail/:slug',
                element: <LibraryDetail/>
            },
            {
                path: 'join-us',
                element: <JoinUs/>
            },
            {
                path: 'contact-us',
                element: <ContactUs />
            },
            {
                path: 'faq',
                element: <FAQ/>
            },
            {
                path: 'term',
                element: <Term/>
            },
            {
                path: 'our-story',
                element: <OurStory/>
            },  
            {
                path: 'privacy-policy',
                element: <PrivacyPolicy/>
            },    
        ]
    }
]