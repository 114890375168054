import React from 'react';
import { useNavigate } from 'react-router-dom';
import { urlPrefix } from '../Helpers/Common';

const CurrentOrders = ({currentOrders}) => {

    const navigate = useNavigate()

    return (
        <div className="current-orders">
        {
            currentOrders && currentOrders.length ? 
            currentOrders.map((order,index)=><div className="order-book-card" key={index}>
                <div className="book">
                    <img src={order.image_url} alt="book imag" onClick={()=> navigate(urlPrefix() + `/library-detail/${order.slug}`)}></img>
                </div>
                
                <div className="detail">
                    <div className='top-row'>
                        <div className="order-n">
                            <p className="o-title">Order nr.:</p>
                            <p className="o-sub-title">#{order.order_id}</p>
                        </div>
                        <div className="order-s-date">
                            <p className="o-title">Order on:</p>
                            <p className="o-sub-title">{order.ordered_on}</p>
                        </div>
                        <div className="order-exp-date">
                            <p className="o-title">Status:</p>
                            <p className="o-sub-title">{order.order_status}</p>
                        </div>
                    </div>
                    <div className="book-name" onClick={()=> navigate(urlPrefix() + `/library-detail/${order.slug}`)}>
                        <h3 className="heading-h3 title">{order.title}</h3>
                    </div>
                    <div className="book-detail">
                        <p className="s-title">By {order?.authors?.map((author) => author).join(" ,")}</p>
                    </div>
                   
                </div>
            </div>) : <div className='no-data'>No orders yet</div>
        }
        </div>
    )
}
export default CurrentOrders;