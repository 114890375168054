import React from 'react'
import { Modal } from 'react-bootstrap'

const ConfirmationModal = ({ confirmationModal }) => {

    const { show, data, acceptFx, rejectFx, message = null } = confirmationModal;
    return (
        <Modal className="cost-book-modal con-check membership-modal-v1-min" show={show} size="lg" onHide={() => rejectFx()} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message ?? "Are you sure, You want to proceed?"}</p>
                {/* <div className="accept-main">
                    <button onClick={() => acceptFx(data)} className="accept-reject">Yes, cancel my subscription</button>
                    <button onClick={() => rejectFx(data)} className="accept-reject">No, I'd like remain a member of House of Books</button>
                </div> */}
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => acceptFx(data)} className="accept-reject">Yes, cancel my subscription</button>
                <button onClick={() => rejectFx(data)} className="accept-reject">No, I'd like remain a member of House of Books</button>
            </Modal.Footer>`
        </Modal>
    )
}

export default ConfirmationModal;



