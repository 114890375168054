import { Modal } from 'react-bootstrap'
import React, { useEffect, useState } from "react";
import { CButton, Loading } from "../Components";
import { Form, FormGroup } from "react-bootstrap";
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { login } from "../Services/Auth";
import { useDispatch, useSelector } from "react-redux";
import { initiateSession } from "../../Redux/Slices/UserSlice";
import { validateLoginForm } from '../Auth/Validations';
import { useNavigate } from 'react-router-dom';
import { urlPrefix } from '../Helpers/Common';

// Currently it is not being used.
const LoginPopUp = ({ showLoginModal, setShowLoginModal }) => {
  const activePath = useSelector((state) => state.ActivePathReducer.value);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [passVisibility, setPassVisibility] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: ""
    },
    validate: validateLoginForm,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      let result = await login(values);
      setLoading(false);
      if (result.success) {
        dispatch(initiateSession(result.data.token))
        toast.success(result.message)
        if (["login", "register", "forgot-password", "reset-password", "verify-email"].includes(activePath[1])) {
          navigate(urlPrefix() + "/")
        }
      }
      else {
        toast.error(result.error.message)
      }
      resetForm();
      setShowLoginModal(false)
    }
  });

  const errorElement = (msg) => {
    return <div className='small text-danger'>{msg}</div>
  }

  useEffect(() => {
    if(showLoginModal){
      navigate(urlPrefix() + "/login")
      setShowLoginModal(false)
    }
  }, [showLoginModal])

  return (
    <Modal className="cost-book-modal" show={showLoginModal} size="lg" onHide={() => setShowLoginModal(false)} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title>Login?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="sign-form-main sign-modal">
          {showLoginModal}
          <Form id="loginForm" className="login-form-main" onSubmit={formik.handleSubmit}>

            <div className="cost-form-input">
              <FormGroup className="cost-form-group">
                <Form.Label>My Email </Form.Label>
                <div className="inp-msg">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    placeholder="Type Here"
                    className="form-controla"
                    autoComplete="new-password"
                  />
                  {formik.errors.email ? errorElement(formik.errors.email) : null}
                </div>
              </FormGroup>
            </div>

            <div className="cost-form-input">
              <FormGroup className="cost-form-group login-show10">
                <Form.Label>And my password is</Form.Label>
                <div className="inp-msg">
                  <input
                    id="password"
                    name="password"
                    type={passVisibility ? "text" : "password"}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    placeholder="Enter your password"
                    className="form-controla"
                    autoComplete="new-password"
                  />
                  <span className="pd-sh86" onClick={() => setPassVisibility(!passVisibility)} >{passVisibility ?
                    <div className="show-icon">
                      <img src={urlPrefix() + "/assets/image/view-green.png"} alt="book imag"></img>
                    </div>
                    :
                    <div className="hide-icon">
                      <img src={urlPrefix() + "/assets/image/eye-green.png"} alt="book imag"></img>
                    </div>
                  }
                  </span>
                  {formik.errors.password ? errorElement(formik.errors.password) : null}
                </div>
              </FormGroup>
            </div>

            <div className="submit-cta">
              <CButton label="Login" custClassName="login-button t-btn w-50"></CButton>
            </div>

            <div className="pass-Forgot">
              <CButton preventDefault={true} fxToRun={() => { setShowLoginModal(false); navigate(urlPrefix() + "/forgot-password") }} label="Forgot Password?" custClassName="cost-a t-btn"></CButton>
            </div>

          </Form>
          <div className="have-account">
            <p>Don’t have an account?
              <CButton preventDefault={true} fxToRun={() => { setShowLoginModal(false); navigate(urlPrefix() + "/register") }} label="Sign up." custClassName="cost-a t-btn"></CButton>
            </p>
          </div>
        </div>
      </Modal.Body>
      {
        loading ? <Loading /> : null
      }
    </Modal>
  )
}

export default LoginPopUp