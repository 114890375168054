import axios from "axios";
import { provideToken } from "../Helpers/Common";

export const getUserOrders = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/order`, { params, headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const getTimeSlots = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/order/time-slots`, { params, headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}


export const placeOrder = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/order`, params , { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const placeOrderToReturn = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/order/return`, params , { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const placeRenewBookOrder = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/order/renew-book`, params , { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}
