import React, { useEffect, useState } from 'react'
import { Loading } from '../../Components';
import AdminNavbar from '../Navbar/AdminNavbar';
import MakePagination from '../../Components/MakePagination';
import { getAdminCurrentlyBorrowed } from '../Services/Order';
import { upperCase } from 'lodash';

const ListCurrentlyBorrowed = () => {
    const [loading, setLoading] = useState(false);
    const [books, setBooks] = useState([]);
    const [toatalBooks, setTotalBooks] = useState(0);
    const [pageFilters, setPageFilters] = useState({})
    const [pagination, setPagination] = useState({ limit: 10, start_from: 0, search: null, order_by: [{ column: 'borrowings.id', order: 'desc' }] });

    const selectFilter = (filter, data) => {
        if (filter === "order") {
            setPagination({ ...pagination, order_by: [data] })
        }
        else if (filter === "status") {
            setPageFilters({ ...pageFilters, status: data })
            setPagination({ ...pagination, start_from: 0 })
        }
        else if (filter === "pagination") {
            setPagination({ ...pagination, start_from: data })
        }
        else if (filter === "search") {
            setPagination({ ...pagination, start_from: 0, search: data && data.length ? data.trim() : null })
        }
    }

    const getInitialPageData = async () => {
        setLoading(true)
        const result = await getAdminCurrentlyBorrowed({ ...pagination, filters: pageFilters, with_user: true, total_count_of: "borrowings.id", with_order_detail:true});
        setBooks(result.data.books)
        setTotalBooks(result.data.totalBookRecords)
        setLoading(false)
    }


    useEffect(() => {
        getInitialPageData()
    }, [pagination, pageFilters])


    return (
        <div className="admin-panel-main">
            <div className="panel-row">
                <div className="left">
                    <AdminNavbar></AdminNavbar>
                </div>
                <div className="right">
                    <div className="panel-title-top all-book">
                        <div className="top-left">
                            <h3 className="heading-h3">Currently Borrowed list</h3>
                        </div>
                    </div>
                    <div className="admin-panel-data borrowed-list85">
                        <div className="tb-res-v1">
                            <div className="top-title">
                                <div className="title">
                                    <p className="top-t">User Name</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Email</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Book</p>
                                </div>
                                <div className="e-status">
                                    <p className="top-t">Borrowed Date</p>
                                </div>
                                <div className="e-status">
                                    <p className="top-t">Renewed Date</p>
                                </div>
                                <div className="e-status">
                                    <p className="top-t">Due Date</p>
                                </div>
                                <div className="e-status">
                                    <p className="top-t">Returned Date</p>
                                </div>
                                <div className="e-status">
                                    <p className="top-t">Address</p>
                                </div>
                                <div className="e-status">
                                    <p className="top-t">Status</p>
                                </div>
                            </div>
                            <div className="book-list">
                                {
                                    books && books.length ?
                                    books.map((book) => <div className="book-row" key={book.borrowing_id}>
                                            <div className="title-value">
                                                <p className="top-value">{book.first_name} {book.last_name}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value email">{book.email}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{book.title}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{book.borrowed_date}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{book.renew_date}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{book.due_date}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{
                                                    book.scheduled_return_date ? (
                                                        <>
                                                            {book.scheduled_return_date} AT {upperCase(book.return_time_slot)}
                                                        </>
                                                        ) : "---"
                                                    }
                                                </p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{book.zipcode} {book.street_address} {book.city} {book.country}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{(book.borrowing_status == "owned") ? "Borrowed" : book.borrowing_status}</p>
                                            </div>
                                        </div>) :
                                        <div className="no-data">
                                            No Orders found.
                                        </div>
                                }
                                

                            </div>
                        </div>
                        
                    </div>
                    {
                        books && books.length ?
                            <div className="books-pagination">
                                <MakePagination totalRecords={toatalBooks} limiter={10} selectFilter={selectFilter}></MakePagination>
                            </div>
                            : null
                    }
                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
        </div>
    )
}

export default ListCurrentlyBorrowed
