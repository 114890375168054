import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Modules/Layout";
import Loading from "../../Modules/Components/Loading";
import { useLocation } from "react-router-dom";
import { updatePath } from "../../Redux/Slices/ActivePathSlice";

export const CommonValidator = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [check, setCheck] = useState("pending");

    useEffect(() => {
        setCheck("passed")
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updatePath(location.pathname))
    }, [location])// eslint-disable-line react-hooks/exhaustive-deps

    if (check === "pending") {
        return <Loading />
    }
    else {
       return <Layout />
    }
};