import React, { useEffect, useState } from 'react'
import { Form, FormGroup } from 'react-bootstrap';
import { CButton, Loading } from '../Components';
import { validateAddressForm, validateResetPasswordForm } from './Validators';
import { useFormik } from 'formik';
import { resetPassword } from '../Services/Auth';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getAddress, updateAddress } from '../Services/User';
import { urlPrefix } from '../Helpers/Common';

const ProfilePay = () => {
    const user = useSelector((state) => state.UserReducer.value);
    const [userAddress, setUserAddress] = useState({ first_name: "", last_name: "", street_address: "", city: "", zipcode: "", state: "" });
    const [loading, setLoading] = useState(false);
    const [confirmPassVisibility,setConfirmPassVisibility] = useState(false);
    const [currentPassVisibility,setCurrentPassVisibility] = useState(false);
    const [newPassVisibility,setNewPassVisibility] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            newPassword: "",
            currentPassword: "",
            confirmPassword: "",
        },
        validate: validateResetPasswordForm,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            let result = await resetPassword({ ...values, user_id: user.id });
            setLoading(false);
            resetForm()
            result.success ? toast.success(result.message) : toast.error(result.error.message)
        }
    });

    const addressformik = useFormik({
        enableReinitialize: true,
        initialValues: userAddress,
        validate: validateAddressForm,
        onSubmit: async (values) => {
            setLoading(true);
            let result = await updateAddress(values);
            setLoading(false);
            result.success ? toast.success(result.message) : toast.error(result.error.message)
        }
    });

    const errorElement = (msg) => {
        return <div className='small text-danger'>{msg}</div>
    }

    const getInitialPageData = async () => {
        setLoading(true)
        let addressForm = {...userAddress,first_name: user.first_name, last_name: user.last_name };
        const result = await getAddress();
        if (result.success) {
            addressForm = {...addressForm,...result.data}
        }
        setUserAddress(addressForm)
        setLoading(false)
    }

    useEffect(() => {
        getInitialPageData()
    }, [])

    return (
        <div className="profile1-m1">
            <h3 className="heading-h3 pro1-title">profile</h3>
            <p className="p1-detail">You’re registered with your email <a href="mailto:example@domain.com">‘{user.email}’</a> as your username, please note that it is not possible to change your username/email address thereafter.</p>

            <div className="pro1-tab">
                <div className="left">
                    <h4 className="heading-h5">About</h4>
                    <Form id="" className="login-form-main rem58" onSubmit={addressformik.handleSubmit} >
                        <div className="sign-form-col">
                            <div className="cost-form-inpu">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>First Name:</Form.Label>
                                    <input
                                        id="first_name"
                                        name="first_name"
                                        type="first_name"
                                        onChange={addressformik.handleChange}
                                        value={addressformik.values.first_name}
                                        placeholder=" "
                                        className="form-controla"
                                        autoComplete="new-password"
                                    />
                                    {addressformik.errors.first_name ? errorElement(addressformik.errors.first_name) : null}
                                </FormGroup>
                            </div>

                            <div className="cost-form-input">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>Last Name:</Form.Label>
                                    <input
                                        id="last_name"
                                        name="last_name"
                                        type="last_name"
                                        onChange={addressformik.handleChange}
                                        value={addressformik.values.last_name}
                                        placeholder=" "
                                        className="form-controla"
                                        autoComplete="new-password"
                                    />
                                    {addressformik.errors.last_name ? errorElement(addressformik.errors.last_name) : null}
                                </FormGroup>
                            </div>

                            <div className="cost-form-input">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>Street Address:</Form.Label>
                                    <input
                                        id="street_address"
                                        name="street_address"
                                        type="street_address"
                                        onChange={addressformik.handleChange}
                                        value={addressformik.values.street_address}
                                        placeholder=" "
                                        className="form-controla"
                                        autoComplete="new-password"
                                    />
                                    {addressformik.errors.street_address ? errorElement(addressformik.errors.street_address) : null}
                                </FormGroup>
                            </div>

                            {/* <div className="cost-form-input">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>Apt Number.</Form.Label>
                                    <input
                                        id="name"
                                        name="John"
                                        type="text"
                                        placeholder="Cluster Q"
                                        className="form-controla"
                                        autoComplete="new-password"
                                    />
                                </FormGroup>
                            </div>

                            <div className="cost-form-input">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>Street Address:</Form.Label>
                                    <input
                                        id="name"
                                        name="John"
                                        type="text"
                                        placeholder="Saba 3 tower, shop 14,"
                                        className="form-controla"
                                        autoComplete="new-password"
                                    />
                                </FormGroup>
                            </div> */}

                            <div className="cost-form-input">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>City:</Form.Label>
                                    <input
                                        id="city"
                                        name="city"
                                        type="city"
                                        onChange={addressformik.handleChange}
                                        value={addressformik.values.city}
                                        placeholder=" "
                                        className="form-controla"
                                        autoComplete="new-password"
                                    />
                                    {addressformik.errors.city ? errorElement(addressformik.errors.city) : null}
                                </FormGroup>
                            </div>

                            <div className="cost-form-input">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>Zipcode:</Form.Label>
                                    <input
                                        id="zipcode"
                                        name="zipcode"
                                        type="zipcode"
                                        onChange={addressformik.handleChange}
                                        value={addressformik.values.zipcode}
                                        placeholder=" "
                                        className="form-controla"
                                        autoComplete="new-password"
                                    />
                                    {addressformik.errors.zipcode ? errorElement(addressformik.errors.zipcode) : null}
                                </FormGroup>
                            </div>

                            <div className="cost-form-input">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>State:</Form.Label>
                                    <input
                                        id="state"
                                        name="state"
                                        type="state"
                                        onChange={addressformik.handleChange}
                                        value={addressformik.values.state}
                                        placeholder=" "
                                        className="form-controla"
                                        autoComplete="new-password"
                                    />
                                    {addressformik.errors.state ? errorElement(addressformik.errors.state) : null}
                                </FormGroup>
                            </div>

                        </div>
                        <div className="submit-cta">
                            <CButton label="Update" custClassName="login-button t-btn"></CButton>
                        </div>
                    </Form>
                </div>
                <div className="right">
                    <h4 className="heading-h5">Change password</h4>

                    <Form id="" className="login-form-main rem58" onSubmit={formik.handleSubmit} >
                        <div className="sign-form-col">
                            <div className="cost-form-input w10-m">
                                <FormGroup className="cost-form-group login-show10">
                                    <input
                                        id="currentPassword"
                                        name="currentPassword"
                                        type={currentPassVisibility ? "text" : "password"}
                                        onChange={formik.handleChange}
                                        value={formik.values.currentPassword}
                                        placeholder="Enter old password"
                                        className="form-controla"
                                        autoComplete="new-password"
                                    />
                                    <span className="pd-sh86 j5" onClick={()=>setCurrentPassVisibility(!currentPassVisibility)} >{currentPassVisibility ? 
                                        <div className="show-icon">
                                        <img src={urlPrefix() + "/assets/image/view-green.png"} alt="book imag"></img>
                                        </div> 
                                        : 
                                        <div className="hide-icon">
                                        <img src={urlPrefix() + "/assets/image/eye-green.png"} alt="book imag"></img>
                                        </div> 
                                        }
                                    </span>
                                    {formik.errors.currentPassword ? errorElement(formik.errors.currentPassword) : null}
                                </FormGroup>

                                <FormGroup className="cost-form-group login-show10">
                                    <input
                                        id="newPassword"
                                        name="newPassword"
                                        type={newPassVisibility ? "text" : "password"}
                                        onChange={formik.handleChange}
                                        value={formik.values.newPassword}
                                        placeholder="Enter new password"
                                        className="form-controla"
                                        autoComplete="new-password"
                                    />
                                     <span className="pd-sh86 j5" onClick={()=>setNewPassVisibility(!newPassVisibility)} >{newPassVisibility ? 
                                        <div className="show-icon">
                                        <img src={urlPrefix() + "/assets/image/view-green.png"} alt="book imag"></img>
                                        </div> 
                                        : 
                                        <div className="hide-icon">
                                        <img src={urlPrefix() + "/assets/image/eye-green.png"} alt="book imag"></img>
                                        </div> 
                                        }
                                        </span>
                                    {formik.errors.newPassword ? errorElement(formik.errors.newPassword) : null}
                                </FormGroup>

                                <FormGroup className="cost-form-group login-show10">
                                    <input
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        type={confirmPassVisibility ? "text" : "password"}
                                        onChange={formik.handleChange}
                                        value={formik.values.confirmPassword}
                                        placeholder="Repeat new password"
                                        className="form-controla"
                                        autoComplete="new-password"
                                    />
                                       <span className="pd-sh86 j5" onClick={()=>setConfirmPassVisibility(!confirmPassVisibility)} >{confirmPassVisibility ? 
                                        <div className="show-icon">
                                        <img src={urlPrefix() + "/assets/image/view-green.png"} alt="book imag"></img>
                                        </div> 
                                        : 
                                        <div className="hide-icon">
                                        <img src={urlPrefix() + "/assets/image/eye-green.png"} alt="book imag"></img>
                                        </div> 
                                        }
                                        </span>
                                    {formik.errors.confirmPassword ? errorElement(formik.errors.confirmPassword) : null}
                                </FormGroup>

                            </div>

                        </div>
                        <div className="submit-cta">
                            <CButton label="Update" custClassName="login-button t-btn"></CButton>
                        </div>
                    </Form>

                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
        </div>
    )
}

export default ProfilePay;