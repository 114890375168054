import React, { useEffect, useState } from 'react'
import { allSubscriptions } from '../Services/Subscription';
import { Loading } from '../Components';


const PaymentDone = () => {
    const [loading, setLoading] = useState(false);
    const [payments, setPayments] = useState([]);

    const getInitialPageData = async () => {
        setLoading(true)
        const result = await allSubscriptions();
        if (result?.success) {
            setPayments(result.data.payments)
        }
        setLoading(false);
    }

    useEffect(() => {
        getInitialPageData()
    }, [])

    return (
        <div className="profile1-m1">
            <h3 className="heading-h3 pro1-title">Payment</h3>

            {
                payments && payments.length ?
                    <div className="payment-done80">
                        <div className="p-head">
                            <p className="id">id</p>
                            <p className="pay-plan">Subscription Plan</p>
                            <p className="amount">Amount</p>
                            <p className="d-time">Date/Time</p>
                            <p className="p-status">borrow limit</p>
                        </div>
                        {
                            payments.map((payment, index) => <div className="pay-history" key={index}>
                                <div className="p-row">
                                    <p className="id">#{index + 1}</p>
                                    <p className="pay-plan">{payment.name} ({payment.plan_type})</p>
                                    <p className="amount">AED {payment.chargeable_price}</p>
                                    <p className="d-time">{payment.payment_date}</p>
                                    <p className="p-status">{payment.borrow_limit}</p>
                                </div>
                            </div>)
                        }
                    </div> :
                    <div className='no-data'>No successful payments yet.</div>
            }
            {
                loading ? <Loading /> : null
            }
        </div>
    )
}

export default PaymentDone;