
export const validateFaqForm = values => {
    const errors = {};

    if (!values.question) {
        errors.question = 'This field is required';
    }
    else if(values.question.length < 4 ){
        errors.question = 'Must contain at least 3 characters';
    }

    if (!values.answer) {
        errors.answer = 'This field is required';
    }    
    else if(values.answer.length < 4 ){
        errors.answer = 'Must contain at least 3 characters';
    }

    return errors;
};
