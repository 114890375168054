import "./Css/index.css";
import "./Css/App.css";
import "./Css/App-Responsive.css";
import 'react-toastify/dist/ReactToastify.css';

import React from "react";
import Routes from "./Routes";
import { ToastContainer } from 'react-toastify';

function App() {

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes />
    </React.Fragment>
  );
}
export default App;
