import React, { useState } from "react";
import { CButton, Loading } from "../Components";
import { Form, FormGroup } from "react-bootstrap";
import { useFormik } from 'formik';
import { validateLoginForm } from "./Validations";
import {  toast } from 'react-toastify';
import { login } from "../Services/Auth";
import { useDispatch } from "react-redux";
import { initiateSession } from "../../Redux/Slices/UserSlice";
import { useNavigate } from "react-router-dom";
import { urlPrefix } from "../Helpers/Common";

const Login = () => {
  const [passVisibility,setPassVisibility] = useState(false);
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: ""
    },
    validate: validateLoginForm,
    onSubmit: async (values,{resetForm}) => {
      setLoading(true);
      let result = await login(values);
      setLoading(false);
      if(result.success){
        dispatch(initiateSession(result.data.token))
        toast.success(result.message)
        navigate(urlPrefix() + "/")
      }
      else{
        toast.error(result.error.message)
      } 
      resetForm()
    }
  });

  const errorElement = (msg) => {
    return <div className='small text-danger'>{msg}</div>
  }
  
  return (
    <React.Fragment>  
      <div className="sign-in-form-wrap">

        <div className="login-main-wrap">
          <h5 className="heading-h5 login-subtitle">Login</h5>
          <h1 className="heading-h2 login-title">Welcome back to House of Books</h1>
        </div>

        <div className="sign-form-main sign859a">
          <Form id="loginForm" className="login-form-main"  onSubmit={formik.handleSubmit}>

            <div className="cost-form-input">
              <FormGroup className="cost-form-group">
                <Form.Label>My Email (your username) </Form.Label>
                <div className="inp-msg">
                <input 
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  placeholder="Type here"
                  className="form-controla" 
                  autoComplete="new-password"
                 />
                 {formik.errors.email ? errorElement(formik.errors.email) : null}
                 </div>
              </FormGroup>
            </div>

            <div className="cost-form-input">
              <FormGroup className="cost-form-group login-show10">
                <Form.Label>And my password is</Form.Label>
                <div className="inp-msg">
                <input
                  id="password"
                  name="password"
                  type={passVisibility ? "text" : "password"}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  placeholder="Enter your password"
                  className="form-controla"
                  autoComplete="new-password"
                />
                <span className="pd-sh86" onClick={()=>setPassVisibility(!passVisibility)} >{passVisibility ? 
                <div className="show-icon">
                   <img src={urlPrefix() + "/assets/image/view-green.png"} alt="book imag"></img>
                </div> 
                : 
                <div className="hide-icon">
                  <img src={urlPrefix() + "/assets/image/eye-green.png"} alt="book imag"></img>
                </div> 
                }
                </span>
                {formik.errors.password ? errorElement(formik.errors.password) : null}
                </div>
              </FormGroup>
            </div>

            <div className="submit-cta">
              <CButton label="Login" custClassName="login-button t-btn w-50"></CButton>
            </div>

            <div className="pass-Forgot">
              <CButton redirectTo="forgot-password" label="Forgot Password?" custClassName="cost-a t-btn"></CButton>
            </div>

          </Form>
          <div className="have-account">
            <p>Don’t have an account?
              <CButton redirectTo="register" label="Sign up." custClassName="cost-a t-btn"></CButton>
            </p>
          </div>
        </div>
      </div>
      {
        loading ? <Loading /> : null
      }
    </React.Fragment>
  );
};
export default Login;
