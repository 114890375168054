import axios from "axios";
import { provideToken } from "../Helpers/Common";

export const updateAddress = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/user/add-address`,params,{headers:{authorization:`Bearer ${provideToken()}`}});
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const getAddress = async () => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/user/address`,{headers:{authorization:`Bearer ${provideToken()}`}});
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const getCards = async () => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/user/cards`,{headers:{authorization:`Bearer ${provideToken()}`}});
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const removeCard = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/user/cards/remove`, params , { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const updateDefaultCard = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/user/cards/update-default`, params , { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const getAdminAllUsers = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/admin/get-all-users`, { params, headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}