import { createSlice } from "@reduxjs/toolkit";

 export const PaginationSlice = createSlice({
   name:'bookPagination',
   initialState:{
    value:{
        startFrom: 0,
        pageNumber: 1,
        search: null,
        categories: null,
        author: null,
        publisher: null,
        special:null
    }
   },
   reducers:{
        changePage: (state, action) => {
            state.value = { ...(action.payload) }
        },
        resetPages: (state) => {
          state.value = {
            startFrom: 0,
            pageNumber: 1,
            search: null,
            categories: null,
            author: null,
            publisher: null,
            special:null
          }
        }
   }
 })

 export const { changePage, resetPages } = PaginationSlice.actions;

 export default PaginationSlice.reducer;