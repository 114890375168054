import axios from "axios";

export const contactUs = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/cms/contact-us`, params);
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const getFaqs = async (params) => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/cms/faqs`,{ params });
        return data
    } catch (error) {
        return error?.response?.data
    }
};