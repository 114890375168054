import React, { useEffect, useState } from 'react';
import useClickAway from '../../CustomHooks/ClickAwayHook';
import { upperFirst } from 'lodash';

const GeneralFilter = ({ options, defaultValue, value, fxToRun, clearText, specialName}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const clickAwayRef = useClickAway(() => {
        if(isOpen){
            setIsOpen(false)
        }
    })

    const selectOption = (option) => {
        setSelectedOption(option);
        if(fxToRun){
            fxToRun(option)
        }
        setIsOpen(false);
    };

    useEffect(() => {
        setSelectedOption(specialName);
    }, [specialName]);

    return (
        <div className={`custom-dropdown ${isOpen ? 'open' : ''}`} ref={clickAwayRef}>
            <div className="selected-option category" onClick={() => setIsOpen(!isOpen)}>
                {selectedOption ? upperFirst(selectedOption[value]) : upperFirst(defaultValue)}
            </div>
            {isOpen && (
                <div className="dropdown-options">
                    <div className="option" onClick={() => selectOption(null)}>
                        {clearText}
                    </div>
                    {options.map((option, index) => (
                        <div key={index} className="option" onClick={() => selectOption(option)}>
                            {selectedOption && selectedOption[value] === option[value] ? <b>{upperFirst(option[value])}</b> : upperFirst(option[value])}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default GeneralFilter;
