import React, { useEffect, useState } from 'react';
import useClickAway from '../../CustomHooks/ClickAwayHook';

const CostDropdown  = ({selectFilter, clearData, setClearData }) => {
  const options = [
    { column: 'books.id', order: 'asc', name: "Default" },
    { column: 'books.date_published', order: 'asc', name: "Publication (old to new)" },
    { column: 'books.date_published', order: 'desc', name: "Publication (new to old)" },
  ]
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({ column: 'books.id', order: 'asc', name: "Please select" });
  const clickAwayRef = useClickAway(()=>{
    if(isOpen){
      setIsOpen(false)
    }
  })
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    selectFilter("order",option)
    setIsOpen(false);
  };

  useEffect(() => {
    if (clearData) {
      setSelectedOption({ column: 'books.id', order: 'asc', name: "Please select" });
      setClearData(false)
    }
  }, [clearData])

  return (
    <div className={`custom-dropdown ${isOpen ? 'open' : ''}`} ref={clickAwayRef}>
      <div className="selected-option popular" onClick={toggleDropdown}>
        {selectedOption.name}
      </div>
      {isOpen && (
        <div className="dropdown-options">
          {options.map((option) => (
            <div key={option.name} className="option" onClick={() => selectOption(option)}>
            {selectedOption.name === option.name ? <b>{option.name}</b> : <>{option.name}</>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CostDropdown;
