import React, {useState, useEffect } from "react";
import {CButton, Loading} from "../Components";
import { useNavigate } from "react-router-dom";
import { Form, FormGroup, Accordion } from "react-bootstrap";
import { getFaqs } from "../Services/Cms";
import { urlPrefix } from "../Helpers/Common";

const FAQ = () => {
  const [accKey,setAccKey] = useState(null);
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  const [faqs, setFaqs] = useState([]);

  const fetchFAQs = async () => {
    setLoading(true)
    const result = await getFaqs();
    setLoading(false)
    result.success ? setFaqs(result.data) : setFaqs([])
  };

  useEffect(() => {
    fetchFAQs();
  }, []);

  useEffect(()=>{
    setTimeout(()=>{
      const accordionItems = document.querySelectorAll('.accordion-item');
      accordionItems.forEach(item => {
        const accordionShow = item.querySelector('.show');
        if (accordionShow) {
          item.classList.add('item-show');
        }else{
          item.classList.remove('item-show');
        }
      });
    },400)
  },[accKey])

  return (  
    <React.Fragment>
      <div className="library-overview-hero faq">
        <div className="house_container">
            <h5 className="heading-h5 overview-subtitle">FAQS</h5>
            <h1 className="heading-h1 overview-title">Your Questions Answered</h1>
        </div>
      </div>

      <div className="join-questions-main faq">
        <div className="right">
          <div className="faq-accordion">
            <Accordion activeKey={accKey} >
              { faqs && faqs.length ? (
                faqs.map((faq, index) => (
                  <Accordion.Item key={index} eventKey={index} onClick={()=>setAccKey(index === accKey ? null : index)}>
                    <Accordion.Header>{index + 1}. {faq.question}</Accordion.Header>
                    <Accordion.Body dangerouslySetInnerHTML={{__html: faq.answer}}></Accordion.Body>
                  </Accordion.Item>
                ))
              ) : (
                <div className="no-data">
                  <p>No faqs found.</p>
                </div>
              )}
            </Accordion>
            </div>
        </div>   
      </div>


      <section className="becoming-member">
        <div className="house_container">
          <div className="image">
            <img src={urlPrefix() + "/assets/image/christin-hume.png"} alt="book imag"></img>
          </div>
          <div className="content">
            <h2 className="heading-h2">Becoming a Member is Easy.</h2>
            <p className="detail">Our members can borrow from a wide range of specially selected books with free pick-up and delivery.</p>
            <div className="cta">
              <CButton fxToRun={()=>navigate(urlPrefix() +  "/join-us")} label="Join Us" custClassName="white-btn-w t-btn"></CButton>
            </div>
          </div>
        </div>
      </section>
      {
        loading ? <Loading/> : null
      }
    </React.Fragment>
  );
};
export default FAQ;