import React, { useEffect, useState } from 'react';
import { CButton, GeneralFilter, Loading } from '../../Components';
import AdminNavbar from '../Navbar/AdminNavbar';
import MakePagination from '../../Components/MakePagination';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { capitalize, toLower } from 'lodash';
import { useDebounce } from '../../../CustomHooks/DebounceHook';
import { getAdminAllUsers } from '../../Services/User';

const Listusers = () => {
    const dropDownOptions = [{ id: 1, value: "1", name: "verified", filter: "status" }, { id: 2, value: "0", name: "not verified", filter: "status" }]
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [totalusers, setTotalusers] = useState(0);
    const [users, setUsers] = useState([]);
    const [pageFilters, setPageFilters] = useState({})
    const [pagination, setPagination] = useState({ limit: 10, start_from: 0, search: null, order_by: [{ column: 'users.id', order: 'desc' }] });
    const debouncedSearch = useDebounce(search,500);

    const selectFilter = (filter, data) => {
        if (filter === "order") {
            setPagination({ ...pagination, order_by: [data] })
        }
        else if (filter === "status") {
            setPageFilters({ ...pageFilters, status: data })
            setPagination({ ...pagination, start_from: 0 })
        }
        else if (filter === "pagination") {
            setPagination({ ...pagination, start_from: data })
        }
        else if (filter === "search") {
            setPagination({ ...pagination, start_from: 0, search: data && data.length ? data.trim() : null })
        }
    }


    const generalFilterFx = (option) => {
        selectFilter("status", option?.value)
    }

    const getInitialPageData = async () => {
        setLoading(true)
        const result = await getAdminAllUsers({ ...pagination, filters: pageFilters, total_count_of: "users.id" });
        setUsers(result.data.users);
        setTotalusers(result.data.totalUsersRecords)
        setLoading(false)
    }

    useEffect(() => {
        getInitialPageData()
    }, [pagination, pageFilters])

    useEffect(()=>{ 
        selectFilter("search",search)
    },[debouncedSearch])

    return (
        <div className="admin-panel-main">
            <div className="panel-row">
                <div className="left">
                    <AdminNavbar></AdminNavbar>
                </div>
                <div className="right">
                    <div className="panel-title-top">
                        <div className="top-left">
                            <h3 className="heading-h3">All Users</h3>
                        </div>
                        <div className="top-right admin-filter">
                            <div className="right-side">
                                <CButton fxToRun={() => window.location.reload()} label="Reset" custClassName="login-button t-btn clear-btn-v1"></CButton>
                            </div>
                        </div>
                    </div>

                    <div className="dash-filter filter">
                        <div className="left-fil">
                            <GeneralFilter options={dropDownOptions} value="name" defaultValue="Verification" fxToRun={generalFilterFx} clearText={"Select Verification"}/>
                        </div>
                        <div className="search">
                            <InputGroup className="admin-search-input" onChange={(e) => setSearch(e.target.value)} >
                                <Form.Control placeholder="Search User name" value={search || ""} />
                            </InputGroup>
                        </div>
                    </div>

                    <div className="admin-panel-data admin-user-list">
                        <div className="tb-res-v1">
                            <div className="top-title">
                                <div className="order-no">
                                    <p className="top-t">Name</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Email</p>
                                </div>
                                {/* <div className="title">
                                    <p className="top-t"></p>
                                </div> */}
                                <div className="title">
                                    <p className="top-t">Verified</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Subscribed</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Status</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Register On</p>
                                </div>
                            </div>
                            <div className="book-list">

                                {
                                    users && users.length ?
                                        users.map((user) => <div className="book-row" key={user.user_id}>
                                            <div className="order-no-value">
                                                <p className="top-value">{user.first_name}</p>
                                            </div>
                                            <div className="order-no-value">
                                                <p className="top-value">{toLower(user.email)}</p>
                                            </div>
                                            {/* <div className="order-no-value">
                                                <p className="top-value"></p>
                                            </div> */}
                                            <div className="order-no-value">
                                                <p className="top-value">{(user.email_verified) ? "Yes" : "No"}</p>
                                            </div>
                                            <div className="order-no-value">
                                                <p className="top-value">{(user.subscribed) ? "Yes" : "No"}</p>
                                            </div>
                                            <div className="order-no-value">
                                                <p className="top-value">{user.user_status === 1 ? 'Active' : 'Inactive'}</p>
                                            </div>
                                            <div className="order-no-value">
                                                <p className="top-value">{user.register_on}</p>
                                            </div>
                                            
                                            
                                        </div>) :
                                        <div className="no-data">
                                            No User Found.
                                        </div>
                                }


                            </div>
                        </div>
                    </div>
                    {
                        users && users.length ?
                            <div className="books-pagination">
                                <MakePagination totalRecords={totalusers} limiter={10} selectFilter={selectFilter}></MakePagination>
                            </div> : null
                    }
                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
        </div>
    )
}

export default Listusers