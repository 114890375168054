import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Public } from "./Public";
import { Common } from "./Common"
import { Protected } from "./Protected";
import { Admin } from "./Admin";
import { useSelector } from "react-redux";

const Routes = () => {
    const user = useSelector((state) => state.UserReducer.value);
    
    const provideRoutes = () => {
        if(user.authenticated && user.role === 1 ){
            return Admin
        }
        else if(user.authenticated && user.role !== 1){
            return [...Protected, ...Common]
        }
        else{
            return [...Public,...Common]
        }
    }

    return <RouterProvider router={createBrowserRouter(provideRoutes())} />
}
export default Routes;