import React, { useEffect, useState } from 'react'
import { CButton, Loading } from '../../Components';
import { useNavigate } from 'react-router-dom';
import AdminNavbar from '../Navbar/AdminNavbar';
import { Button, Form, InputGroup } from 'react-bootstrap';
import MakePagination from '../../Components/MakePagination';
import { urlPrefix } from '../../Helpers/Common';
import { getAdminAuthors } from '../Services/Author';
import { getAdminCategories } from '../Services/Categories';
import { useDebounce } from '../../../CustomHooks/DebounceHook';

const ListCategories = () => {
    const navigate = useNavigate()
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [totalCategories, setTotalCategories] = useState(0);
    const [pagination, setPagination] = useState({ limit: 10, start_from: 0, search: null, order_by: [{ column: 'categories.id', order: 'desc' }] });
    const debouncedSearch = useDebounce(search,500);

    const selectFilter = (filter, data) => {
        if (filter === "order") {
            setPagination({ ...pagination, order_by: [data] })
        }
        else if (filter === "pagination") {
            setPagination({ ...pagination, start_from: data })
        }
        else if (filter === "search") {
            setPagination({ ...pagination, start_from: 0, search: data && data.length ? data.trim() : null })
        }
    }

    const getInitialPageData = async () => {
        setLoading(true)
        const result = await getAdminCategories({ ...pagination, total_count_of: "categories.id" });
        setCategories(result.data.categories)
        setTotalCategories(result.data.totalCategoryRecords)
        setLoading(false)
    }

    useEffect(() => {
        getInitialPageData()
    }, [pagination])

    useEffect(()=>{ 
        selectFilter("search",search)
    },[debouncedSearch])

    return (
        <div className="admin-panel-main">
            <div className="panel-row">
                <div className="left">
                    <AdminNavbar></AdminNavbar>
                </div>
                <div className="right">
                    <div className="panel-title-top all-book">
                        <div className="top-left">
                            <h3 className="heading-h3">Categories list</h3>
                        </div>
                        <div className="top-right admin-filter">
                            <div className="right-side">
                                <CButton fxToRun={() => { navigate(urlPrefix() + "/admin/categories/insert") }} label="Add Category" custClassName="login-button t-btn"></CButton>
                            </div>
                        </div>
                    </div>

                    <div className="dash-filter filter">
                        <div className="left-fil" />
                        <div className="search">
                            <InputGroup className="admin-search-input" onChange={(e) => setSearch(e.target.value)} >
                                <Form.Control placeholder="Search Category name" value={search || ""} />
                            </InputGroup>
                        </div>
                    </div>

                    <div className="admin-panel-data">
                        <div className="top-title all-plans">
                            <div className="order-no">
                                <p className="top-t">Name</p>
                            </div>
                            <div className="title">
                                <p className="top-t">Description</p>
                            </div>
                            <div className="e-edit-delete">
                                <p className="top-t">Actions</p>
                            </div>
                        </div>
                        <div className="book-list">
                            {
                                categories && categories.length ?
                                    categories.map((category) => <div className="book-row all-plans" key={category.category_id}>
                                        <div className="order-no-value">
                                            <p className="top-value">{category.name}</p>
                                        </div>
                                        <div className="title-value">
                                            <p className="top-value">{category.description}</p>
                                        </div>
                                        <div className="e-edit-delete-value">
                                            <a className="edit-action" onClick={() => navigate(urlPrefix() + `/admin/categories/edit/${category.category_id}`)}><img src={urlPrefix() + "/assets/image/edit-green.png"} alt='book-image' /></a>
                                            {/* <a className="delete-action" href="#"><img src={urlPrefix() + "/assets/image/trash.svg"} alt='book-image' /></a> */}
                                        </div>
                                    </div>) :
                                    <div className='no-data'>
                                        No Categories found.
                                    </div>
                            }

                            {
                                categories && categories.length ?
                                    <div className="books-pagination">
                                        <MakePagination totalRecords={totalCategories} limiter={10} selectFilter={selectFilter}></MakePagination>
                                    </div> : null
                            }

                        </div>
                    </div>
                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
        </div>
    )
}

export default ListCategories
