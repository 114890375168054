import moment from "moment";

export const validateAddBookForm = values => {
  const errors = {};
  if (!values.publishers || !values.publishers.length) {
    errors.publishers = 'This field is required';
  }

  if(values.image){
    const file_type = (values.image.type).split("/")[1];
    if(!["png","jpg","jpeg","webp"].includes(file_type)){
      errors.image = 'Please upload jpg, jpeg, webp and png image.';
    }
    else if(values.image.size > (5 * 1024 * 1024)){
      errors.image = 'Size must be less than 5 mb.';
    }
  }

  if (!values.dimensions) {
    errors.dimensions = 'This field is required';
  }

  if (!values.title) {
    errors.title = 'This field is required';
  }
  if (!values.synopsis) {
    errors.synopsis = 'This field is required';
  }

  // if (!values.why_we_love_it) {
  //   errors.why_we_love_it = 'This field is required';
  // }

  if (!values.edition) {
    errors.edition = 'This field is required';
  }
  else if(values.edition.trim().length > 20){
    errors.edition = 'Length must 20 or less.';
  }

  if (!values.binding) {
    errors.binding = 'This field is required';
  }

  if (!values.ISBN10 && !values.ISBN13) {
    errors.ISBN10 = 'Either ISBN10 or ISBN13 required.';
    errors.ISBN13 = 'Either ISBN10 or ISBN13 required.';
  }

  if (!values.date_published) {
    errors.date_published = 'This field is required';
  }
  else if(!moment(values.date_published,"YYYY-MM-DD",true).isValid()){
    errors.date_published = 'Please enter date in format (YYYY-MM-DD)';
  }
  else if(moment(values.date_published).isAfter("2090-01-01")){
    errors.date_published = 'Please enter date less then 2090-01-01';
  }

  if (!values.language) {
    errors.language = 'This field is required';
  }

  if (!values.quantity_available && values?.quantity_available < 0) {
    errors.quantity_available = 'This field is required';
  }

  if (!values.authors || !values.authors.length) {
    errors.authors = 'This field is required';
  }

  if (!values.categories || !values.categories.length) {
    errors.categories = 'This field is required';
  }

  return errors;
};