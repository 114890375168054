import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { initiateSession, terminateSession } from '../../../Redux/Slices/UserSlice'
import { urlPrefix } from '../../Helpers/Common'
import { adminUploadImage } from '../Services/Admin'
import { toast } from 'react-toastify'
import { resetPages } from '../../../Redux/Slices/PaginationSlice'

const AdminNavbar = () => {
    const user = useSelector((state) => state.UserReducer.value);
    const activePath = useSelector((state) => state.ActivePathReducer.value);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logOut = () => {
        dispatch(terminateSession())
        dispatch(resetPages())
        navigate(urlPrefix() + "/")
    }

    const handleImageUpload = async (e) => {
        const input = e.target;
        const image = document.getElementById("img-preview");
        if (input.files.length) {
            const src = URL.createObjectURL(input.files[0]);
            let result = await adminUploadImage(input.files[0]);
            if (result?.success) {
                image.src = src;
                toast.success(result.message);
                dispatch(initiateSession(result.data.token));
            } else {
                toast.error(result.error.message);
            }
        }
    };

    useEffect(() => {
        const input = document.getElementById("file-input");
        input.addEventListener("change", handleImageUpload);
        return () => {
            input.removeEventListener("change", handleImageUpload);
        };
    }, []);


    return (
        <div className="admin-left">
            <div className="top">
                <div className="logo">
                    <img className="fixed-menu-logo" src={urlPrefix() + "/assets/image/mobile-logo.png"} alt='login-logo' />
                </div>
                <div className="panel-title">
                    <p>Admin panel</p>
                </div>
                <div className="panel-name">
                    <div className="up-img152">
                        <div className="image">
                            <img  id="img-preview" className="fixed-menu-logo" src={user.picture} alt='login-logo' />
                        </div>
                        <div className="upload-img-icon">
                            <label for="file-input">
                                <img src="/assets/image/upload-image-icon.png" alt='login-logo' />
                            </label>
                            <input accept="image/*" type="file" id="file-input" />
                        </div>
                    </div>
                    <div className="detail">
                        <p className="name">{user.first_name} {user.last_name}</p>
                        <p className="member">Member</p>
                    </div>
                </div>
                <div className="menu-list">
                    <ul className="admin-menu-main">
                        <li onClick={() => navigate(urlPrefix() + "/admin/books")} className={`admin-menu ${activePath[2] === "" || activePath[2] === "books" ? "active" : null}`}>
                            <a title="Books">
                                <span><img src={urlPrefix() + "/assets/image/books-list.svg"} alt='admin-nav-icon' /></span>
                                <span>Books</span>
                            </a>
                        </li>
                        <li onClick={() => navigate(urlPrefix() + "/admin/publishers")} className={`admin-menu ${activePath[2] === "" || activePath[2] === "publishers" ? "active" : null}`}>
                            <a title="Publishers">
                                <span><img src={urlPrefix() + "/assets/image/books-list.svg"} alt='admin-nav-icon' /></span>
                                <span>Publishers</span>
                            </a>
                        </li>
                        <li onClick={() => navigate(urlPrefix() + "/admin/categories")} className={`admin-menu ${activePath[2] === "" || activePath[2] === "categories" ? "active" : null}`}>
                            <a title="Categories">
                                <span><img src={urlPrefix() + "/assets/image/books-list.svg"} alt='admin-nav-icon' /></span>
                                <span>Categories</span>
                            </a>
                        </li>
                        <li onClick={() => navigate(urlPrefix() + "/admin/authors")} className={`admin-menu ${activePath[2] === "" || activePath[2] === "authors" ? "active" : null}`}>
                            <a title="Authors">
                                <span><img src={urlPrefix() + "/assets/image/books-list.svg"} alt='admin-nav-icon' /></span>
                                <span>Authors</span>
                            </a>
                        </li>
                        <li onClick={() => navigate(urlPrefix() + "/admin/plans")} className={`admin-menu ${activePath[2] === "plans" ? "active" : null}`}>
                            <a title="Plans">
                                <span><img src={urlPrefix() + "/assets/image/history.svg"} alt='admin-nav-icon' /></span>
                                <span>Plans</span>
                            </a>
                        </li>
                        <li onClick={() => navigate(urlPrefix() + "/admin/membership-plans")} className={`admin-menu ${activePath[2] === "membership-plans" ? "active" : null}`}>
                            <a title="Profile">
                                <span><img src={urlPrefix() + "/assets/image/profile.svg"} alt='admin-nav-icon' /></span>
                                <span>Membership Plans</span>
                            </a>
                        </li>
                        <li onClick={() => navigate(urlPrefix() + "/admin/borrowed")} className={`admin-menu ${activePath[2] === "borrowed" ? "active" : null}`}>
                            <a title="Retrieval Orders">
                                <span><img src={urlPrefix() + "/assets/image/profile.svg"} alt='admin-nav-icon' /></span>
                                <span>Currently Borrowed</span>
                            </a>
                        </li>
                        <li onClick={() => navigate(urlPrefix() + "/admin/orders")} className={`admin-menu ${activePath[2] === "orders" ? "active" : null}`}>
                            <a title="Orders">
                                <span><img src={urlPrefix() + "/assets/image/profile.svg"} alt='admin-nav-icon' /></span>
                                <span>Orders</span>
                            </a>
                        </li>
                        <li onClick={() => navigate(urlPrefix() + "/admin/retrievals")} className={`admin-menu ${activePath[2] === "retrievals" ? "active" : null}`}>
                            <a title="Retrieval Orders">
                                <span><img src={urlPrefix() + "/assets/image/profile.svg"} alt='admin-nav-icon' /></span>
                                <span>Retrieval Orders</span>
                            </a>
                        </li>
                        <li onClick={() => navigate(urlPrefix() + "/admin/subscriptions")} className={`admin-menu ${activePath[2] === "subscriptions" ? "active" : null}`}>
                            <a title="Subscriptions">
                                <span><img src={urlPrefix() + "/assets/image/payment-active.svg"} alt='admin-nav-icon' /></span>
                                <span>Subscriptions</span>
                            </a>
                        </li>
                        <li onClick={() => navigate(urlPrefix() + "/admin/user-refunds")} className={`admin-menu ${activePath[2] === "user-refunds" ? "active" : null}`}>
                            <a title="User Refunds">
                                <span><img src={urlPrefix() + "/assets/image/payment-active.svg"} alt='admin-nav-icon' /></span>
                                <span>User Refunds</span>
                            </a>
                        </li>
                        <li onClick={() => navigate(urlPrefix() + "/admin/defaulters")} className={`admin-menu ${activePath[2] === "defaulters" ? "active" : null}`}>
                            <a title="Defaulters">
                                <span><img src={urlPrefix() + "/assets/image/payment-active.svg"} alt='admin-nav-icon' /></span>
                                <span>Defaulters</span>
                            </a>
                        </li>
                        <li onClick={() => navigate(urlPrefix() + "/admin/faqs")} className={`admin-menu ${activePath[2] === "faqs" ? "active" : null}`}>
                            <a title="FAQ's">
                                <span><img src={urlPrefix() + "/assets/image/payment-active.svg"} alt='admin-nav-icon' /></span>
                                <span>FAQ's</span>
                            </a>
                        </li>
                        <li onClick={() => navigate(urlPrefix() + "/admin/inquiries")} className={`admin-menu ${activePath[2] === "inquiries" ? "active" : null}`}>
                            <a title="Inquiries">
                                <span><img src={urlPrefix() + "/assets/image/payment-active.svg"} alt='admin-nav-icon' /></span>
                                <span>Inquiries</span>
                            </a>
                        </li>
                        <li onClick={() => navigate(urlPrefix() + "/admin/users")} className={`admin-menu ${activePath[2] === "users" ? "active" : null}`}>
                            <a title="All Users">
                                <span><img src={urlPrefix() + "/assets/image/payment-active.svg"} alt='admin-nav-icon' /></span>
                                <span>All Users</span>
                            </a>
                        </li>
                        
                    </ul>
                </div>
            </div>
            <div className="bottom">
                <div className="log-out">
                    <a title="Log out" onClick={() => logOut()}>
                        <span><img src={urlPrefix() + "/assets/image/log-out.svg"} alt='admin-logo-out' /></span>
                        <span>Log out</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default AdminNavbar