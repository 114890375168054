import React, { useEffect, useState } from 'react'
import {  Loading } from '../../Components';
import AdminNavbar from '../Navbar/AdminNavbar';
import { Button, Form, InputGroup } from 'react-bootstrap';
import MakePagination from '../../Components/MakePagination';
import { urlPrefix } from '../../Helpers/Common';
import { getAdminBooksRetrievalOrders } from '../Services/Order';
import ViewRetrievalOrderDetail from './Components/ViewRetrievalOrderDetail';
import { useDebounce } from '../../../CustomHooks/DebounceHook';

const ListRetrievalOrders = () => {
    const [show, setShow] = useState(false);
    const [retrievalOrderDetail, setRetrievalOrderDetail] = useState(null);
    const [userId, setUserId] = useState("");
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [booksToRetrieve, setBooksToRetrieve] = useState([]);
    const [totalBookToRetrieveRecords, setTotalBookToRetrieveRecords] = useState(0);
    const [pageFilters, setPageFilters] = useState({})
    const [pagination, setPagination] = useState({ limit: 10, start_from: 0, search: null, order_by: [{ column: 'borrowings.id', order: 'desc' }] });
    const debouncedSearch = useDebounce(search,500);

    const selectFilter = (filter, data) => {
        if (filter === "order") {
            setPagination({ ...pagination, order_by: [data] })
        }
        else if (filter === "status") {
            setPageFilters({ ...pageFilters, status: data })
            setPagination({ ...pagination, start_from: 0 })
        }
        else if (filter === "pagination") {
            setPagination({ ...pagination, start_from: data })
        }
        else if (filter === "search") {
            setPagination({ ...pagination, start_from: 0, search: data && data.length ? data.trim() : null })
        }
    }

    const getInitialPageData = async () => {
        setLoading(true)
        const result = await getAdminBooksRetrievalOrders({ ...pagination, filters: pageFilters, books_to_retrieve_child_form: true, total_count_of: "booksToRetrieve.id" });
        setBooksToRetrieve(result.data.booksToRetrieve)
        setTotalBookToRetrieveRecords(result.data.totalOrderRecords)
        setLoading(false)
    }


    useEffect(() => {
        getInitialPageData()
    }, [pagination, pageFilters])

    useEffect(()=>{ 
        selectFilter("search",search)
    },[debouncedSearch])

    return (
        <div className="admin-panel-main">
            <div className="panel-row">
                <div className="left">
                    <AdminNavbar></AdminNavbar>
                </div>
                <div className="right">
                    <div className="panel-title-top all-book">
                        <div className="top-left">
                            <h3 className="heading-h3">Retrieval Orders list</h3>
                        </div>
                    </div>

                    <div className="dash-filter filter">
                        <div className="left-fil">

                        </div>
                        <div className="search">
                            <InputGroup className="admin-search-input" onChange={(e) => setSearch(e.target.value)} >
                                <Form.Control placeholder="Search User name" value={search || ""} />
                            </InputGroup>
                        </div>
                    </div>

                    <div className="admin-panel-data retrieval-order">
                        <div className="top-title">
                            <div className="order-no">
                                <p className="top-t">Date</p>
                            </div>
                            <div className="order-no">
                                <p className="top-t">Name</p>
                            </div>
                            <div className="title">
                                <p className="top-t">Email</p>
                            </div>
                            <div className="e-status">
                                <p className="top-t">Address</p>
                            </div>
                            <div className="e-edit-delete">
                                <p className="top-t">Action</p>
                            </div>
                        </div>
                        <div className="book-list">
                            {
                                booksToRetrieve && booksToRetrieve.length ?
                                    booksToRetrieve.map((order, index) => <div className="book-row" key={index}>
                                        <div className="order-no-value">
                                            <p className="top-value">{order.return_issued_on}</p>
                                        </div>
                                        <div className="order-no-value">
                                            <p className="top-value">{order.first_name} {order.last_name}</p>
                                        </div>
                                        <div className="title-value">
                                            <p className="top-value">{order.email}</p>
                                        </div>
                                        <div className="title-value">
                                            <p className="top-value">{order.zipcode} {order.street_address} {order.city} {order.country}</p>
                                        </div>
                                        <div className="title-value">
                                            <p className="top-value">
                                                <button className="accept-reject" onClick={() => { setUserId(order.user_id); setRetrievalOrderDetail(order.books_to_retrieve); setShow(true) }}>View Detail</button></p>
                                        </div>
                                    </div>) :
                                    <div className="no-data">
                                        No Retrieval orders found.
                                    </div>
                            }
                            {
                                booksToRetrieve && booksToRetrieve.length ?
                                    <div className="books-pagination">
                                        <MakePagination totalRecords={totalBookToRetrieveRecords} limiter={10} selectFilter={selectFilter}></MakePagination>
                                    </div>
                                    : null
                            }

                        </div>
                    </div>
                </div>
            </div>
            <ViewRetrievalOrderDetail userId={userId} getInitialPageData={getInitialPageData} show={show} setShow={setShow} retrievalOrderDetail={retrievalOrderDetail} />
            {
                loading ? <Loading /> : null
            }
        </div>
    )
}

export default ListRetrievalOrders
