import React, { useEffect, useState } from 'react'
import {  useNavigate } from 'react-router-dom';
import { AdminDeleteMembershipPlan, getAdminPlans } from '../Services/Plans';
import { CButton, Loading } from '../../Components';
import AdminNavbar from '../Navbar/AdminNavbar';
import { urlPrefix } from '../../Helpers/Common';
import MakePagination from '../../Components/MakePagination';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useDebounce } from '../../../CustomHooks/DebounceHook';
import { toast } from 'react-toastify';
import ConfirmationModal from '../Books/Components/ConfirmationModal';

const ListMembershipPlans = () => {
    const [confirmationModal, setConfirmationModal] = useState({ show: false, data: null, acceptFx: null, rejectFx: null,message:null });
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState([]);
    const [totalPlans,setTotalPlans] = useState(0);
    const [pagination, setPagination] = useState({ limit: 10, start_from: 0, search: null , order_by: [{ column: 'plans.id', order: 'desc' }] });
    const debouncedSearch = useDebounce(search,500);

    const getInitialPageData = async () => {
        setLoading(true)
        const result = await getAdminPlans({...pagination ,membership_plans:true,total_count_of:"plans.id"});
        setPlans(result.data.plans)
        setTotalPlans(result.data.totalPlanRecords)
        setLoading(false)
    }

    const selectFilter = (filter, data) => {
        if (filter === "order") {
            setPagination({ ...pagination, order_by: [data] })
        }
        else if(filter === "pagination"){
            setPagination({...pagination, start_from:data})
        }
        else if(filter === "search"){
            setPagination({ ...pagination, start_from: 0, search: data && data.length ? data.trim() : null })
        }
    }

    const deleteMembershipPlan = async (data) => {
        const { id } = data
        setLoading(true)
        const result = await AdminDeleteMembershipPlan({ id });
        if (result.success) {
            getInitialPageData()
            toast.success(result.message)
        }
        else {
            toast.error(result.error.message)
        }
        setConfirmationModal({ show: false, data: null, acceptFx: null, rejectFx: null,message:null })
        setLoading(false)
    }

    useEffect(() => {
        getInitialPageData()
    }, [pagination])

    useEffect(()=>{ 
        selectFilter("search",search)
    },[debouncedSearch])

    return (
        <div className="admin-panel-main">
            <div className="panel-row">
                <div className="left">
                    <AdminNavbar></AdminNavbar>
                </div>
                <div className="right">
                    <div className="panel-title-top">
                        <div className="top-left">
                            <h3 className="heading-h3">Membership Plans</h3>
                        </div>
                        <div className="right-side">
                            <CButton fxToRun={() => { navigate(urlPrefix() + "/admin/membership-plans/insert") }} label="Add Membership Plan" custClassName="login-button t-btn"></CButton>
                        </div>
                    </div>

                    <div className="dash-filter filter">
                        <div className="left-fil" />
                        <div className="search">
                            <InputGroup className="admin-search-input" onChange={(e) => setSearch(e.target.value)} >
                                <Form.Control placeholder="Search Plan name" value={search || ""} />
                            </InputGroup>
                        </div>
                    </div>

                    <div className="admin-panel-data">
                        <div className="top-title member-pl5692">
                            <div className="order-no">
                                <p className="top-t">Name</p>
                            </div>
                            <div className="title">
                                <p className="top-t">Type</p>
                            </div>
                            <div className="title">
                                <p className="top-t">Price</p>
                            </div>
                            <div className="title">
                                <p className="top-t">Discounted Price</p>
                            </div>
                            <div className="title">
                                <p className="top-t">Refundable Deposit</p>
                            </div>
                            <div className="title">
                                <p className="top-t">Borrow Limit</p>
                            </div>
                            <div className="e-edit-delete">
                                <p className="top-t">Actions</p>
                            </div>
                        </div>
                        <div className="book-list">
                            {
                                plans && plans.length ?
                                    plans.map((plan) => <div className="book-row member-plans904" key={plan.plan_detail_id}>
                                        <div className="order-no-value">
                                            <p className="top-value">{plan.name}</p>
                                        </div>
                                        <div className="title-value">
                                            <p className="top-value">{plan.plan_type}</p>
                                        </div>
                                        <div className="title-value">
                                            <p className="top-value">{plan.price}</p>
                                        </div>
                                        <div className="title-value">
                                            <p className="top-value">{plan.chargeable_price}</p>
                                        </div>
                                        <div className="title-value">
                                            <p className="top-value">{plan.refundable_deposit}</p>
                                        </div>
                                        <div className="title-value">
                                            <p className="top-value">{plan.borrow_limit}</p>
                                        </div>
                                        <div className="e-edit-delete-value">
                                            <a className="edit-action"
                                            onClick={() => setConfirmationModal({
                                                show: true,
                                                data: {id:plan.membership_plan_id},
                                                message:"Are you sure you want to delete Membership Plan ?",
                                                acceptFx: deleteMembershipPlan,
                                                rejectFx: () => { setConfirmationModal({ show: false, data: null, acceptFx: null, rejectFx: null, message:null}) }
                                            })}
                                            ><img src={urlPrefix() + "/assets/image/trash.svg"} alt='book-image' /></a>
                                        </div>
                                    </div>) :
                                    <div className='no-data'>
                                        No Membership Plans found.
                                    </div>
                            }
                        </div>
                        {
                            plans && plans.length ?
                            <div className="books-pagination">
                                <MakePagination totalRecords={totalPlans} limiter={10} selectFilter={selectFilter}></MakePagination>
                            </div> :
                            null
                        }
                    </div>
                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
            <ConfirmationModal confirmationModal={confirmationModal} />
        </div>
    )
}

export default ListMembershipPlans