import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import CurrentOrders from './CurrentOrders';
import PreviousOrders from './PreviousOrders';
import { useSelector } from 'react-redux';
import { getUserOrders } from '../Services/Order';

const  OrderHistory = () => {
    const user = useSelector((state) => state.UserReducer.value);
    const [loading,setLoading] = useState(false);
    const [currentOrders,setCurrentOrders] = useState([]);
    const [previousOrders,setPreviousOrders] = useState([]);

    const getInitialPageData = async () => {
        setLoading(true)
        const requests = [
            getUserOrders({ filters: { order_status: ["pending", "approved"] }, remove_limit_offset: true, include_authors:true, order_by : [{column: 'order_items.id', order: 'desc'}]}),
            getUserOrders({ filters: { order_status: ["retrieved","completed", "cancelled"] }, remove_limit_offset: true, include_authors:true, order_by : [{column: 'order_items.id', order: 'desc'}]})
        ]
        const results = await Promise.all(requests);
        setCurrentOrders(results[0].data)
        setPreviousOrders(results[1].data)
        setLoading(false)
    }

    useEffect(() => {
        getInitialPageData()
    }, [])

    return (
        <div className="profile1-m1">
            <h3 className="heading-h3 pro1-title">Order history</h3>
            <div className="order-history">
                <Tabs defaultActiveKey="Current Orders" id="OrderHistory" className="cost-order-tab" fill >
                    <Tab eventKey="Current Orders" title="Current Orders">
                        <CurrentOrders currentOrders = {currentOrders}></CurrentOrders>
                    </Tab>
                    <Tab eventKey="Previous Orders" title="Previous Orders">
                        <PreviousOrders previousOrders = {previousOrders}></PreviousOrders>
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}
export default  OrderHistory;