import React, { useEffect, useState } from 'react';
import { useDebounce } from '../../CustomHooks/DebounceHook';
import { filter } from 'lodash';
import useClickAway from '../../CustomHooks/ClickAwayHook';

const PublisherFilter = ({ options, selectFilter, openedDropdown, dropdownId, setOpenedDropDown, clearData, setClearData, publisherName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [sortableOptions, setSortableOptions] = useState([])
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 700);
  
  const clickAwayRef = useClickAway(()=>{
    if(setOpenedDropDown && openedDropdown && dropdownId){
      if(openedDropdown === dropdownId){
        setOpenedDropDown(null)
      }
    }
  })

  const toggleDropdown = () => {
    if (dropdownId) {
      if (isOpen) {
        setOpenedDropDown(null)
      }
      else {
        setOpenedDropDown(dropdownId)
      }
    }
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    selectFilter("publisher", option.publisher_id)
    setSelectedOption(option.name);
    setIsOpen(false);
  };

  useEffect(() => {
    if (clearData) {
      setSelectedOption(null);
      setClearData(false)
    }
  }, [clearData])

  useEffect(() => {
    if (dropdownId && openedDropdown !== dropdownId) {
      setIsOpen(false)
    }
  }, [openedDropdown])

  useEffect(() => {
    setSortableOptions(options)
  }, [options])

  const sortOptions = (searchInput) => {
    searchInput = searchInput && searchInput.length ? searchInput.trim() : null
    if (searchInput && searchInput.length) {
      setSortableOptions(filter(options, (item) => {
        return item.name.toLowerCase().includes(searchInput.toLowerCase());
      }))
    }
    else {
      setSortableOptions(options)
    }
  }

  useEffect(() => {
    sortOptions(search)
  }, [debouncedSearch]);

  useEffect(() => {
      setSelectedOption(publisherName);
  }, [publisherName]);

  return (
    <div className={`custom-dropdown ${isOpen ? 'open' : ''}`} ref={clickAwayRef}>
      <div className="selected-option publisher" onClick={toggleDropdown}>
        {selectedOption ? selectedOption : 'Publisher'}
      </div>
      {isOpen && (
        <div className="dropdown-options">
          <div className="drop-filter-main">
            <input type="text" className="drop-input" value={search} onChange={(e) => setSearch(e.target.value)} />
          </div>
          <div className="option" onClick={() => {selectOption({ publisher_id: null, name: "Publisher" }); setSearch("")}}>
            Select Publisher
          </div>
          {sortableOptions.map((option) => (
            <div key={option.publisher_id} className="option" onClick={() => selectOption(option)}>
            {selectedOption === option.name ? <b>{option.name}</b> : <>{option.name}</>}
            </div>
          ))}
          {
            !sortableOptions.length ? <div className="option">No result found</div> : null
          }
        </div>
      )}
    </div>
  );
};

export default PublisherFilter;
