import axios from "axios";

export const verifyToken = async (token) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/verify-token`, { token });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const login = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/login`, params );
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const register = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/register`, params );
        return data
    } catch (error) {
        return error?.response?.data
    }
}
export const forgotPassword = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/forgot-password`, params );
        return data
    } catch (error) {
        return error?.response?.data
    }
}
export const resetPassword = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/reset-password`, params );
        return data
    } catch (error) {
        return error?.response?.data
    }
}
export const verifyEmail = async (token) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/verify-email/${token}`);
        return data
    } catch (error) {
        return error?.response?.data
    }
}
export const resendEmailVerification = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/resend-email-verification`, params);
        return data
    } catch (error) {
        return error?.response?.data
    }
}



