export const validateContactUsForm = values => {
    const errors = {};
  
    if (!values.name) {
      errors.name = 'This field is required';
    }
  
    if (!values.email && !values.phone_number) {
      errors.email = 'One field is required email or phone number';
      errors.phone_number = 'One field is required email or phone number';
    } else if ((values.email) && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))) {
      errors.email = 'Invalid email address';
    }

    if (!values.message) {
      errors.message = 'This field is required';
    }
  
  
    return errors;
  };