import React, { useState } from "react";
import { CButton, Loading } from "../Components";
import { Form, FormGroup } from "react-bootstrap";
import { useFormik } from "formik";
import { validateRegisterForm } from "./Validations";
import { register } from "../Services/Auth";
import { toast } from "react-toastify";
import { urlPrefix } from "../Helpers/Common";

const Register = () => {
  const [loading,setLoading] = useState(false);
  const [passVisibility,setPassVisibility] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name:"",
      email: "",
      password: ""
    },
    validate: validateRegisterForm,
    onSubmit: async (values) => {
      setLoading(true)
      let result = await register(values);
      setLoading(false)
      result?.success ? toast.success(result.message) : toast.error(result.error.message)
    }
  });

  const errorElement = (msg) => {
    return <div className='small text-danger'>{msg}</div>
  }

  return (
    <React.Fragment>

      <div className="sign-in-form-wrap">

        <div className="login-main-wrap sign">
          <h5 className="heading-h5 login-subtitle reg10">Sign Up</h5>
          <h1 className="heading-h2 login-title sign-title">welcome to house of books</h1>
        </div>

        <div className="sign-form-main sign">
          <Form id="loginForm" className="login-form-main" onSubmit={formik.handleSubmit}>
            <div className="sign-form-col sign859a">
              <div className="cost-form-input w-55">
                <FormGroup className="cost-form-group">
                  <Form.Label>Hi, my name is</Form.Label>
                  <div className="inp-msg">
                  <input
                    id="first_name"
                    name="first_name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.first_name}
                    placeholder="Type here"
                    className="form-controla"
                    autoComplete="new-password"
                  />
                  {formik.errors.first_name ? errorElement(formik.errors.first_name) : null}
                  </div>
                </FormGroup>
              </div>

              <div className="cost-form-input w-45 c-mail-w1">
                <FormGroup className="cost-form-group">
                  <Form.Label className="col-sp">and my Email Address is</Form.Label>
                </FormGroup>
              </div>

              <div className="cost-form-input w-45 c-mail-w">
              <div className="inp-msg">
                <FormGroup className="cost-form-group">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    placeholder="Your Email Address"
                    className="form-controla col-sp"
                    autoComplete="new-password"
                  />
                  {formik.errors.email ? errorElement(formik.errors.email) : null}
                </FormGroup>
                </div>
              </div>

              <div className="cost-form-input w-80">
                <FormGroup className="cost-form-group  login-show10">
                  <Form.Label>and my password will be</Form.Label>
                  <div className="inp-msg">
                  <input
                    id="password"
                    name="password"
                    type={passVisibility ? "text" : "password"}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    placeholder="Enter your password"
                    className="form-controla"
                    autoComplete="new-password"
                  />
                  <span className="pd-sh86" onClick={()=>setPassVisibility(!passVisibility)} >{passVisibility ? 
                    <div className="show-icon">
                      <img src={urlPrefix() + "/assets/image/view-green.png"} alt="book imag"></img>
                    </div> 
                    : 
                    <div className="hide-icon">
                      <img src={urlPrefix() + "/assets/image/eye-green.png"} alt="book imag"></img>
                    </div> 
                    }
                </span>
                {formik.errors.password ? errorElement(formik.errors.password) : null}
                </div>
                </FormGroup>
              </div>

            </div>

            <div className="submit-cta">
              <CButton label="Sign Up" custClassName="login-button t-btn"></CButton>
            </div>

          </Form>
          <div className="have-account sign-ragi">
            <p>Already have an account? Please
              <CButton redirectTo="login" label="Login" custClassName="cost-a t-btn"></CButton>
            </p>
          </div>
        </div>
      </div>
      {
        loading ? <Loading /> : null
      }
    </React.Fragment>
  );
};
export default Register;
