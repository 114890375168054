import React, { useEffect, useState } from "react";
import { OverviewCard, CButton, CostDropdown, CategoryFilter, AuthorFilter, PublisherFilter, Loading } from "../Components"
import { getAuthors } from "../Services/Authors.";
import { getPublishers } from "../Services/Publishers";
import { getCategories } from "../Services/Categories";
import { getBooks } from "../Services/Books";
import { useSelector } from "react-redux";
import { urlPrefix } from "../Helpers/Common";
import { useNavigate } from "react-router-dom";
import useClickAway from "../../CustomHooks/ClickAwayHook";


const LibraryOverview = () => {
  const overview_page_state = JSON.parse(localStorage.getItem("overview_page_state"));
  const key = new URLSearchParams(window.location.search).get("book");
  const cat = new URLSearchParams(window.location.search).get("cat");
  const navigate = useNavigate();
  const [openedDropdown, setOpenedDropDown] = useState(null)
  const user = useSelector((state) => state.UserReducer.value);
  const [loading, setLoading] = useState(false);
  const [pageFilters, setPageFilters] = useState({ categories: [cat ?? null], authors: [], publishers: [] });
  const [pagination, setPagination] = useState({ limit: (overview_page_state?.limit || 8), start_from: 0, search: key && key.length ? key : null, search_authors: key && key.length ? key : null, order_by: [{ column: 'books.id', order: 'asc' }] });

  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [books, setBooks] = useState([]);
  const [totalBooks, setTotalBooks] = useState(0);
  const [catName, setCatName] = useState(null);
  const [clearData, setClearData] = useState(false);
  
  const MobileFilter = (ele) => {
    var srcElement = document.getElementById(ele);
    if (srcElement != null) {
      if (srcElement.style.display == "block") {
        srcElement.style.display = 'none';
      }
      else {
        srcElement.style.display = 'block';
      }
      return false;
    }
  };

  const mobilFilterClickAwayRef = useClickAway(() => {
    if (document.getElementById("divMsg").style.display === "block") {
      MobileFilter("divMsg")
    }
  }, "exclude_for_mobile_filter")

  const getInitialPageData = async () => {
    setLoading(true);
    const requests = [getAuthors({ remove_limit_offset: true }), getPublishers({ remove_limit_offset: true }), getCategories({ remove_limit_offset: true })];
    const results = await Promise.all(requests);
    setAuthors(results[0].data)
    setPublishers(results[1].data)
    setCategories(results[2].data)
    if (cat && cat.length) {
            const sortableOptions = results[2].data;
            for(var i=0; i < sortableOptions.length; i++) {
              if (sortableOptions[i].category_id == cat) {
                  setCatName(sortableOptions[i].name);
                  break;
              }
            }
      }
    setLoading(false);
  }

  const getBooksWithFilters = async () => {
    setLoading(true);
    let result = await getBooks({ filters: pageFilters, ...pagination, include_categories: true, total_count_of: "books.id" })
    setBooks(result.data.books)
    setTotalBooks(result.data.totalBookRecords)
    setLoading(false);

    if (overview_page_state) {
      setTimeout(() => window.scrollTo(0, overview_page_state.scroll), 500)
      localStorage.removeItem("overview_page_state")
    }
  }

  const selectFilter = (filter, data) => {
    if (filter === "category") {
      setPageFilters({ ...pageFilters, categories: [data ?? null] })
      setPagination({ ...pagination, start_from: 0 })
    }
    else if (filter === "author") {
      setPageFilters({ ...pageFilters, authors: [data ?? null] })
      setPagination({ ...pagination, start_from: 0 })
    }
    else if (filter === "publisher") {
      setPageFilters({ ...pageFilters, publishers: [data ?? null] })
      setPagination({ ...pagination, start_from: 0 })
    }
    else if (filter === "order") {
      setPagination({ ...pagination, order_by: [data, { column: 'books.id', order: 'asc' }] })
    }
    else if (filter === "search") {
      const term_to_search = data && data.length ? data.trim() : null
      setPagination({ ...pagination, search: term_to_search, search_authors: term_to_search })
    }
  }

  const clearFilter = () => {
    setPageFilters({ categories: [null], authors: [], publishers: [] })
    setPagination({ limit: 8, start_from: 0, search: null, search_authors: null, order_by: [{ column: 'books.id', order: 'asc' }] })
    setClearData(true)
  }

  useEffect(() => {
    getInitialPageData()
  }, [])

  useEffect(() => {
    getBooksWithFilters()
  }, [pageFilters, pagination])

  useEffect(() => {
    if (key && key.length) {
      selectFilter("search", key)
    }
  }, [key])

  const loadMore = (pagination) => {
    setPagination({ ...pagination, limit: pagination.limit + 8 })
  }


  return (
    <React.Fragment>
      <div className="library-overview-hero">
        <div className="house_container">
          <h5 className="heading-h5 overview-subtitle">The library</h5>
          <h1 className="heading-h1 overview-title">Grab your next read</h1>
          <h5 className="heading-h5 overview-detail">Browse our collection</h5>
        </div>
      </div>

      <div className="overview-filter-main">

        <div className="overview-clear-filter text-right">
          {
            ((key && key.length) || (pageFilters?.categories != "" && pageFilters?.categories.length > 0) || pageFilters?.authors?.length > 0 || pageFilters?.publishers.length > 0) ? <>
              <h5 className="heading-h5">{key}</h5>
              <button className="accept-reject" onClick={() => { navigate(urlPrefix() + "/library-overview"); clearFilter() }}>Clear</button>
            </> : null
          }
        </div>

        <div className="overview-top-title">
          <div className="left">
            <h5 className="heading-h5">filter on:</h5>
          </div>
          <div className="right">
            <h5 className="heading-h5">Sort by:</h5>
          </div>
        </div>

        <div className="filter-row">
          <div className="left-area">
            <div className="filter">
              <CategoryFilter setOpenedDropDown={setOpenedDropDown} dropdownId={1} openedDropdown={openedDropdown} options={categories} selectFilter={selectFilter} catid={catName} clearData={clearData} setClearData={setClearData}></CategoryFilter>
            </div>
            <div className="filter">
              <PublisherFilter setOpenedDropDown={setOpenedDropDown} dropdownId={2} openedDropdown={openedDropdown} options={publishers} selectFilter={selectFilter}  clearData={clearData} setClearData={setClearData}></PublisherFilter>
            </div>
            <div className="filter">
              <AuthorFilter setOpenedDropDown={setOpenedDropDown} dropdownId={3} openedDropdown={openedDropdown} options={authors} selectFilter={selectFilter}  clearData={clearData} setClearData={setClearData}></AuthorFilter>
            </div>
          </div>
          <div className="right-area">
            <CostDropdown selectFilter={selectFilter} clearData={clearData} setClearData={setClearData}></CostDropdown>
          </div>
        </div>

        <div className="filter-mobile">
          <div className="left-mobile">
            <input type="button" id="exclude_for_mobile_filter" value="filter on" onClick={() => { MobileFilter('divMsg') }} />
            <div id="divMsg" ref={mobilFilterClickAwayRef}>
              <div className="left-area">
                <div className="top-title">
                  <input type="button" value="filter on" onClick={() => { MobileFilter('divMsg') }} />
                </div>
                <div className="filter">
                  <CategoryFilter setOpenedDropDown={setOpenedDropDown} dropdownId={4} openedDropdown={openedDropdown} options={categories} setOptions={setCategories} selectFilter={selectFilter} catid={catName} clearData={clearData} setClearData={setClearData}></CategoryFilter>
                </div>
                <div className="filter">
                  <PublisherFilter setOpenedDropDown={setOpenedDropDown} dropdownId={5} openedDropdown={openedDropdown} options={publishers} setOptions={setPublishers} selectFilter={selectFilter} clearData={clearData} setClearData={setClearData}></PublisherFilter>
                </div>
                <div className="filter">
                  <AuthorFilter setOpenedDropDown={setOpenedDropDown} dropdownId={6} openedDropdown={openedDropdown} options={authors} setOptions={setAuthors} selectFilter={selectFilter} clearData={clearData} setClearData={setClearData}></AuthorFilter>
                </div>
              </div>
            </div>
          </div>
          <div className="right-mobile">
            <CostDropdown selectFilter={selectFilter} clearData={clearData} setClearData={setClearData}></CostDropdown>
          </div>
        </div>
      </div>

      <div className="overview-card-row">
        <OverviewCard books={books} getBooksWithFilters={getBooksWithFilters} bookLimit={pagination?.limit}></OverviewCard>
      </div>

      <div className="overview-footer">
        <div className="load-row">
          <div className="load-more">
            {
              pagination?.limit < totalBooks ?
                <CButton label="Load More" fxToRun={loadMore} data={pagination} custClassName="cost-btn t-btn"></CButton>
                : null
            }
          </div>
          <div className="go-to-top">
            {
              books && books.length ?
                <CButton fxToRun={() => window.scrollTo(0, 0)} label="Go to top" custClassName="cost-btn t-btn"></CButton>
                : null
            }
          </div>
        </div>
      </div>

      <section className="becoming-member">
        <div className="house_container">
          <div className="image">
            <img src={urlPrefix() + "/assets/image/christin-hume.png"} alt="book imag"></img>
          </div>
          <div className="content">
            <h2 className="heading-h2">Becoming a Member is Easy.</h2>
            <p className="detail">Our members can borrow from a wide range of specially selected books with free pick-up and delivery.</p>
            <div className="cta">
              <CButton fxToRun={() => navigate(urlPrefix() + "/join-us")} label="Join Us" custClassName="white-btn-w t-btn"></CButton>
            </div>
          </div>
        </div>
      </section>

      {
        loading ? <Loading /> : null
      }
    </React.Fragment>
  );
};
export default LibraryOverview;