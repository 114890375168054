
export const validateAddPlanForm = values => {
    const errors = {};

    const { name, ...rest } = values

    if (!name) {
        errors.name = 'This field is required';
    }

    if (rest && Object.getOwnPropertyNames(rest).length > 0) {
        for (let [key, value] of Object.entries(rest)) {
            value = value ? value.trim() : value
            if (!value || !value.length) {
                errors[key] = "This field is required"
            }
        }
    }

    return errors;
};



export const validateAddMembershipPlanForm = values => {
    const errors = {};

    if (!values.plan_id) {
        errors.plan_id = 'This field is required';
    }

    if (!values.borrow_limit) {
        errors.borrow_limit = 'This field is required';
    }

    if (values.price) {
        let price = values.price.toString().split(".");
        if(price.length > 1 && price[1].length > 2){
            errors.price = 'Must be in format: 0.00';
        }
    }

    if (!values.chargeable_price) {
        errors.chargeable_price = 'This field is required';
    }
    else{
        let chargeable_price = values.chargeable_price.toString().split(".");
        if(chargeable_price.length > 1 && chargeable_price[1].length > 2){
            errors.chargeable_price = 'Must be in format: 0.00';
        }
    }

    if (!values.plan_type || !values.plan_type.length) {
        errors.plan_type = 'This field is required';
    }

    return errors;
};