export const displayDimensions = (inputString,withDefault = false) => {
    const extractValue = (pattern, input) => {
        if (!input) {
           return null;
        }
        input = input.replace(/\s{2,}/g, ' ')
        const match = input.match(new RegExp(pattern, 'i'));
        return match ? parseFloat(match[1]) : null;
    };
    const height = extractValue(/Height: (\d+(\.\d+)?) Inches/, inputString);
    const length = extractValue(/Length: (\d+(\.\d+)?) Inches/, inputString);
    const width = extractValue(/Width: (\d+(\.\d+)?) Inches/, inputString);

    if (height && length && width) {
        return `${height.toFixed(2)} X ${length.toFixed(2)} X ${width.toFixed(2)}`
    }
    else {
        return withDefault ? "1 X 1 X 1" : inputString; 
    }
}

export const provideToken = () => {
    return localStorage.getItem("token")
}

export const urlPrefix = () => {
    return process.env.REACT_APP_FRONT_URL
}