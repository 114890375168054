import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { getAdminUserRefunds } from '../Services/Subscriptions';
import { CButton, GeneralFilter, Loading } from '../../Components';
import AdminNavbar from '../Navbar/AdminNavbar';
import { urlPrefix } from '../../Helpers/Common';
import MakePagination from '../../Components/MakePagination';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { capitalize, toLower, find ,upperCase } from 'lodash';
import { useDebounce } from '../../../CustomHooks/DebounceHook';
import StatusDropDown from './Components/StatusDropDown';
import { refundStatus } from '../../../Config/Constant';
import NoteModal from '../Subscriptions/Components/NoteModal';

const ListRefunds = () => {
    const dropDownOptions = [{ id: 1, name: "pending", filter: "status" }, { id: 2, name: "initiated", filter: "status" }, { id: 3, name: "completed", filter: "status" }, { id: 4, name: "cancelled", filter: "status" }]
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [totalSubscriptions, setTotalSubscriptions] = useState(0);
    const [refund, setRefund] = useState([]);
    const [pageFilters, setPageFilters] = useState({})
    const [pagination, setPagination] = useState({ limit: 10, start_from: 0, search: null, order_by: [{ column: 'user_refunds.id', order: 'desc' }] });
    const debouncedSearch = useDebounce(search,500);
    const [openedDropdown, setOpenedDropDown] = useState(null)
    const [noteModal,setNoteModal] = useState({show:false, rejectFx: null, message:null});

    const selectFilter = (filter, data) => {
        if (filter === "order") {
            setPagination({ ...pagination, order_by: [data] })
        }
        else if (filter === "status") {
            setPageFilters({ ...pageFilters, status: data })
            setPagination({ ...pagination, start_from: 0 })
        }
        else if (filter === "pagination") {
            setPagination({ ...pagination, start_from: data })
        }
        else if (filter === "search") {
            setPagination({ ...pagination, start_from: 0, search: data && data.length ? data.trim() : null })
        }
    }


    const generalFilterFx = (option) => {
        selectFilter("status", option?.id)
    }

    const getInitialPageData = async () => {
        setLoading(true)
        const result = await getAdminUserRefunds({ ...pagination, filters: pageFilters, total_count_of: "user_refunds.id" });
        console.log(result);
        setRefund(result.data.subscriptions);
        setTotalSubscriptions(result.data.totalSubscriptionRecords)
        setLoading(false)
    }

    const showNote = (note) => {
        // setsShowReason(note)
        setNoteModal({
            show:true,
            message:note,
            rejectFx:()=>{setNoteModal({show:false, rejectFx: null, message:null})}
        })
    }

    useEffect(() => {
        getInitialPageData()
    }, [pagination, pageFilters])

    useEffect(()=>{ 
        selectFilter("search",search)
    },[debouncedSearch])

    return (
        <div className="admin-panel-main">
            <div className="panel-row">
                <div className="left">
                    <AdminNavbar></AdminNavbar>
                </div>
                <div className="right">
                    <div className="panel-title-top">
                        <div className="top-left">
                            <h3 className="heading-h3">Refunds List</h3>
                        </div>
                    </div>

                    {/* <div className="dash-filter filter">
                        <div className="left-fil">
                            <GeneralFilter options={dropDownOptions} value="name" defaultValue="Status" fxToRun={generalFilterFx} clearText={"Select Status"}/>
                        </div>
                        <div className="search">
                            <InputGroup className="admin-search-input" onChange={(e) => setSearch(e.target.value)} >
                                <Form.Control placeholder="Search User name" value={search || ""} />
                            </InputGroup>
                        </div>
                    </div> */}

                    <div className="admin-panel-data list_refunds-v1">
                        <div className="tb-res-v1">
                            <div className="top-title">
                                <div className="order-no">
                                    <p className="top-t">Name</p>
                                </div>
                                
                                <div className="title">
                                    <p className="top-t">Email</p>
                                </div>
                                
                                <div className="title">
                                    <p className="top-t">Plan Name</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Plan Type</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Refundable Deposit</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Start Date</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Refundable Amount</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">End Date</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Borrowed books</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Stripe Refund Status</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">By</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Status</p>
                                </div>
                            </div>
                            <div className="book-list">

                                {
                                    refund && refund.length ?
                                        refund.map((sub) => <div className="book-row" key={sub.plan_detail_id}>
                                            <div className="order-no-value">
                                                <p className="top-value">{sub.first_name}</p>
                                            </div>
                                            
                                            <div className="order-no-value">
                                                <p className="top-value">{sub.email}</p>
                                            </div>
                                            
                                            <div className="order-no-value">
                                                <p className="top-value">{sub.name}</p>
                                            </div>
                                            <div className="order-no-value">
                                                <p className="top-value">{capitalize(sub.plan_type)}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{sub?.refundable_deposit ?? "--"}</p>
                                            </div>
                                            
                                            <div className="title-value">
                                                <p className="top-value">{sub?.refund_initiate_date}</p>
                                            </div>
                                                                                    
                                            <div className="title-value">
                                                <p className="top-value">{sub?.refundable_amount ?? "--"}</p>
                                            </div>
                                            
                                            <div className="title-value">
                                                <p className="top-value">{sub?.completed_date ?? "--"}</p>
                                            </div>
                                            <div className="title-value">
                                            {
                                            sub?.borrowed_books_count > 0 ? 
                                            <>
                                                <CButton fxToRun={() => { navigate(urlPrefix() + "/admin/borrowed") }} label={sub?.borrowed_books_count}></CButton>
                                            </> : 
                                            <>
                                                <p className="top-value">{sub?.borrowed_books_count ?? "--"}</p>
                                            </> 
                                            }
                                                
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{sub?.stripe_refund_status ?? "--"}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{sub?.initiated_by ?? "--"}</p>
                                            </div>
                                            <div className="title-value">
                                                <div className="fil-drop">
                                                    {/* <h1>{refundStatus[sub?.refund_status] }</h1> */}
                                                    <StatusDropDown getInitialPageData={getInitialPageData} note={sub.message}  setOpenedDropDown={setOpenedDropDown} dropdownId={sub.refund_id} openedDropdown={openedDropdown} options={dropDownOptions} currentSelected={find(dropDownOptions, { name: refundStatus[sub?.refund_status] })} />
                                                    <p className="top-value">{sub?.message ? <>
                                                        <button onClick={(e) => showNote(sub?.message)} className="note-modal">
                                                            <img src="/assets/image/note-icon.png" alt="admin-logo-out"/>
                                                        </button>
                                                    </> : ""}</p>
                                                </div>
                                            </div>
                                        </div>) :
                                        <div className="no-data">
                                            No Refund Request found.
                                        </div>
                                }


                            </div>
                        </div>
                    </div>

                    {
                        refund && refund.length ?
                            <div className="books-pagination">
                                <MakePagination totalRecords={totalSubscriptions} limiter={50} selectFilter={selectFilter}></MakePagination>
                            </div> : null
                    }
                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
            <NoteModal noteModal={noteModal} />
        </div>
    )
}

export default ListRefunds