import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getAdminSubscriptions } from '../Services/Subscriptions';
import { GeneralFilter, Loading } from '../../Components';
import AdminNavbar from '../Navbar/AdminNavbar';
import { urlPrefix } from '../../Helpers/Common';
import MakePagination from '../../Components/MakePagination';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { capitalize, toLower } from 'lodash';
import { useDebounce } from '../../../CustomHooks/DebounceHook';
import { cancelRecurringPayments } from '../../Services/Subscription';
import { toast } from 'react-toastify';
import ConfirmationModal from './Components/ConfirmationModal';
import NoteModal from './Components/NoteModal';

const ListSubscription = () => {
    const dropDownOptions = [{ id: 1, value: "1", name: "active", filter: "status" }, { id: 2, value: "0", name: "inactive", filter: "status" }]
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [totalSubscriptions, setTotalSubscriptions] = useState(0);
    const [subscription, setSubscriptions] = useState([]);
    const [pageFilters, setPageFilters] = useState({})
    const [pagination, setPagination] = useState({ limit: 10, start_from: 0, search: null, order_by: [{ column: 'user_subscribed_plans.id', order: 'desc' }] });
    const debouncedSearch = useDebounce(search,500);
    const [confirmationModal,setConfirmationModal] = useState({show:false, data: null, acceptFx: null, rejectFx: null,message:null});
    const [noteModal,setNoteModal] = useState({show:false, rejectFx: null, message:null});

    const selectFilter = (filter, data) => {
        if (filter === "order") {
            setPagination({ ...pagination, order_by: [data] })
        }
        else if (filter === "status") {
            setPageFilters({ ...pageFilters, status: data })
            setPagination({ ...pagination, start_from: 0 })
        }
        else if (filter === "pagination") {
            setPagination({ ...pagination, start_from: data })
        }
        else if (filter === "search") {
            setPagination({ ...pagination, start_from: 0, search: data && data.length ? data.trim() : null })
        }
    }


    const generalFilterFx = (option) => {
        selectFilter("status", option?.value)
    }

    const getInitialPageData = async () => {
        setLoading(true)
        const result = await getAdminSubscriptions({ ...pagination, filters: pageFilters, total_count_of: "user_subscribed_plans.id" });
        setSubscriptions(result.data.subscriptions);
        setTotalSubscriptions(result.data.totalSubscriptionRecords)
        setLoading(false)
    }

    const cancelUserSubscription = async (data) => {
        setLoading(true);
        if (!data?.reason) {
            toast.error("Reason field is required.");
            // setConfirmationModal({show:true, data: null, acceptFx: null, rejectFx: null,message:null})
            setLoading(false)
            return
        }
        const result = await cancelRecurringPayments({initiated_by:"admin", id:data.id,reason:data.reason });
        if (result?.success) {
            toast.success(result.message)
            getInitialPageData()
        } else {
            toast.error(result.error.message)
        }
        setConfirmationModal({show:false, data: null, acceptFx: null, rejectFx: null,message:null})
        setLoading(false)
    }

    const showNote = (note) => {
        // setsShowReason(note)
        setNoteModal({
            show:true,
            message:note,
            rejectFx:()=>{setNoteModal({show:false, rejectFx: null, message:null})}
        })
    }

    useEffect(() => {
        getInitialPageData()
    }, [pagination, pageFilters])

    useEffect(()=>{ 
        selectFilter("search",search)
    },[debouncedSearch])

    return (
        <div className="admin-panel-main">
            <div className="panel-row">
                <div className="left">
                    <AdminNavbar></AdminNavbar>
                </div>
                <div className="right">
                    <div className="panel-title-top">
                        <div className="top-left">
                            <h3 className="heading-h3">Subscription List</h3>
                        </div>
                    </div>

                    <div className="dash-filter filter">
                        <div className="left-fil">
                            <GeneralFilter options={dropDownOptions} value="name" defaultValue="Status" fxToRun={generalFilterFx} clearText={"Select Status"}/>
                        </div>
                        <div className="search">
                            <InputGroup className="admin-search-input" onChange={(e) => setSearch(e.target.value)} >
                                <Form.Control placeholder="Search User name" value={search || ""} />
                            </InputGroup>
                        </div>
                    </div>

                    <div className="admin-panel-data order-list85 subscription-list">
                        <div className="tb-res-v1">
                            <div className="top-title">
                                <div className="order-no">
                                    <p className="top-t">Name</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Email</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Plan Name</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Plan Type</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Borrow Limit</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Recurring</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Status</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Start Date</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">End Date</p>
                                </div>
                                <div className="title">
                                    <p className="top-t">Action</p>
                                </div>
                            </div>
                            <div className="book-list">

                                {
                                    subscription && subscription.length ?
                                        subscription.map((sub) => <div className="book-row" key={sub.plan_detail_id}>
                                            <div className="order-no-value">
                                                <p className="top-value">{sub.first_name}</p>
                                            </div>
                                            <div className="order-no-value">
                                                <p className="top-value">{toLower(sub.email)}</p>
                                            </div>
                                            <div className="order-no-value">
                                                <p className="top-value">{capitalize(sub.name)}</p>
                                            </div>
                                            <div className="order-no-value">
                                                <p className="top-value">{capitalize(sub.plan_type)}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{sub.borrow_limit}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{sub.recurring === 1 ? 'On' : 'Off'}</p>
                                            </div>
                                            <div className="order-no-value">
                                                <p className="top-value">{sub.status === 1 ? 'Active' : 'Inactive'}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{sub.start_date}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{sub.end_date}</p>
                                            </div>
                                            <div className="title-value">
                                             <div className="not-mod-v1">
                                                
                                                <p className="top-value">{sub.recurring == 0 ? "Subscription Cancelled" : 
                                                    <>
                                                    <button className="accept-reject" onClick={() => setConfirmationModal({
                                                        show:true,
                                                        data:{id:sub.id},
                                                        acceptFx:cancelUserSubscription,
                                                        message:`Are you sure, you want to cancel ?`,
                                                        rejectFx:()=>{setConfirmationModal({show:false, data: null, acceptFx: null, rejectFx: null, message:null})}
                                                    }) 
                                                    }>Cancel Subscription</button>
                                                    </>
                                                    }
                                                </p>
                                                <p className="top-value">{sub?.reason ? <>
                                                    <button onClick={(e) => showNote(sub?.reason)} className="note-modal">
                                                        <img src="/assets/image/note-icon.png" alt="admin-logo-out"/>
                                                    </button>
                                                </> : ""}</p>
                                                </div>
                                            </div>
                                        </div>) :
                                        <div className="no-data">
                                            No subscriptions found.
                                        </div>
                                }


                            </div>
                        </div>
                    </div>
                    {
                        subscription && subscription.length ?
                            <div className="books-pagination">
                                <MakePagination totalRecords={totalSubscriptions} limiter={10} selectFilter={selectFilter}></MakePagination>
                            </div> : null
                    }
                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
            <ConfirmationModal confirmationModal={confirmationModal}/>
            <NoteModal noteModal={noteModal} />
        </div>
    )
}

export default ListSubscription