import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { CButton, Loading } from '../../Components';
import { Form, FormGroup } from 'react-bootstrap';
import AdminNavbar from '../Navbar/AdminNavbar';
import { toast } from 'react-toastify';
import { validateAddPlanForm } from './Validators';
import { AdminCreatePlan, getAdminSinglePlan } from '../Services/Plans';
import { useNavigate, useParams } from 'react-router-dom';
import { urlPrefix } from '../../Helpers/Common';

const EditPlan = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [inputObj, setInputObj] = useState({ "0": true });
    const navigate = useNavigate();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            plan_details_0: ""
        },
        validate: validateAddPlanForm,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true)
            const { name, ...plan_details } = values;
            let result = await AdminCreatePlan({ id, name, plan_details });
            if (result?.success) {
                toast.success(result.message)
                navigate( urlPrefix() + "/admin/plans")
            } else {
                toast.error(result?.error?.message)
            }
            setLoading(false)
        }
    });

    const errorElement = (msg) => {
        return <div className='small text-danger'>{msg}</div>
    }

    const manipulateInput = (key, check) => {
        if (check === "add") {
            setInputObj({ ...inputObj, [`${key}`]: true })
            formik.setFieldValue(`plan_details_${key}`, "")
        }
        else if (check === "remove") {
            if (Object.getOwnPropertyNames(inputObj).length > 1) {
                delete inputObj[`${key}`]
                delete formik.values[`plan_details_${key}`]
                setInputObj({ ...inputObj })
            }
        }
    }

    const getInitialPageData = async() => {
        setLoading(true);
        let result = await getAdminSinglePlan({ plan_id: id });
        setLoading(false)
        if (result?.success) {
            let value = {}
            let inputObj = {}
            value["name"] = result.data.plan.name
            result.data.plan_details.map((plan,index) => {value[`plan_details_${index}`] = plan.description ; inputObj[`${index}`] =  true} )
            formik.setValues(value)
            setInputObj(inputObj)
        } else {
            navigate( urlPrefix() + "/admin/plans")
        }
    }

    useEffect(()=>{
        getInitialPageData()
    },[])

    return (
        <div className="admin-panel-main">
            <div className="panel-row">
                <div className="left">
                    <AdminNavbar></AdminNavbar>
                </div>
                <div className="right">

                    <div className="add-book">
                        <div className="panel-title-top">
                            <div className="top-left">
                            <button className='top-back-12' onClick={()=>navigate( urlPrefix() + "/admin/plans")}>Back</button>
                                <h3 className="heading-h3">Edit Plan</h3>
                            </div>
                            <div className="top-right">
                                {/* <CButton label="Add Book" custClassName="login-button t-btn"></CButton> */}
                            </div>
                        </div>
                    </div>

                    <div className="add-book-form">
                        <Form className="book-panel-form" onSubmit={formik.handleSubmit}>
                            <div className="pan-form-row">
                                <div className="cost-form-input">
                                    <FormGroup className="cost-form-group">
                                        <Form.Label>Plan Name:</Form.Label>
                                        <input
                                            id="name"
                                            name="name"
                                            type="name"
                                            onChange={formik.handleChange}
                                            value={formik.values.name}
                                            placeholder="Enter plan name"
                                            className="form-control"
                                            autoComplete="new-password"
                                        />
                                        {formik.errors.name ? errorElement(formik.errors.name) : null}
                                    </FormGroup>
                                </div>

                                {
                                    Object.getOwnPropertyNames(inputObj).length > 0 ?
                                        Object.entries(inputObj).map(([key, value], index, array) => {
                                            return <div className="cost-form-input">
                                                <FormGroup className="cost-form-group">
                                                    <Form.Label>Plan Detail:</Form.Label>
                                                    <input
                                                        id={`plan_details_${key}`}
                                                        name={`plan_details_${key}`}
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        value={formik.values[`plan_details_${key}`]}
                                                        placeholder="Enter plan detail"
                                                        className="form-control"
                                                        autoComplete="new-password"
                                                    />
                                                    <div className="add-remove-input">
                                                    {
                                                        array.length > 1 ? <div className="remove" onClick={() => { manipulateInput(key, "remove") }}>Remove Detail</div> : null
                                                    }
                                                    {
                                                        index === array.length - 1 ? <div className="make" onClick={() => { manipulateInput(parseInt(key) + 1, "add") }}>Add More Detail</div> : null
                                                    }
                                                    </div>
                                                    {formik.errors[`plan_details_${key}`] ? errorElement(formik.errors[`plan_details_${key}`]) : null}
                                                </FormGroup>
                                            </div>
                                        }) : null
                                }
                            </div>
                            <div className="submit-cta">
                                <CButton label="Submit" type="submit" custClassName="login-button t-btn"></CButton>
                            </div>
                        </Form>
                        {/* <button onClick={() => setInputList([...inputList,1])}>Make input</button>
            <button onClick={() =>  {delete formik.values[`${inputList.length - 1}`];delete formik.errors[`${inputList.length - 1}`]  ;setInputList(prev => prev.slice(0, -1))}}>Remove input</button> */}
                    </div>

                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
        </div>
    )
}

export default EditPlan