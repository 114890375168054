import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Modules/Layout";
import Loading from "../../Modules/Components/Loading";
import { Navigate, useLocation } from "react-router-dom";
import { updatePath } from "../../Redux/Slices/ActivePathSlice";

export const PublicValidator = () => {
    const location = useLocation();
    const [check, setCheck] = useState("pending");
    const user = useSelector((state) => state.UserReducer.value);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user?.authenticated) {
            setCheck("passed")
        }
        else {
            setCheck("failed")
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updatePath(location.pathname))
    }, [location])// eslint-disable-line react-hooks/exhaustive-deps

    if (check === "pending") {
        return <Loading />
    }
    else if (check === "passed") {
        return location.pathname === "/" ? <Layout/> : <Navigate to="/"/>
    }
    else {
       return <Layout />
    }
};