import React, { useEffect, useState } from "react";
import { CButton, Loading } from "../Components";

import { Form, FormGroup, Accordion } from "react-bootstrap";
import { useFormik } from "formik";
import { validateContactUsForm } from "./Validators";
import { contactUs } from "../Services/Cms";
import { toast } from "react-toastify";
import { urlPrefix } from "../Helpers/Common";

const ContactUs = () => {

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            email: "",
            phone_number: "",
            message: ""
        },
        validate: validateContactUsForm,
        onSubmit: async (values) => {
            setLoading(true)
            let result = await contactUs(values);
            setLoading(false)
            result?.success ? toast.success(result.message) : toast.error(result.error.message)
        }
    });

    const errorElement = (msg) => {
        return <div className='small text-danger'>{msg}</div>
    }

    return (
        <React.Fragment>
            <div className="library-overview-hero contact-us">
                <div className="house_container">
                    <h5 className="heading-h5 overview-subtitle">Contact Us</h5>
                    <h1 className="heading-h1 overview-title">seeking literary answers?</h1>
                    <h5 className="heading-h5 overview-detail">Ask away!</h5>
                </div>
            </div>

            <div className="contact-us-main">
                <div className="contact-row">
                    <div className="left">
                        <div className="inquiries">
                            <p>For general inquiries, library-related questions, or assistance with your account, drop us a line at: </p>
                        </div>
                        <div className="address">
                            <a href="tel:+971 52 282 6362" className="tel cta"><span><img src={urlPrefix() + "/assets/image/icon-feather-smartphone.png"} alt="heart icon"></img></span>+971 52 282 6362</a>
                            <a href="mailto:customerservice@houseofbooks.ae" className="main cta"><span><img src={urlPrefix() + "/assets/image/Icon-feather-mail.png"} alt="heart icon"></img></span>customerservice@houseofbooks.ae</a>
                            <a href="tel:map" className="location cta"><span><img src={urlPrefix() + "/assets/image/icon-feather-map-pin.png"} alt="heart icon"></img></span>Dubai, UAE</a>
                        </div>
                    </div>
                    <div className="right">
                        <div className="in-touch">
                            <h3 className="heading-h3 sub-title">or Get in touch</h3>
                            <h2 className="heading-h2 title">and fill in the form below</h2>

                            <div className="contact-form">
                                <Form id="loginForm" className="login-form-main" onSubmit={formik.handleSubmit}>
                                    <div className="sign-form-col">

                                        <div className="cost-form-input w-55 tb-m">
                                            <FormGroup className="cost-form-group">
                                                <Form.Label>Hi, my name is</Form.Label>
                                                <div className="inp-msg">
                                                    <input
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.name}
                                                        placeholder="Type Here"
                                                        className="form-controla"
                                                        autoComplete="new-password"
                                                    />
                                                    {formik.errors.name ? errorElement(formik.errors.name) : null}
                                                </div>
                                            </FormGroup>
                                        </div>

                                        <div className="cost-form-input w-45 no-grid">
                                            <FormGroup className="cost-form-group">
                                                <Form.Label className="col-sp">you can send me an email to</Form.Label>
                                            </FormGroup>
                                        </div>

                                        <div className="cost-form-input w-45 no-grid tb-m">
                                            <FormGroup className="cost-form-group">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email}
                                                    placeholder="Your Email Address"
                                                    className="form-controla col-sp"
                                                    autoComplete="new-password"
                                                />
                                                {formik.errors.email ? errorElement(formik.errors.email) : null}
                                            </FormGroup>
                                        </div>

                                        <div className="cost-form-input w-55 tb-m">
                                            <FormGroup className="cost-form-group">
                                                <Form.Label>or call me at</Form.Label>
                                                <div className="inp-msg">
                                                    <input
                                                        id="phone_number"
                                                        name="phone_number"
                                                        type="tel"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.phone_number}
                                                        placeholder="Your Phone Number"
                                                        className="form-controla"
                                                        autoComplete="new-password"
                                                    />
                                                    {formik.errors.phone_number ? errorElement(formik.errors.phone_number) : null}    
                                                </div>                     
                                                </FormGroup>
                                        </div>

                                        <div className="cost-form-input comments w-100 tb-m">
                                            <FormGroup className="cost-form-group">
                                                <Form.Label>I have some questions / comments:</Form.Label>
                                                <textarea
                                                    id="message"
                                                    name="message"
                                                    type="textarea"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.message}
                                                    placeholder="Type Here"
                                                    className="form-controla"
                                                    autoComplete="new-password"
                                                    rows="1"
                                                />
                                                {formik.errors.message ? errorElement(formik.errors.message) : null}
                                            </FormGroup>
                                        </div>

                                    </div>

                                    <div className="submit-cta">
                                        <CButton label="Send Enquiry" custClassName="login-button t-btn"></CButton>
                                    </div>

                                </Form>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
        </React.Fragment>
    );
};
export default ContactUs;