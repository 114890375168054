import React, { useEffect, useState } from 'react'
import { CButton, Loading } from '../../Components';
import { useNavigate } from 'react-router-dom';
import AdminNavbar from '../Navbar/AdminNavbar';
import { Button, Form, InputGroup } from 'react-bootstrap';
import MakePagination from '../../Components/MakePagination';
import { urlPrefix } from '../../Helpers/Common';
import { AdminDeleteFaq, AdminUpdateFaqStatus, getAdminFaqs } from '../Services/Cms';
import ConfirmationModal from '../Books/Components/ConfirmationModal';
import { toast } from 'react-toastify';
import { useDebounce } from '../../../CustomHooks/DebounceHook';

const ListFaqs = () => {
    const [confirmationModal, setConfirmationModal] = useState({ show: false, data: null, acceptFx: null, rejectFx: null,message:null });
    const navigate = useNavigate()
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [faqs, setFaqs] = useState([]);
    const [totalFaqs, setTotalFaqs] = useState(0);
    const [pagination, setPagination] = useState({ limit: 10, start_from: 0, search: null, order_by: [{ column: 'faqs.id', order: 'desc' }] });
    const debouncedSearch = useDebounce(search,500);

    const selectFilter = (filter, data) => {
        if (filter === "order") {
            setPagination({ ...pagination, order_by: [data] })
        }
        else if (filter === "pagination") {
            setPagination({ ...pagination, start_from: data })
        }
        else if (filter === "search") {
            setPagination({ ...pagination, start_from: 0, search: data && data.length ? data.trim() : null })
        }
    }

    const deleteFaq = async (data) => {
        const { id } = data
        setLoading(true)
        const result = await AdminDeleteFaq({ id });
        if (result.success) {
            getInitialPageData()
            toast.success(result.message)
        }
        else {
            toast.error(result.error.message)
        }
        setConfirmationModal({ show: false, data: null, acceptFx: null, rejectFx: null,message:null })
        setLoading(false)
    }

    const faqStatusUpdate = async (data) => {
        const { id, status } = data
        setLoading(true)
        const result = await AdminUpdateFaqStatus({ id, status:!status });
        if (result.success) {
            getInitialPageData()
            toast.success(result.message)
        }
        else {
            toast.error(result.error.message)
        }
        setConfirmationModal({ show: false, data: null, acceptFx: null, rejectFx: null,message:null })
        setLoading(false)
    }


    const getInitialPageData = async () => {
        setLoading(true)
        const result = await getAdminFaqs({ ...pagination, total_count_of: "faqs.id" });
        setFaqs(result.data.faqs)
        setTotalFaqs(result.data.totalFaqs)
        setLoading(false)
    }

    useEffect(() => {
        getInitialPageData()
    }, [pagination])

    useEffect(()=>{ 
        selectFilter("search",search)
    },[debouncedSearch])

    return (
        <div className="admin-panel-main">
            <div className="panel-row">
                <div className="left">
                    <AdminNavbar></AdminNavbar>
                </div>
                <div className="right">
                    <div className="panel-title-top all-book">
                        <div className="top-left">
                            <h3 className="heading-h3">Faqs list</h3>
                        </div>
                        <div className="top-right admin-filter">
                            <div className="right-side">
                                <CButton fxToRun={() => { navigate(urlPrefix() + "/admin/faqs/insert") }} label="Add FAQ" custClassName="login-button t-btn"></CButton>
                            </div>
                        </div>
                    </div>

                    <div className="dash-filter filter">
                        <div className="left-fil" />
                        <div className="search">
                            <InputGroup className="admin-search-input" onChange={(e) => setSearch(e.target.value)} >
                                <Form.Control placeholder="Search FAQ" value={search || ""} />
                            </InputGroup>
                        </div>
                    </div>

                    <div className="admin-panel-data">
                        <div className="top-title all-plans">
                            <div className="order-no">
                                <p className="top-t">Question</p>
                            </div>
                            <div className="title">
                                <p className="top-t">Answer</p>
                            </div>
                            <div className="title">
                                <p className="top-t">Status</p>
                            </div>
                            <div className="title">
                                <p className="top-t">Actions</p>
                            </div>
                        </div>
                        <div className="book-list">
                            {
                                faqs && faqs.length ?
                                    faqs.map((faq) => <div className="book-row all-plans" key={faq.id}>
                                        <div className="order-no-value">
                                            <p className="top-value">{faq.question}</p>
                                        </div>
                                        <div className="title-value">
                                            <p className="top-value" dangerouslySetInnerHTML={{__html: faq.answer}}></p>
                                        </div>
                                        <div className="e-status-value">
                                            <button onClick={() => setConfirmationModal({
                                                show: true,
                                                data: { id: faq.id, status:faq.status },
                                                acceptFx: faqStatusUpdate,
                                                message:`Are you sure, You want to ${faq.status ? "Inactive":"Active"} FAQ ?`,
                                                rejectFx: () => { setConfirmationModal({ show: false, data: null, acceptFx: null, rejectFx: null ,message:null}) }
                                            })} className={faq.status ? "toggle-on" : "toggle-off"}>
                                            </button>
                                            <div className="d-none">
                                                {
                                                    faq.status ? <div className="on">on</div> : <div className="off">of</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="e-edit-delete-value">
                                            <a className="edit-action" onClick={() => navigate(urlPrefix() + `/admin/faqs/edit/${faq.id}`)}><img src={urlPrefix() + "/assets/image/edit-green.png"} alt='book-image' /></a>
                                            <a className="delete-action" onClick={() => setConfirmationModal({
                                                show: true,
                                                data: { id: faq.id},
                                                message:`Are you sure you want to delete FAQ ?`,
                                                acceptFx: deleteFaq,
                                                rejectFx: () => { setConfirmationModal({ show: false, data: null, acceptFx: null, rejectFx: null,message:null }) }
                                            })} ><img src={urlPrefix() + "/assets/image/trash.svg"} alt='book-image' /></a>
                                        </div>
                                    </div>) :
                                    <div className='no-data'>
                                        No Faqs found.
                                    </div>
                            }

                            {
                                faqs && faqs.length ?
                                    <div className="books-pagination">
                                        <MakePagination totalRecords={totalFaqs} limiter={10} selectFilter={selectFilter}></MakePagination>
                                    </div> : null
                            }

                        </div>
                    </div>
                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
            <ConfirmationModal confirmationModal={confirmationModal} />
        </div>
    )
}

export default ListFaqs
