import React from "react";
import {CButton} from "../Components";
import { Navigate, useNavigate } from "react-router-dom";
import { urlPrefix } from "../Helpers/Common";

const PortalFooter = () => {
  
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <footer className="house-footer">
        <div className="house_container">

          {/* top footer section */}
          <div className="footer-top">
            <div className="address">
              <div className="f-title">
                <h5 className="heading-h5">Address</h5>
              </div>
              <div className="f-detail">
                <p>Dubai, UAE</p>
                {/* <p><a href="tel:+971 55 394 6745">+971 55 394 6745</a></p> */}
              </div>
            </div>
            <div className="contact">
              <div className="f-title">
                <h5 className="heading-h5">Contact</h5>
              </div>
              <div className="f-detail">
              <p className="pb-v1"><a href="tel:+971 52 282 6362">+971 52 282 6362</a></p>
              <CButton redirectTo="contact-us" label="Send a message " custClassName="cost-btn t-btn"></CButton>
              </div>
            </div>
            <div className="business-hours">
              <div className="f-title">
                <h5 className="heading-h5">Business Hours</h5>
              </div>
              <div className="f-detail">
                <p>09:00 - 19:00</p>
              </div>
            </div>
            <div className="in-short">
              <div className="f-title">
                <h5 className="heading-h5">In Short</h5>
              </div>
              <div className="f-detail">
                <ul className="our-story">
                  <li><a onClick={()=>navigate(urlPrefix() + "/")}>Home</a></li>
                  <li><a onClick={()=>navigate(urlPrefix() + "/our-story")}>Our Story</a></li>
                  <li><a onClick={()=>navigate(urlPrefix() + "/library-overview")}>Library</a></li>
                  <li><a onClick={()=>navigate(urlPrefix() + "/join-us")}>Join House of Books</a></li>
                  <li><a onClick={()=>navigate(urlPrefix() + "/contact-us")}>Contact Us</a></li>
                </ul>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <div className="f-left">
                <div className="reserved">
                  <p>© 2024 House of Books. All Rights Reserved.</p>
                  <p>Made with <span className="icon"><img src={urlPrefix() + "/assets/image/Icon-awesome-heart.png"} alt="heart icon"></img></span> by <a href="https://studioubique.com" target="_blank">Studio Ubique</a></p>
                </div>
            </div>
            <div className="f-right">
                <div className="f-social">
                  <ul className="social-links">
                    <li className="insta-ico">
                        <a href="https://www.instagram.com/houseofbooks_ae/" target="_blank" title="instagram"></a>
                    </li>
                    <li className="facebook-ico">
                        <a href="https://www.facebook.com/HOB.library" target="_blank" title="facebook"></a>
                    </li>
                    <li className="linkedin-ico">
                        <a href="#" title="linkedin"></a>
                    </li>
                    <li className="x-logo-ico ">
                        <a href="https://twitter.com/houseofbooks_ae" target="_blank" title="twitter"></a>
                    </li>
                  </ul>
                </div>
                <div className="f-pvc">
                  <ul className="privacy-policy">
                    <li><a onClick={()=>navigate(urlPrefix() + "/privacy-policy")}>Privacy Policy</a></li>
                    <li><a onClick={()=>navigate(urlPrefix() + "/faq")}>FAQs</a></li>
                    <li><a onClick={()=>navigate(urlPrefix() + "/term")}>Terms and Conditions</a></li>
                  </ul>
                </div>
            </div>
          </div>

        </div>
      </footer>
    </React.Fragment>
  );
};
export default PortalFooter;
