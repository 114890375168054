export const validateLoginForm = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'This field is required';
  }

  if (!values.password) {
    errors.password = 'This field is required';
  }

  return errors;
};

export const validateRegisterForm = values => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = 'This field is required';
  } else if (values.first_name.length > 15) {
    errors.first_name = 'Must be 15 characters or less';
  } else if (values.first_name.length < 3) {
    errors.first_name = 'Must be 3 characters or more';
  } else if (/\d/.test(values.first_name) ) {
    errors.first_name = 'Must not contain numbers.';
  }

  if (!values.email) {
    errors.email = 'This field is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'This field is required';
  } else if (values.password.length < 8) {
    errors.password = 'Must be 8 characters or more';
  } else if (values.password.length > 21) {
    errors.password = 'Must be at most 20 characters';
  } else if (!/[a-z]/.test(values.password)) {
    errors.password = 'Must contain at least one lowercase letter';
  } else if (!/[A-Z]/.test(values.password)) {
    errors.password = 'Must contain at least one uppercase letter';
  } else if (!/\d/.test(values.password)) {
    errors.password = 'Must contain at least one digit';
  } else if (!/\W/.test(values.password)) {
    errors.password = 'Must contain at least one special character';
  }

  return errors;
};

export const validateForgetPasswordForm = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'This field is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};


export const validateResetPasswordForm = values => {
  const errors = {};

  if (!values.newPassword) {
    errors.newPassword = 'This field is required';
  } else if (values.newPassword.length < 8) {
    errors.newPassword = 'Must be 8 characters or more';
  } else if (values.newPassword.length > 21) {
    errors.newPassword = 'Must be at most 20 characters';
  } else if (!/[a-z]/.test(values.newPassword)) {
    errors.newPassword = 'Must contain at least one lowercase letter';
  } else if (!/[A-Z]/.test(values.newPassword)) {
    errors.newPassword = 'Must contain at least one uppercase letter';
  } else if (!/\d/.test(values.newPassword)) {
    errors.newPassword = 'Must contain at least one digit';
  } else if (!/\W/.test(values.newPassword)) {
    errors.newPassword = 'Must contain at least one special character';
  }

  if(!values.confirmPassword){
    errors.confirmPassword = 'This field is required';
  }else if (values.newPassword && values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = 'Invalid confirm password';
  }

  return errors;
};

export const validateResendEmailVerificationForm = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'This field is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};