import React from "react";
const SlideMoreInfo = (props) => {
  const {label, custClassName,fxToRun,data} = props;
      return (
      <React.Fragment>
        <button className={custClassName} onClick={()=> fxToRun ? fxToRun(data) : null}>
        <span  className="info-icon">&nbsp;</span> {label}
        </button>              
      </React.Fragment>
    );
  };
  export default SlideMoreInfo;

// import SlideMoreInfo from "../Components/elements/SlideHover";

// <SlideMoreInfo label="test" custClassName="this and"></SlideMoreInfo>