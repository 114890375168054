import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { CButton, Loading } from '../Components';
import { urlPrefix } from '../Helpers/Common';
import { Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { placeOrderToReturn } from '../Services/Order';

const ReturnNow = ({ borrowedBooks, updateBorrowedBooks, address ,nextSevenDays, timeSlots}) => {
    const [tabKey, setTabKey] = useState('LiteraryKey');
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.UserReducer.value);
    const [booksToReturn, setBooksToReturn] = useState({});
    const [scheduled_return_date,set_scheduled_return_date] = useState("");
    const [return_time_slot,set_return_time_slot] = useState("");

    const handleBookToReturn = (book) => {
        if (booksToReturn[book.book_id]) {
            setBooksToReturn(prev => { const { [book.book_id]: _, ...updatedBooksToOrder } = prev; return updatedBooksToOrder; })
        } else {
            setBooksToReturn({ [book.book_id]: { title: book.title }, ...booksToReturn })
        }
    }

    const handleReturnOrderPlacement = async () => {
        if (!booksToReturn || !Object.getOwnPropertyNames(booksToReturn).length) {
            toast.error("Please select books first.")
            return
        }
        let books = []
        Object.entries(booksToReturn).map(([key, value]) => { books.push({ id: key }) })
        setLoading(true)
        let result = await placeOrderToReturn({ books,scheduled_return_date,return_time_slot });
        if (result.success) {
            updateBorrowedBooks()
            setBooksToReturn({})
            toast.success(result.message)
        }
        else {
            toast.error(result.error.message)
        }
        setLoading(false)
    }

    useEffect(()=>{
        if(nextSevenDays.length){
            set_scheduled_return_date(nextSevenDays[0].split(" ")[0])
        }
        if(timeSlots.length){
            set_return_time_slot(timeSlots[0].time_slot)
        }
    },[timeSlots,nextSevenDays])

    return (
        <div className="literary-cart">
            <div className="literary">
                <div class="top">
                    <h3 className="top-heading">Cart</h3>
                </div>
                <div className="literary-row">

                    {
                        borrowedBooks && borrowedBooks.length ?
                            borrowedBooks.map((book,index) => <div key={index} class="l-row">
                                <div className="icon">
                                    {
                                        book.borrowing_status !== "scheduled" ?
                                            <Form className="cost-form">
                                                <Form className="cost-form">
                                                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                        <Form.Check id={`book_${book.book_id}`} name={`book_${book.book_id}`} checked={booksToReturn[book.book_id] ? true : false} type="checkbox" label=" " onClick={() => handleBookToReturn(book)} />
                                                    </Form.Group>
                                                </Form>
                                            </Form> : null
                                    }
                                </div>
                                <div className="ico-card">
                                    <div className="order-book-card">
                                        <div className="book">
                                            <img src={book.image_url} alt="book imag"></img>
                                        </div>
                                        <div className="detail">
                                            <div className='top-row'>
                                                <div className="order-n">
                                                    <p className="o-title">Order nr.:</p>
                                                    <p className="o-sub-title">#{book.borrowing_order_id}</p>
                                                </div>
                                                <div className="order-s-date">
                                                    <p className="o-title">Order Start Date:</p>
                                                    <p className="o-sub-title">{book.borrowed_date}</p>
                                                </div>
                                                <div className="order-exp-dsate">
                                                    <p className="o-title">Expiration Date:</p>
                                                    <p className="o-sub-title">{book.due_date}</p>
                                                </div>
                                            </div>
                                            <div className="book-name">
                                                <h3 className="heading-h3 title">{book.title}</h3>
                                            </div>
                                            <div className="book-detail">
                                                <p className="s-title">By {book?.authors?.map((author) => author).join(" ,")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='schedule-return'>
                                    {
                                        book.borrowing_status === "scheduled" ?
                                            `Scheduled to Return ${book.scheduled_return_date ? ` (${book.scheduled_return_date})` : " (pending)"}` :
                                            ""
                                    }
                                    </div>
                                </div>
                            </div>) :

                            <div className="no-data">
                                Currently no books borrowed.
                            </div>
                    }

                </div>

            </div>

            <div className="delivery">
                <div className="place-order-card">
                    <h3 className="heading-h3 card-heading">Pick up Details</h3>
                    <div className="address">
                        <div className="left">
                            <p className="l-side">Name</p>
                        </div>
                        <div className="right">
                            <p className="r-side">{user.first_name} {user.last_name}</p>
                        </div>
                        <div className="left">
                            <p className="l-side">Address:</p>
                        </div>
                        <div className="right">
                            <p className="r-side">
                                {address ? <span>{address.city}, {address.state}</span> : <span>Address Line 1</span>}
                            </p>
                        </div>
                    </div>
                    <div className="cust-delivery-time">
                        <div className="title">
                            <p>Return Between</p>
                        </div>
                        <div className="selct-col">
                            <select onChange={(e) => set_scheduled_return_date(e.target.value)}>
                                {nextSevenDays.map((i, index) => <option key={index} value={i.split(' ')[0]} >{i}</option>)}
                            </select>
                            <select onChange={(e) => set_return_time_slot(e.target.value)}>
                                {timeSlots.map((i, index) => <option key={index} value={i.time_slot}>{i.slug}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="book-return">
                        <h5 className="heading-h5">Book to return</h5>
                        {
                            booksToReturn && Object.values(booksToReturn).length ?
                                Object.values(booksToReturn).map((book,index) => <p key={index} className="return-detail">{book.title}</p>) :
                                <p className="return-detail">None</p>
                        }
                    </div>

                    <div className="place-order">
                        <CButton preventDefault={true} fxToRun={() => { handleReturnOrderPlacement() }} label="Return" custClassName="login-button t-btn"></CButton>
                    </div>
                </div>

            </div>

            <div className="literary-cart mobile">
                <div className="mobile-tab">
                    <Tabs activeKey={tabKey} onSelect={(k) => setTabKey(k)} defaultActiveKey="LiteraryKey" id="LiteraryCart" className="cost-order-tab" fill >
                        <Tab eventKey="LiteraryKey" title="1. Literary Cart">
                            <div className="literary">
                                <div className="literary-row">
                                    {
                                        borrowedBooks && borrowedBooks.length ?
                                            borrowedBooks.map((book,index) => <div key={index} class="l-row">
                                                <div className="icon">
                                                    {
                                                        book.borrowing_status !== "scheduled" ?
                                                            <Form className="cost-form">
                                                                <Form className="cost-form">
                                                                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                                        <Form.Check id={`book_${book.book_id}`} name={`book_${book.book_id}`} checked={booksToReturn[book.book_id] ? true : false} type="checkbox" label=" " onClick={() => handleBookToReturn(book)} />
                                                                    </Form.Group>
                                                                </Form>
                                                            </Form> : null
                                                    }
                                                </div>

                                                <div className="ico-card">
                                                    <div className="order-book-card">
                                                        <div className="book">
                                                        <img src={book.image_url} alt="book imag"></img>
                                                        </div>
                                                        <div className="detail">
                                                            <div className='top-row'>
                                                                <div className="order-n">
                                                                    <p className="o-title">Order nr.:</p>
                                                                    <p className="o-sub-title">#{book.borrowing_order_id}</p>
                                                                </div>
                                                                <div className="order-s-date">
                                                                    <p className="o-title">Order Start Date:</p>
                                                                    <p className="o-sub-title">{book.borrowed_date}</p>
                                                                </div>
                                                                <div className="order-exp-date">
                                                                    <p className="o-title">Expiration Date:</p>
                                                                    <p className="o-sub-title">{book.due_date}</p>
                                                                </div>
                                                            </div>
                                                            <div className="book-name">
                                                                <h3 className="heading-h3 title">{book.title}</h3>
                                                            </div>
                                                            <div className="book-detail">
                                                                <p className="s-title">By {book?.authors?.map((author) => author).join(" ,")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='schedule-return-mob'>
                                                {
                                                    book.borrowing_status === "scheduled" ?
                                                        `Scheduled to Return ${book?.scheduled_return_date ? ` (${book.scheduled_return_date})` : " (pending)"}` :
                                                        ""
                                                }
                                                </div>
                                            </div>) : <div className="no-data">
                                                Currently no books borrowed.
                                            </div>
                                    }
                                </div>

                                <div className="proceed-details">
                                    <CButton fxToRun={() => Object.values(booksToReturn).length ? setTabKey("DeliveryDetails") : toast.error("Please choose a book first.")} label="Proceed to Pick up Details" custClassName="login-button t-btn"></CButton>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="DeliveryDetails" title="2. Delivery Details">
                            <div className="delivery">
                                <div className="place-order-card">
                                    {/* <h3 className="heading-h3 card-heading">Delivery Details</h3> */}
                                    <div className="address">
                                        <div className="left">
                                            <p className="l-side">Name</p>
                                        </div>
                                        <div className="right">
                                            <p className="r-side">{user.first_name} {user.last_name}</p>
                                        </div>
                                        <div className="left">
                                            <p className="l-side">Address:</p>
                                        </div>
                                        <div className="right">
                                            <p className="r-side">
                                            {address ? <span>{address.city}, {address.state}</span> : <span>Address Line 1</span>}
                                            </p>
                                        </div>
                                        <div className="cust-delivery-time">
                                            <div className="title">
                                                <p>Return Between</p>
                                            </div>
                                            <div className="selct-col">
                                                <select onChange={(e) => set_scheduled_return_date(e.target.value)}>
                                                    {nextSevenDays.map((i, index) => <option key={index} value={i.split(' ')[0]} >{i}</option>)}
                                                </select>
                                                <select onChange={(e) => set_return_time_slot(e.target.value)}>
                                                    {timeSlots.map((i, index) => <option key={index} value={i.time_slot}>{i.slug}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="book-ordered">
                                        <h5 className="heading-h5">Book to return</h5>
                                        <ul className="ordered">
                                        {
                                            booksToReturn && Object.values(booksToReturn).length ?
                                                Object.values(booksToReturn).map((book,index) => <li key={index} className="return-detail">{book.title}</li>) :
                                                <li className="return-detail">None</li>
                                        }
                                        </ul>
                                    </div>
                                    <div className="place-order">
                                        <CButton preventDefault={true} fxToRun={() => { handleReturnOrderPlacement() }} label="Return" custClassName="login-button t-btn"></CButton>
                                    </div>
                                    <div className="previous-step">
                                        <button onClick={()=>setTabKey("LiteraryKey")} type="submit" class="cost-btn t-btn"><span>&nbsp;</span> Previous step </button>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
        </div>
    )
}

export default ReturnNow;