// Define Constants in CamelCases For Better Access. 

export const roleType = {
    Admin: 1,
    BookKeeper: 2,
    User: 3,
    Other: 4
}

export const roleName = {
    1 : "Admin",
    2 : "BookKeeper",
    3 :  "User",
    4 : "Other"
}

export const userTypes = [
    { name: 'Admin', value: '1'},
    { name: 'Book Keeper', value: '2' },
    { name: 'User', value: '3' },
    { name: 'Other', value: '4' },
];

export const planTypes =[
    {plan_type:"annually",duration_days:365},
    {plan_type:"half_yearly",duration_days:182},
    {plan_type:"quarterly",duration_days:90},
    {plan_type:"monthly",duration_days:30},
    // {plan_type:"weekly",duration:7},
    // {plan_type:"daily",duration:1},
]

export const CurrencyTypes = {
 aed : "AED"   
}

export const categoryIds = {
    fiction:1,
    fantasy:2,
    featured:14,
    in_demand:21
}

export const refundStatus = {
    1:"pending",
    2:"initiated",
    3:"completed",
    4:"cancelled"
}

