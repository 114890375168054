import React, { useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { getBooks, insertBookInReadingList } from '../Services/Books';
import { categoryIds } from "../../Config/Constant"
import { useDispatch, useSelector } from "react-redux";
import SlideMoreInfo from './SlideHover';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { addToCart } from '../Services/Cart';
import { urlPrefix } from '../Helpers/Common';
import { increaseCartQuantity } from '../../Redux/Slices/UserSlice';
import Loading from './Loading';
import { LoginPopUp } from '.';

const NonFictionCarousel = () => {
  const [loading, setLoading] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer.value);
  const [books, setBooks] = useState([]);
  const [splideType, setSplideType] = useState("loop");
  const splideOptions = {
    wheel: false,
    loop: true,
    start: 0,
    perPage: 3.5,
    perMove: 1,
    snap: true,
    interval: 5000,
    speed: 3000,  
    dragSpeed: 10000,
    autoplay: true,
    arrows: true,
    pagination: true,
    rewind: true,
    rewindByDrag: true,
    lazyLoad: true,
    gap: '50px',
    drag: true,
    type: splideType,
    // padding: '4rem',
    breakpoints: {
      1440: {
        gap: '40px',
      },
      1280: {
        perPage: 3,
        gap: '30px',
      },
      1024: {
        perPage: 3,
        gap: '25px',
      },
      768: {
        perPage: 2,
        gap: '10px',
        snap: true
      },
      430: {
        perPage: 1.5, 
        gap: '0px',
        snap: true,
        interval: 5000,
        speed: 5000,
        dragSpeed  : 5000,
        pauseOnFocus:  true,
        autoplay: false,
      },

      414: {
        perPage: 1.5, 
        gap: '0px',
        snap: true,
        interval: 5000,
        speed: 5000,
        dragSpeed  : 5000,
        pauseOnFocus:  true,
        autoplay: false,
      },

      375: {
        perPage: 1.5, 
        gap: '0px',
        snap: true,
        interval: 5000,
        speed: 5000,
        dragSpeed  : 5000,
        pauseOnFocus:  true,
        autoplay: false,
      }
    },
    pauseOnHover: true, // Pause the carousel on hover
  }

  const addToReadingList = async (data) => {
    const { reading_list_id, book_id } = data;
    if (!user.authenticated) {
      navigate(urlPrefix() + "/login")
    }
    else if (reading_list_id) {
      navigate(urlPrefix() + "/subscription-account?key=Reading List");
      return
    }
    else {
      setLoading(true)
      let result = await insertBookInReadingList({ book_id });
      if (result?.success) {
        toast.success(result.message)
        getInitialPageData()
      } else {
        toast.error(result.error.message)
      }
      setLoading(false)
    }
  }

  const addToUsersCart = async (data) => {
    const { book_id, cart_id, order_id, order_status, returned_date, borrowing_id } = data;

    if (!user.authenticated) {
      navigate(urlPrefix() + "/login")
    }
    else if (cart_id) {
      navigate(urlPrefix() + "/order?key=OrderNow");
    }
    else if (order_id && ["pending", "approved"].includes(order_status)) {
      navigate(urlPrefix() + "/subscription-account?key=Order History");
    }
    else if (borrowing_id && !returned_date) {
      navigate(urlPrefix() + "/order?key=ReturnNow");
    }
    else {
      setLoading(true)
      const result = await addToCart({ book_id, cart_id: user.cart_id, quantity: 1 });
      if (result?.success) {
        dispatch(increaseCartQuantity())
        toast.success(result.message)
        getInitialPageData()
      } else {
        toast.error(result.error.message)
      }
      setLoading(false)
    }
  }

  const bookLabel = (data) => {
    const { cart_id, order_id, order_status, returned_date, borrowing_id } = data
    if (borrowing_id && !returned_date) {
      return "Borrowed"
    }
    else if (order_id && ["pending", "approved"].includes(order_status)) {
      return "Ordered"
    }
    else if (cart_id) {
      return "Go to Cart"
    }
    else {
      return "Add to Cart"
    }
  }

  const getInitialPageData = async () => {
    setLoading(true)
    const result = await getBooks({ include_categories:true, filters: { categories: [categoryIds.fantasy] }, limit: null, start_from: null, search: null, order_by: [{ column: 'books.id', order: 'desc' }] });
    if(result?.success) {
      setSplideType(result?.data?.length > 3 ? "loop" : "slide")
      setBooks(result.data)
    } else {
      setBooks([])
    }
    setLoading(false)
  }

  useEffect(() => {
    getInitialPageData()
  }, [user.cart_quantity])

  return (
    <>
      {
        books.length ?
          <Splide
            hasTrack={true}
            aria-label="..."
            options={splideOptions}
          >
            {
              books.map((item,index) =>
                <SplideSlide key={index}>
                  <div className="fiction-slide-card">
                    <div className="card-image img-box">
                      <img src={item.image_url} alt="book imag"></img>
                    </div>

                    <div className="hero-hover">
                      <div className="hover-content">
                        <SlideMoreInfo fxToRun={() => { navigate(urlPrefix() + `/library-detail/${item.slug}`) }} label="More info" custClassName="info more-info"></SlideMoreInfo>
                        <SlideMoreInfo label={item.reading_list_id ? "Reading List" : "Add to List"} fxToRun={addToReadingList} data={item} custClassName="info reading"></SlideMoreInfo>
                        <SlideMoreInfo label={bookLabel(item)} fxToRun={addToUsersCart} data={item} custClassName="info cart"></SlideMoreInfo>
                      </div>
                    </div>
                    <div className="detail">
                      <p className="category">{item?.categories?.filter((category, index)=> index < 3).join(", ")}</p>
                      <h3 className="heading-h3 book-name" onClick={()=> navigate(urlPrefix() + `/library-detail/${item.slug}`)}>{item.title}</h3>
                      <p className="more-info"><div dangerouslySetInnerHTML={{ __html: item.synopsis }} /></p>
                    </div>
                  </div>
                </SplideSlide>)
            }

          </Splide> :
          <div className="no-data">
            No Books found.
          </div>
      }
      {
        loading ? <Loading /> : null
      }
      <LoginPopUp showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal}/>
    </>
  );
};

export default NonFictionCarousel;