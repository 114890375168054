import React, { useState } from "react";
import { CButton, Loading } from "../Components";

import { Form, FormGroup } from "react-bootstrap";
import { validateForgetPasswordForm } from "./Validations";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { forgotPassword } from "../Services/Auth";

const ForgotPassword = () => {

  const [loading,setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validate: validateForgetPasswordForm,
    onSubmit: async (values) => {
      setLoading(true);
      let result = await forgotPassword(values);
      setLoading(false);
      result?.success ? toast.success(result.message) : toast.error(result.error.message)
    }
  });

  
  const errorElement = (msg) => {
    return <div className='small text-danger'>{msg}</div>
  }
  

  return (
    <React.Fragment>
      <div className="sign-in-form-wrap">
        <div className="login-main-wrap forgot">
          <h1 className="heading-h2 login-title">Forgot Password!</h1>
        </div>
        <div className="sign-form-main forgot526">
          <Form id="loginForm" className="login-form-main" onSubmit={formik.handleSubmit}>

            <div className="cost-form-input">
              <FormGroup className="cost-form-group">
                <Form.Label>Email</Form.Label>
                <div className="inp-msg">
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  placeholder="Type here"
                  className="form-controla"
                  autoComplete="new-password"
                />
                {formik.errors.email ? errorElement(formik.errors.email) : null}
                </div>
              </FormGroup>
            </div>

            <div className="submit-cta">
              <CButton label="Forgot Password!" custClassName="login-button t-btn w-75 m-auto"></CButton>
            </div>
          </Form>
        </div>
      </div>
      {
        loading ? <Loading /> : null
      }
    </React.Fragment>
  );
};
export default ForgotPassword;
