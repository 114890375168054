import React, {  useEffect, useState } from "react";
import {CButton} from "../Components";
import Dropdown from 'react-bootstrap/Dropdown';
import { urlPrefix } from "../Helpers/Common";
import {useNavigate} from "react-router-dom";


const Term = () => {
  const [selectedTerm,setSelectedTerm] = useState("Membership");
  const navigate = useNavigate()
  
  useEffect(()=> {
    // get id from URL
    const id = window.location.hash;
    if (id) {
      document.querySelector(`${id}`).scrollIntoView();
    }
  }, [])
  return (
    <React.Fragment>
      <div className="library-overview-hero terms">
        <div className="house_container">
            <h1 className="heading-h1 overview-subtitle">Terms and conditions</h1>
        </div>
      </div>

      <div className="t-and-c-main">
        <div className="left">
          <div className="left-main">
              <ul>
                <li><a href="#membership">Membership</a></li>
                <li><a href="#delivery">Delivery</a></li>
                <li><a href="#third-parties">Use of third parties, privacy and use of data</a></li>
                <li><a href="#membership-period">Membership Period</a></li>
                <li><a href="#m-payment">Payment</a></li>
                <li><a href="#m-pause">Membership Pause</a></li>
                <li><a href="#m-guarantee">Price Guarantee</a></li>
                <li><a href="#m-renewal">Membership Renewal</a></li>
                <li><a href="#cancellation">Refunds or Cancellation</a></li>
                <li><a href="#responsibility">Our responsibility to you</a></li>
                <li><a href="#m-late-fee">Late Fees</a></li>
                <li><a href="#m-damage">Loss or Damage</a></li>
              </ul>
          </div>

          <div className="term-mobile">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
               &nbsp; {selectedTerm}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={()=>setSelectedTerm("Membership")} href="#membership">1. &nbsp; Membership</Dropdown.Item>
              <Dropdown.Item onClick={()=>setSelectedTerm("Delivery")} href="#delivery">2. &nbsp; Delivery</Dropdown.Item>
              <Dropdown.Item onClick={()=>setSelectedTerm("Use of third parties, privacy and use of data")} href="#third-parties">3. &nbsp; Use of third parties, privacy and use of data</Dropdown.Item>
              <Dropdown.Item onClick={()=>setSelectedTerm("Membership Period")} href="#membership-period">4. &nbsp; Membership Period</Dropdown.Item>
              <Dropdown.Item onClick={()=>setSelectedTerm("Payment")} href="#m-payment">5. &nbsp; Payment</Dropdown.Item>
              <Dropdown.Item onClick={()=>setSelectedTerm("Membership Pause")} href="#m-pause">6. &nbsp; Membership Pause</Dropdown.Item>
              <Dropdown.Item onClick={()=>setSelectedTerm("Price Guarantee")} href="#m-guarantee">7. &nbsp; Price Guarantee</Dropdown.Item>
              <Dropdown.Item onClick={()=>setSelectedTerm("Membership Renewal")} href="#m-renewal">8. &nbsp; Membership Renewal</Dropdown.Item>
              <Dropdown.Item onClick={()=>setSelectedTerm("Refunds or Cancellation")} href="#cancellation">9. &nbsp; Refunds or Cancellation</Dropdown.Item>
              <Dropdown.Item onClick={()=>setSelectedTerm("Our responsibility to you")} href="#responsibility">10. &nbsp; Our responsibility to you</Dropdown.Item>
              <Dropdown.Item onClick={()=>setSelectedTerm("Late Fees")} href="#m-late-fee">11. &nbsp; Late Fees</Dropdown.Item>
              <Dropdown.Item onClick={()=>setSelectedTerm("Loss or Damage")} href="#m-damage">12. &nbsp; Loss or Damage</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          </div>
        </div>
        <div className="right">
          <div className="right-content">

              <div className="m-card membership" id="membership">
                <h5 className="heading-h5 title mt-0">1. Membership</h5>
                <p className="detail">A membership entitles a member to borrow books from the library. The maximum number of books a member can borrow is subject to the limit stated in the membership package. Your membership is personal to you and is non-transferable, except where you buy a membership in someone else’s name as a gift, which you can do by nominating a different delivery name and address. In that case, the subscription is personal to that person and non-transferable by them, but you remain bound by these terms and conditions as the contracting party. </p>
              </div>

              <div className="m-card delivery" id="delivery">
                <h5 className="heading-h5 title">2. Delivery</h5>
                <p className="detail">We deliver books only in the UAE. Whilst we endeavour to use fast delivery services, we cannot guarantee next day delivery in every city. For Dubai and Abu Dhabi, orders placed before 12pm are usually fulfilled by next day and it may take up to 3 days to fulfil orders from the rest of the emirates.</p>
              </div>

              <div className="m-card third-parties" id="third-parties">
                <h5 className="heading-h5 title">3. Use of third parties, privacy and use of data.</h5>
                <p className="detail">We may use third parties to fulfil certain parts of the delivery service on our behalf. This means that the information provided by you is passed to such third parties. This information will only be used for the purposes of providing the delivery service.</p>
              </div>

              <div className="m-card membership-period" id="membership-period">
                <h5 className="heading-h5 title">4. Membership Period.</h5>
                <p className="detail">If you are paying for your membership monthly, quarterly or annually, once you have placed an order for a membership package which is accepted by us, the membership will continue and will be renewed automatically unless and until it is ended by either of us in accordance with the provisions of section 9 below.</p>
              </div>

              <div className="m-card payment" id="m-payment">
                <h5 className="heading-h5 title">5. Payment</h5>
                <p className="detail">You must provide us with complete and accurate payment information at the time you purchase the membership package. Payment for your membership can be made by Visa or Mastercard credit or debit card. By submitting credit or debit card details, you confirm that you have the express prior permission of the credit/debit card holder. Your payment of the membership fee will be processed by House of Books (based in the United Arab Emirates). </p>
              </div>

              <div className="m-card m-pause" id="m-pause">
                <h5 className="heading-h5 title">6. Membership Pause</h5>
                <p className="detail">You are allowed to pause a membership for up to 3 months per membership year (starting on the date you first purchase your membership package and each anniversary of this date). The pause period allowance is reset at the beginning of each membership year. You cannot carry over any pause period not taken in one membership year into the next year. You must return all the borrowed books in order to pause the membership. The weeks covered by your membership pause will be credited to your next payment.</p>
              </div>

              <div className="m-card guarantee" id="m-guarantee">
                <h5 className="heading-h5 title">7. Price Guarantee</h5>
                <p className="detail">If you are paying for your membership on a rolling monthly, quarterly or annual basis, we guarantee that your payment will not increase within the first year of your membership, after which period we reserve the right to withdraw or change any membership price. A notification of any change will be sent to you at least 30 days before your next payment due date by email. If we discover any error in the price of your order, we will inform you as soon as possible and correct the price of your order, and provide you with a credit or at your option a refund for any amount overpaid. If you do not wish to continue your membership at the correct price, you may cancel your order.</p>
              </div>

              <div className="m-card m-renewal" id="m-renewal">
                <h5 className="heading-h5 title">8. Membership Renewal</h5>
                <p className="detail">If you pay for your membership on a monthly, quarterly or annual rolling basis, there is no need to renew your membership as it will automatically continue unless and until terminated in accordance with the refund and cancellation provisions detailed below. We will continue to take payment by means of your debit/credit card (as applicable) at the agreed frequency.</p>
              </div>

              <div className="m-card cancellation" id="cancellation">
                <h5 className="heading-h5 title">9. Refunds and Cancellation.</h5>
                <p className="detail">You have the right to cancel your membership at any time. If you cancel by telephone or email, you may use the phone number or email address on the website.</p> 

                <p className="detail">Upon cancellation for any reason, you must return any books borrowed from the House of Books Library. Once all the books are returned without delay or damage we will issue a full refund of the refundable deposit. If you pay for your membership on a monthly, quarterly or annual rolling basis, we shall cease debiting any payments from your account or debit or credit card (as appropriate) at the point of cancellation.</p>

                <p className="detail">If your subscription is cancelled as a result of any breach of your obligations, we shall not refund any deposit made by you. If cancellation otherwise occurs at our discretion, we shall refund any amounts paid in advance as deposit as a result of our decision to cancel.</p>

                <p className="detail">Any refunds will be made to the account or debit or credit card from which the membership fees are taken, and shall be issued in the same currency as that in which the payments were made; in any event you will not incur any fees as a result of the reimbursement.</p>
              </div>

              <div className="m-card responsibility" id="responsibility">
                <h5 className="heading-h5 title">10. Our responsibility to you.</h5>
                <p className="detail">We are under a legal duty to supply goods that are in conformity with our contract with you, however we shall not be responsible for any failure by us to perform our obligations to you in relation to your membership where this failure is caused by circumstances beyond our control. We shall not be responsible for any failure to deliver your books if you have supplied us with an incorrect address for delivery. </p>
              </div>

              <div className="m-card m-late-fee" id="m-late-fee">
                <h5 className="heading-h5 title">11. Late Fees.</h5>
                <div className="detail">
                  <ul>
                    <li>Delaying the return of a borrowed book is subject to a late fee as per our late fee policy.</li>
                    <li>If the book is not returned on the return date a late fee of 5 aed will be charged.</li>
                    <li>If the book is not returned within 15 days after return date a late fee of 10 aed will be charged</li>
                    <li>If the book is not returned within 30 days after pick up date a late fee of 50 aed will be charged and the membership will be paused until the book is recovered.</li>
                  </ul>
                </div>
              </div>

              <div className="m-card m-damage" id="m-damage">
                <h5 className="heading-h5 title">12. Loss or Damage</h5>
                <div className="detail">
                  <ul>
                    <li>In the event that the borrowed book is not returned, or wholly or partially lost or destroyed, the member shall comply with the following:</li>
                    <li>Pay the current retail value of the book and AED 25 in lieu of administrative expenses </li>
                    <li>If the member fails to do as stated above within 90 days of the scheduled return date the membership will be cancelled and the security deposit will be confiscated.</li>
                  </ul>
                </div>
              </div>
          </div>
        </div>
      </div>

      <section className="becoming-member">
        <div className="house_container">
          <div className="image">
            <img src={urlPrefix() + "/assets/image/christin-hume.png"} alt="book imag"></img>
          </div>
          <div className="content">
            <h2 className="heading-h2">Becoming a Member is Easy.</h2>
            <p className="detail">Our members can borrow from a wide range of specially selected books with free pick-up and delivery.</p>
            <div className="cta">
              <CButton fxToRun={()=>navigate(urlPrefix() +  "/join-us")} label="Join Us" custClassName="white-btn-w t-btn"></CButton>
            </div>
          </div>
        </div>
      </section>

    </React.Fragment>
  );
};
export default Term;