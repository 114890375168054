import "../../Css/Loader.css"
import React from 'react'

const Loading = () => {
    return (
        <div className="loader-theme-outer">
            <span className="loader-theme"></span>
        </div>
    )
}

export default Loading