import React from 'react'
import { Outlet } from 'react-router-dom'
import PortalFooter from "./Footer";
import PortalNavbar from "./Navbar";
import { useSelector } from 'react-redux';

const Layout = () => {
    const activePath = useSelector((state) => state.ActivePathReducer.value);
    return (
        <>
            <PortalNavbar />
            <Outlet />
            {
                activePath[1] !== "verify-email" ? <PortalFooter /> : null
            }
        </>
    )
}

export default Layout;