import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from '../Services/Cart';
import { toast } from 'react-toastify';
import { urlPrefix } from '../Helpers/Common';
import { increaseCartQuantity } from '../../Redux/Slices/UserSlice';
import { insertBookInReadingList } from '../Services/Books';
import Loading from './Loading';
import { LoginPopUp } from '.';

const OverviewCard = ({ books, getBooksWithFilters,bookLimit }) => {
  const [loading, setLoading] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const user = useSelector((state) => state.UserReducer.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addToReadingList = async (data) => {
    const { reading_list_id, book_id } = data;
    if (!user.authenticated) {
      navigate(urlPrefix() + "/login")
    }
    else if (reading_list_id) {
      navigate(urlPrefix() + "/subscription-account?key=Reading List");
      return
    }
    else {
      setLoading(true)
      let result = await insertBookInReadingList({ book_id });
      if (result?.success) {
        toast.success(result.message)
        getBooksWithFilters()
      } else {
        toast.error(result.error.message)
      }
      setLoading(false)
    }
  }

  const addToUsersCart = async (data) => {
    const { book_id, cart_id, order_id, order_status, returned_date, borrowing_id } = data;

    if (!user.authenticated) {
      navigate(urlPrefix() + "/login")
    }
    else if (cart_id) {
      navigate(urlPrefix() + "/order?key=OrderNow");
    }
    else if (order_id && ["pending", "approved"].includes(order_status)) {
      navigate(urlPrefix() + "/subscription-account?key=Order History");
    }
    else if (borrowing_id && !returned_date) {
      navigate(urlPrefix() + "/order?key=ReturnNow");
    }
    else {
      setLoading(true)
      const result = await addToCart({ book_id, cart_id: user.cart_id, quantity: 1 });
      if (result?.success) {
        dispatch(increaseCartQuantity())
        toast.success(result.message)
        getBooksWithFilters()
      } else {
        toast.error(result.error.message)
      }
      setLoading(false)
    }
  }

  const bookLabel = (data) => {
    const { cart_id, order_id, order_status, returned_date, borrowing_id } = data
    if (borrowing_id && !returned_date) {
      return "Borrowed"
    }
    else if (order_id && ["pending", "approved"].includes(order_status)) {
      return "Ordered"
    }
    else if (cart_id) {
      return "Go to Cart"
    }
    else {
      return "Add to Cart"
    }
  }

  const navigateToLibraryDetail = (slug)=>{
    localStorage.setItem("overview_page_state",JSON.stringify({limit:bookLimit,scroll:window.scrollY}))
    navigate(urlPrefix() + `/library-detail/${slug}`);
  }

  // This is working when user login through pop up, in order to get the books again with user details attached to them  
  useEffect(()=>{
    getBooksWithFilters();
  },[user.authenticated])

  return (
    <>
      {
        books && books.length ?
          books.map((book) => <div key={book.book_id} className="fiction-slide-card">
            <div className="card-image img-box">
              <img src={book.image_url} alt="book imag"></img>
            </div>
            <div className="hero-hover">
              <div className="hover-content">
                <button className="info more-info" onClick={() => navigateToLibraryDetail(book.slug)}><span className="info-icon">&nbsp;</span>More info</button>
                <button className="info reading" onClick={() => addToReadingList(book)}><span className="info-icon">&nbsp;</span>{book.reading_list_id ? "Reading List" : "Add to List"}</button>
                <button className="info cart" onClick={() => addToUsersCart(book)}><span className="info-icon">&nbsp;</span>{bookLabel(book)}</button>
              </div>
            </div>
            <div className="detail">
              <p className="category">{book?.categories && book?.categories.length ? book.categories.map(category => category).join(', ') : "Category"}</p>
              <h3 className="heading-h3 book-name" onClick={() => navigateToLibraryDetail(book.slug)}>{book.title}</h3>
              <p className="more-info"><div dangerouslySetInnerHTML={{ __html: book.synopsis }} /></p>
            </div>
          </div>) : <div className='no-data'>
            No book found.
          </div>
      }
      {
        loading ? <Loading /> : null
      }
      <LoginPopUp showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal}/>
    </>

  );
};

export default OverviewCard;
