import { useEffect, useState } from 'react'
import { verifyToken } from '../Modules/Services/Auth';
import { initiateSession, terminateSession } from '../Redux/Slices/UserSlice';
import { useDispatch } from 'react-redux';
import { Loading } from '../Modules/Components';

const AuthProvider = ({children }) => {
    const dispatch = useDispatch();
    const [check, setCheck] = useState("pending");
    
    const verifyUserToken = async (savedToken) => {
        let result = await verifyToken(savedToken);
        if (result?.success) {
            dispatch(initiateSession(result.data.token))
        }
        else {
            dispatch(terminateSession())
        }
        setCheck("passed")
    }

    useEffect(() => {
        verifyUserToken(localStorage.getItem("token"))
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            {
                check ===  "pending" ? <Loading/> : children
            }
        </>
    )
}

export default AuthProvider