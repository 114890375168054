import axios from "axios";
import { provideToken } from "../Helpers/Common";

export const createPaymentIntent = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/subscription/create-payment-intent`, params, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const subscribe = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/subscription/subscribe`, params, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const subscribeWithCard = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/subscription/subscribe-with-saved-card`, params, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}


export const currentSubscription = async () => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/subscription/current`,{ headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const allSubscriptions = async () => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/subscription`,{ headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}


export const cancelRecurringPayments = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/subscription/cancel-recurring-payments`, params, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}
