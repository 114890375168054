import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import SlideMoreInfo from './SlideHover';
import { getBooks, insertBookInReadingList } from '../Services/Books';
import { useNavigate } from "react-router-dom";
import { addToCart } from '../Services/Cart';
import { toast } from 'react-toastify';
import { urlPrefix } from '../Helpers/Common';
import { increaseCartQuantity } from '../../Redux/Slices/UserSlice';
import Loading from './Loading';
import LoginPopUp from './LoginPopUp';

const HomeHeroCarousel = () => {
    const [loading, setLoading] = useState(false);
    const [showLoginModal,setShowLoginModal] = useState(false);
    const dispatch = useDispatch()
    const user = useSelector((state) => state.UserReducer.value);
    const splideRef = useRef(null);
    const navigate = useNavigate()
    const [books, setBooks] = useState([]);

    const splideOptions = {
        direction: 'ttb',
        height: '100%',
        perPage: 1,
        padding: '4rem',
        wheel: false,
        // rewindByDrag: false,
        lazyLoad: true,
        // start: 0,
        // snap: false,
        perMove: 1,
        pagination: false,
        interval: 5000,
        speed: 3000,  
        dragSpeed: 10000,
        drag: true,
        gap: '30px',
        type: 'loop',
        loop: true,
        autoplay: true,
        breakpoints: {

            1440: {
                padding: '1.5rem',
                perPage: 5,
            },
            
            1366: {
                padding: '1.5rem',
            },

            1280: {
                padding: '2rem',
            },

            768: {
                direction: 'ltr',
                perPage: 2.5,
                gap: '15px',
                wheel: false,
                loop: true,
                type: 'loop',
            },

            414: {
                direction: 'ltr',
                perPage: 1.5,
                snap: true,
                gap: '20px',
                padding: '0rem',
                height: 'auto',
                wheel: false,
                interval: 5000,
                speed: 5000,
                dragSpeed  : 5000,
                pauseOnFocus:  true,
                autoplay: false,
            },

            430: {
                direction: 'ltr',
                perPage: 1.5,
                snap: true,
                gap: '20px',
                padding: '0rem',
                height: 'auto',
                wheel: false,
                interval: 5000,
                speed: 5000,
                dragSpeed  : 5000,
                pauseOnFocus:  true,
                autoplay: false,
            },

            375: {
                direction: 'ltr',
                perPage: 1.5,
                snap: true,
                gap: '20px',
                padding: '0rem',
                height: 'auto',
                wheel: false,
                interval: 5000,
                speed: 5000,
                dragSpeed  : 5000,
                pauseOnFocus:  true,
                autoplay: false,
            }
            
        }
    };
    
    const getInitialPageData = async () => {
        setLoading(true)
        const result = await getBooks({ include_categories:true, top_book:true});
        result.success ? setBooks(result.data) : setBooks([])
        setLoading(false)
    }

    const fxToRun = (data) => {
        navigate(urlPrefix() + data)
    }

    const addToReadingList = async (data) => {
        const { reading_list_id, book_id } = data;
        if (!user.authenticated) {
            navigate(urlPrefix() + "/login") 
        }
        else if (reading_list_id) {
            navigate(urlPrefix() + "/subscription-account?key=Reading List");
            return
        }
        else {
            setLoading(true)
            let result = await insertBookInReadingList({ book_id });
            if (result?.success) {
                toast.success(result.message)
                getInitialPageData()
            } else {
                toast.error(result.error.message)
            }
            setLoading(false)
        }
    }

    const addToUsersCart = async (data) => {
        const { book_id, cart_id, order_id, order_status, returned_date, borrowing_id } = data;

        if (!user.authenticated) {
            navigate(urlPrefix() + "/login")
        }
        else if (cart_id) {
            navigate(urlPrefix() + "/order?key=OrderNow");
        }
        else if (order_id && ["pending", "approved"].includes(order_status)) {
            navigate(urlPrefix() + "/subscription-account?key=Order History");
        }
        else if (borrowing_id && !returned_date) {
            navigate(urlPrefix() + "/order?key=ReturnNow");
        }
        else {
            setLoading(true)
            const result = await addToCart({ book_id, cart_id: user.cart_id, quantity: 1 });
            if (result?.success) {
                toast.success(result.message)
                dispatch(increaseCartQuantity())
                getInitialPageData()
            } else {
                toast.error(result.error.message)
            }
            setLoading(false)
        }
    }

    const bookLabel = (data) => {
        const { cart_id, order_id, order_status, returned_date, borrowing_id } = data
        if (borrowing_id && !returned_date) {
            return "Borrowed"
        }
        else if (order_id && ["pending", "approved"].includes(order_status)) {
            return "Ordered"
        }
        else if (cart_id) {
            return "Go to Cart"
        }
        else {
            return "Add to Cart"
        }
    }

    useEffect(() => {
        getInitialPageData()
    }, [user.cart_quantity])

    return (
        <>
            {
                books && books.length ?
                    <Splide
                        hasTrack={true}
                        aria-label="..."
                        options={splideOptions}
                        ref={splideRef}
                    >
                    {
                        books.map((item,index) =>
                        <SplideSlide key={index}>
                            <div className="hero-book-card">
                                <div className="card-image">
                                    <img src={item.image_url} alt="book imag"></img>
                                </div>
                                <div className="hero-hover">
                                    <div className="hover-content">
                                        <SlideMoreInfo label="More info" custClassName="info more-info" data={`/library-detail/${item.slug}`} fxToRun={fxToRun}></SlideMoreInfo>
                                        <SlideMoreInfo label={item.reading_list_id ? "Reading List" : "Add to List"} fxToRun={addToReadingList} data={item} custClassName="info reading"></SlideMoreInfo>
                                        <SlideMoreInfo label={bookLabel(item)} fxToRun={addToUsersCart} data={item} custClassName="info cart"></SlideMoreInfo>
                                    </div>
                                </div>
                                <div className="card-content">
                                    <div className="sub-title">
                                        <p>{item.categories.filter((category, index) => index < 3).join(", ")}</p>
                                    </div>
                                    <div className="title" onClick={()=> navigate(urlPrefix() + `/library-detail/${item.slug}`)}>
                                        <p>{item.title}</p>
                                    </div>
                                    <div className="detail">
                                        <p><div dangerouslySetInnerHTML={{ __html: item.synopsis }} /></p>
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>)
                    }

                    </Splide> :
                    <div className="no-data">
                        No Books found.
                    </div>
            }
            {
                loading ? <Loading/>: null
            }
            <LoginPopUp showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal}/>
        </>
    );
};

export default HomeHeroCarousel;
