import axios from "axios";
import { provideToken } from "../../Helpers/Common";
import DOMPurify from "dompurify";

export const adminUploadImage = async (file) => {
    try {
        const formData = new FormData();
        formData.append('file', file)
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/upload-image/`, formData, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return (typeof error?.response?.data === "string") ? { error: { message: DOMPurify.sanitize(error?.response?.data, { USE_PROFILES: { html: false } }) } } : error?.response?.data
    }
}