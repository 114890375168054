import React, { useRef, useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { getBooks} from '../Services/Books';
import { categoryIds } from "../../Config/Constant"
import { urlPrefix } from '../Helpers/Common';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { increaseCartQuantity } from '../../Redux/Slices/UserSlice';
import { addToCart } from '../Services/Cart';
import { toast } from 'react-toastify';
import SlideMoreInfo from './SlideHover';
import Loading from './Loading';

const YouAlsoLike = ({changeLibraryDetailBook,categoryId,rejectedBooks,setShowMostInDemand}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((state) => state.UserReducer.value);
  const splideRef = useRef(null);
  const [books, setBooks] = useState([]);
  const [splideType, setSplideType] = useState("loop");

  const fxToRun = (data) => { 
    const {url,slug} = data;
    navigate(urlPrefix() + url)// This is just changing the url. 
    changeLibraryDetailBook(slug)// Main change in the library detail page book is done by this.
}

const addToUsersCart = async (data) => {
    const { book_id, cart_id, order_id, order_status, returned_date, borrowing_id } = data;

    if (!user.authenticated) {
      navigate(urlPrefix() + "/login");
    }
    else if (cart_id) {
      navigate(urlPrefix() + "/order?key=OrderNow");
    }
    else if (order_id && ["pending", "approved"].includes(order_status)) {
      navigate(urlPrefix() + "/subscription-account?key=Order History");
    }
    else if (borrowing_id && !returned_date) {
      navigate(urlPrefix() + "/order?key=ReturnNow");
    }
    else {
      setLoading(true)
      const result = await addToCart({ book_id, cart_id: user.cart_id, quantity: 1 });
      if (result?.success) {
        toast.success(result.message)
        dispatch(increaseCartQuantity())
        getInitialPageData()
      } else {
        toast.error(result.error.message)
      }
      setLoading(false)
    }
  }

  const bookLabel = (data) => {
    const {cart_id, order_id, order_status, returned_date, borrowing_id } = data
    if (borrowing_id && !returned_date) {
      return "Borrowed"
    }
    else if (order_id && ["pending", "approved"].includes(order_status)) {
      return "Ordered"
    }
    else if (cart_id) {
      return "Go to Cart"
    }
    else {
      return "Add to Cart"
    }
  }

  const splideOptions = {
    wheel: false,
    loop: true,
    start: 0,
    perPage: 4,
    perMove: 1,
    snap: true,
    interval: 5000,
    speed: 3000,  
    dragSpeed: 10000,
    autoplay: true,
    arrows: true,
    pagination: true,
    rewind: true,
    rewindByDrag: true,
    lazyLoad: true,
    gap: '50px',
    drag: true,
    type: splideType,
    breakpoints: {
      1440: {
        perPage: 3.5,
        gap: '30px',
      },
      1280: {
        perPage: 3,
        gap: '30px',
      },
      1024: {
        perPage: 3,
        gap: '25px', 
      },
      768: {
        perPage: 2, 
        gap: '10px',
        snap: true
      },
      600: {
        perPage: 1.5,
        gap: '0px',
        snap: true,
        interval: 3000,
        speed: false,
        dragSpeed  : false,
        pauseOnFocus:  true,
      },
      430: {
        perPage: 1.5,
        gap: '10px',
        padding: '0rem',
        interval: 5000,
        speed: 5000,
        dragSpeed  : 5000,
        pauseOnFocus:  true,
        autoplay: false,
      },
      414: {
        perPage: 1.5,
        gap: '10px', 
        padding: '0rem',
        interval: 5000,
        speed: 5000,
        dragSpeed  : 5000,
        pauseOnFocus:  true,
        autoplay: false,
      },
      375: {
        perPage: 1.5,
        gap: '10px',
        padding: '0rem',
        interval: 5000,
        speed: 5000,
        dragSpeed  : 5000,
        pauseOnFocus:  true,
        autoplay: false,
      },
    }
  }

  const getInitialPageData = async () => {
    setLoading(true)
    const result = await getBooks({ filters: {categories:[categoryId],rejected_books:rejectedBooks} , limit: null, start_from: null, search: null, include_categories:true });
    if(result?.success){
      setBooks(result.data)
      setSplideType(result?.data?.length > 3 ? "loop" : "slide")
      if(result?.data.length){
        setShowMostInDemand(true)
      }
      else{
        setShowMostInDemand(false)
      }
    }
    else{
      setBooks([])
      setShowMostInDemand(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    getInitialPageData()
  }, [categoryId,rejectedBooks])

  return (
    <>
      {
        books.length ?
          <Splide
            hasTrack={true}
            aria-label="..."
            options={splideOptions}
            ref={splideRef}
          >
            {
              books.map((item,index) =>
                <SplideSlide key={index} >
                  <div className="fiction-slide-card">
                    <div className="card-image img-box">
                      <img src={item.image_url} alt="book imag"></img>
                    </div>
                    <div className="hero-hover">
                        <div className="hover-content">
                            <SlideMoreInfo label="More info" custClassName="info more-info" data={{url:`/library-detail/${item.slug}`,slug:item.slug}} fxToRun={fxToRun}></SlideMoreInfo>
                            <SlideMoreInfo label="Reading List" custClassName="info reading"></SlideMoreInfo>
                            <SlideMoreInfo label={bookLabel(item)} fxToRun={addToUsersCart} data={item} custClassName="info cart"></SlideMoreInfo>
                        </div>
                    </div>
                    <div className="detail">
                      <p className="category">{item.categories.filter((category, index)=> index < 3).join(", ")}</p>
                      <h3 className="heading-h3 book-name" onClick={()=> navigate(urlPrefix() + `/library-detail/${item.slug}`)}>{item.title}</h3>
                      <p className="more-info"><div dangerouslySetInnerHTML={{ __html: item.synopsis }} /></p>
                    </div>
                  </div>
                </SplideSlide>)
            }

          </Splide> :
          <div className="no-data">
            No Books found.
          </div>
      }
      {
        loading ? <Loading/>: null
      }
    </>
  );
};

export default YouAlsoLike;
