import React, { useEffect, useState } from "react";
import { Tab, Tabs } from 'react-bootstrap';
import OrderNow from "../MyAccount/OrderNow";
import ReturnNow from "../MyAccount/ReturnNow";
import { getCartBooks } from "../Services/Cart";
import { useSelector } from "react-redux";
import { getAddress } from "../Services/User";
import { getBooks } from "../Services/Books";
import { getTimeSlots } from "../Services/Order";
import moment from "moment";

const OrderDetail = () => {
  const key = new URLSearchParams(window.location.search).get("key");
  const user = useSelector((state) => state.UserReducer.value);
  const [cartBooks,setCartBooks] = useState([]);
  const [borrowedBooks,setBorrowedBooks] = useState([]);
  const [address,setAddress] = useState("")
  const [timeSlots,setTimeSlots] = useState([]);
  const [nextSevenDays,setNextSevenDays] = useState([]);

  
  const updateCartBooks = async () =>{
    const result = await getCartBooks({cart_id:user.cart_id});
    result.success ? setCartBooks(result.data) : setCartBooks([])
  }

  const updateBorrowedBooks = async () =>{
    const result = await getBooks({remove_limit_offset: true, borrowed_books: true,include_authors:true });
    result.success ? setBorrowedBooks(result.data) : setBorrowedBooks([])
  }

  const provideNextSevenDays = ()=>{
    let days = []
    let index = 1
    while(days.length < 7){
      if(!["Saturday","Sunday"].includes(moment().add(index,"days").format("dddd")))
      days.push(moment().add(index,"days").format("YYYY-MM-DD dddd"))
      index++
    }
    return days
  }

  const getInitialPageData = async () => {    
    const requests = [getAddress(),getTimeSlots(),updateCartBooks(),updateBorrowedBooks()]
    const result = await Promise.all(requests)

    if(result.length > 1 && result[0]?.data){
      setAddress(result[0].data)
    }

    if(result.length > 2 && result[1]?.data){
      setTimeSlots(result[1].data)
    }

    setNextSevenDays(provideNextSevenDays())
  }

  useEffect(() => {
    getInitialPageData()
  }, [])

  return (
    <React.Fragment>
      <div className="order-page-hero">
        <h5 className="heading-h5 hero-s-title">cart</h5>
        <h1 className="heading-h1 hero-title">Your Basket of Books</h1>
      </div>

      <div className="subscription-main order-now-main">
        <Tabs defaultActiveKey={key ?? "OrderNow"} id="OrderNowMain" className="cost-tab" fill >

          <Tab eventKey="OrderNow" title="Order Now">
            <OrderNow cartBooks={cartBooks} updateCartBooks={updateCartBooks} address={address} nextSevenDays={nextSevenDays} timeSlots={timeSlots}></OrderNow>
          </Tab>

          <Tab eventKey="ReturnNow" title="Return Now">
            <ReturnNow borrowedBooks={borrowedBooks} updateBorrowedBooks={updateBorrowedBooks} address={address} nextSevenDays={nextSevenDays} timeSlots={timeSlots}></ReturnNow>
          </Tab>

        </Tabs>
      </div>

    </React.Fragment>
  );
};
export default OrderDetail;