import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import moment from 'moment';

const ConfirmationModal = ({ show, setShow, acceptFx, rejectFx, data }) => {

    const [note, setNote] = useState("");
    const [deliveredDate, setDeliveredDate] = useState(moment().format('YYYY-MM-DD'))

    return (
        <Modal className="cost-book-modal order-list85-modal" show={show} size="md" onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure, You want to proceed?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mod856">
                    <>
                    {
                        data?.name == "completed" ? (
                            <>
                            <p>Select Delivered Date</p>
                            {/* <p><i>(Date on which 30 days duration will be added.)</i></p> */}
                            <input type='date' required value={deliveredDate} onChange={(e) => setDeliveredDate(e.target.value)}/>
                            </>
                        ) : null
                    }
                    </>
                    <p>Add A Note</p>
                    <textarea value={note} onChange={(e) => setNote(e.target.value)}></textarea>
                </div>
                <div className="mod-foter">
                    <button className="accept-reject" onClick={() => { acceptFx({ ...data, ...(note ? { note } : {} ), ...({delivered_date:deliveredDate})  }); setNote("") }}>Accept</button>
                    <button className="accept-reject" onClick={() => rejectFx(data)}>Reject</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmationModal



