import axios from "axios";
import { provideToken } from "../Helpers/Common";

export const getPlans = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/plans/`,{ params, headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}