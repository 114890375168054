import { AdminValidator } from "./Validators/AdminValidator";
import PageNotFound from "../Modules/Layout/404";
import ListBooks from "../Modules/Admin/Books/ListBooks";
import AddBook from "../Modules/Admin/Books/AddBook";
import EditBook from "../Modules/Admin/Books/EditBook";
import ListPlans from "../Modules/Admin/Plans/ListPlans";
import AddPlan from "../Modules/Admin/Plans/AddPlan";
import EditPlan from "../Modules/Admin/Plans/EditPlan";
import ListMembershipPlans from "../Modules/Admin/Plans/ListMembershipPlans";
import AddMembershipPlan from "../Modules/Admin/Plans/AddMembershipPlan";
import ListOrders from "../Modules/Admin/Orders/ListOrders";
import ListSubscription from "../Modules/Admin/Subscriptions/ListSubscription";
import ListRetrievalOrders from "../Modules/Admin/Orders/ListRetrievalOrders";
import ListDefaulters from "../Modules/Admin/User/ListDefaulters";
import AddDefaulter from "../Modules/Admin/User/AddDefaulter";
import ListFaqs from "../Modules/Admin/Cms/ListFaqs";
import AddFaq from "../Modules/Admin/Cms/AddFaq";
import EditFaq from "../Modules/Admin/Cms/EditFaq";
import ListAuthors from "../Modules/Admin/Authors/ListAuthors";
import AddAuthor from "../Modules/Admin/Authors/AddAuthor";
import EditAuthor from "../Modules/Admin/Authors/EditAuthor";
import ListCategories from "../Modules/Admin/Categories/ListCatgories";
import AddCategory from "../Modules/Admin/Categories/AddCategory";
import EditCategory from "../Modules/Admin/Categories/EditCategory";
import ListPublishers from "../Modules/Admin/Publishers/ListPublishers";
import AddPublisher from "../Modules/Admin/Publishers/AddPublisher";
import EditPublisher from "../Modules/Admin/Publishers/EditPublisher";
import { Navigate } from "react-router-dom";
import ListInquiries from "../Modules/Admin/Cms/ListInquiries";
import ListCurrentlyBorrowed from "../Modules/Admin/Orders/ListCurrentlyBorrowed";
import ListRefunds from "../Modules/Admin/Refunds/ListRefunds";
import ListUsers from "../Modules/Admin/User/ListUsers";

export const Admin = [
    {
        path: process.env.REACT_APP_FRONT_URL + "/admin/",
        element: <AdminValidator />,
        errorElement: <PageNotFound />,
        children: [
            {
                path: '',
                element: <Navigate to="/admin/books"/>,
            },
            {
                path: 'books',
                element: <ListBooks />,
            },
            {
                path: 'books/insert',
                element: <AddBook />
            },
            {
                path: 'books/edit/:id',
                element: <EditBook />
            },
            {
                path: 'plans',
                element:<ListPlans />,
            },
            {
                path: 'plans/insert',
                element: <AddPlan />
            },
            {
                path: 'plans/edit/:id',
                element: <EditPlan />
            },
            {
                path: 'membership-plans',
                element: <ListMembershipPlans />,
            },
            {
                path: 'membership-plans/insert',
                element: <AddMembershipPlan />
            },
            {
                path: 'subscriptions',
                element: <ListSubscription />
            },
            {    
                path: 'orders',
                element: <ListOrders />
            },
            {    
                path: 'retrievals',
                element: <ListRetrievalOrders />
            },
            {    
                path: 'borrowed',
                element: <ListCurrentlyBorrowed />
            },
            {
                path: 'defaulters',
                element: <ListDefaulters />,
            },
            {
                path: 'defaulters/insert',
                element: <AddDefaulter />
            },
            {
                path: 'inquiries',
                element: <ListInquiries />
            },
            {
                path: 'faqs',
                element: <ListFaqs />
            },
            {
                path: 'faqs/insert',
                element: <AddFaq/>
            },
            {
                path: 'faqs/edit/:id',
                element: <EditFaq />
            },
            {
                path: 'authors',
                element: <ListAuthors />
            },
            {
                path: 'authors/insert',
                element: <AddAuthor />
            },
            {
                path: 'authors/edit/:id',
                element: <EditAuthor />
            },
            {
                path: 'categories',
                element: <ListCategories />
            },
            {
                path: 'categories/insert',
                element: <AddCategory />
            },
            {
                path: 'categories/edit/:id',
                element: <EditCategory />
            },
            {
                path: 'publishers',
                element: <ListPublishers />
            },
            {
                path: 'publishers/insert',
                element: <AddPublisher />
            },
            {
                path: 'publishers/edit/:id',
                element: <EditPublisher />
            },
            {
                path: 'user-refunds',
                element: <ListRefunds />
            },
            {
                path: 'users',
                element: <ListUsers />
            },
        ]
    },
    // Just so that every other route that does not match the above ones will be redirected.
    {
        path: process.env.REACT_APP_FRONT_URL + "/*",
        element: <Navigate to="/admin/books"/>,
    }
]