import React, { useEffect, useState } from 'react';
import { AdminUpdateOrderStatus } from '../../Services/Order';
import { toast } from 'react-toastify';
import { Loading } from '../../../Components';
import ConfirmationModal from './ConfirmationModal';
import useClickAway from '../../../../CustomHooks/ClickAwayHook';
import { upperFirst } from 'lodash';


const StatusDropDown = ({ options, currentSelected, openedDropdown, dropdownId, setOpenedDropDown,getInitialPageData,note }) => {
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [show,setShow] = useState(false);
    const [selectedOption, setSelectedOption] = useState(currentSelected);
    const [confirmData,setConfirmData] = useState(null)
    const clickAwayRef = useClickAway(()=>{
        if(setOpenedDropDown){
            if(dropdownId === openedDropdown){
                setOpenedDropDown(null)
            }
        }
    });

    
    const toggleDropdown = () => {
        if (dropdownId) {
            if (isOpen) {
                setOpenedDropDown(null)
            }
            else {
                setOpenedDropDown(dropdownId)
            }
        }
        setIsOpen(!isOpen);
    };



    const updateOrderStatus = async (option) => {
        setLoading(true);
        const {name,note,delivered_date} = option;
        setShow(true)
        let result = await AdminUpdateOrderStatus({ id: dropdownId, status: name,detail:note, delivered_date});
        if (result?.success) {
            setSelectedOption(option)
            toast.success(result.message)
            getInitialPageData()
        } else {
            toast.error(result.error.message)
        }
        setLoading(false);
        setIsOpen(false);
        setShow(false)
    }

    useEffect(() => {
        if (dropdownId && openedDropdown !== dropdownId) {
            setIsOpen(false)
        }
    }, [openedDropdown])

    return (
        <div className={`custom-dropdown ${isOpen ? 'open' : ''}`} ref={clickAwayRef}>
            <div className="selected-option category" onClick={toggleDropdown}>
                {upperFirst(selectedOption.name)}
            </div>
            {isOpen && (
                <div className="dropdown-options common-dropdown">
                    {options.map((option, index) => (
                        <div key={options.id} className="option" onClick={() => {
                            if(selectedOption.id !== option.id){
                                setShow(true);
                                setConfirmData(option);
                            }
                            else{
                                setIsOpen(false)
                            }
                        }}>
                            {selectedOption?.name &&  selectedOption.name === option.name ? <b>{upperFirst(option.name)}</b> : upperFirst(option.name)}
                        </div>
                    ))}
                </div>
            )}
            {
                loading ? <Loading /> : null
            }
            <ConfirmationModal currentNote={note} show={show} setShow={setShow} acceptFx={updateOrderStatus} rejectFx={()=>{setShow(false);setIsOpen(false)}} data={confirmData}/>
        </div>
    );
};

export default StatusDropDown;
