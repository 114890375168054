import React, { useEffect, useState } from 'react'
import { CButton, Loading } from '../../Components';
import { useNavigate } from 'react-router-dom';
import AdminNavbar from '../Navbar/AdminNavbar';
import { Button, Form, InputGroup } from 'react-bootstrap';
import MakePagination from '../../Components/MakePagination';
import { urlPrefix } from '../../Helpers/Common';
import { deleteAuthor, getAdminAuthors } from '../Services/Author';
import { useDebounce } from '../../../CustomHooks/DebounceHook';
import { toast } from 'react-toastify';
import ConfirmationModal from '../Books/Components/ConfirmationModal';

const ListAuthors = () => {
    const navigate = useNavigate()
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [authors, setAuthors] = useState([]);
    const [totalAuthors, setTotalAuthors] = useState(0);
    const [pagination, setPagination] = useState({ limit: 10, start_from: 0, search: null, order_by: [{ column: 'authors.id', order: 'desc' }] });
    const debouncedSearch = useDebounce(search,500);
    const [confirmationModal,setConfirmationModal] = useState({show:false, data: null, acceptFx: null, rejectFx: null,message:null});

    const selectFilter = (filter, data) => {
        if (filter === "order") {
            setPagination({ ...pagination, order_by: [data] })
        }
        else if (filter === "pagination") {
            setPagination({ ...pagination, start_from: data })
        }
        else if (filter === "search") {
            setPagination({ ...pagination, start_from: 0, search: data && data.length ? data.trim() : null })
        }
    }

    const getInitialPageData = async () => {
        setLoading(true)
        const result = await getAdminAuthors({ ...pagination, total_count_of: "authors.id" });
        setAuthors(result.data.authors)
        setTotalAuthors(result.data.totalAuthorRecords)
        setLoading(false)
    }

    // delete author
    const deleteAuthorClick = async (data) => {
        const { id } = data;
        setLoading(true)
        const result = await deleteAuthor({id})
        if (result.success) {
            toast.success(result.message)
            getInitialPageData()
        } else {
            toast.error(result?.error?.message)
        }
        setConfirmationModal({show:false, data: null, acceptFx: null, rejectFx: null,message:null})
        setLoading(false)
    }

    useEffect(() => {
        getInitialPageData()
    }, [pagination])

    useEffect(()=>{ 
        selectFilter("search",search)
    },[debouncedSearch])

    return (
        <div className="admin-panel-main">
            <div className="panel-row">
                <div className="left">
                    <AdminNavbar></AdminNavbar>
                </div>
                <div className="right">
                    <div className="panel-title-top all-book">
                        <div className="top-left">
                            <h3 className="heading-h3">Authors list</h3>
                        </div>
                        <div className="top-right admin-filter">
                            <div className="right-side">
                                <CButton fxToRun={() => { navigate(urlPrefix() + "/admin/authors/insert") }} label="Add Author" custClassName="login-button t-btn"></CButton>
                            </div>
                        </div>
                    </div>

                    <div className="dash-filter filter">
                        <div className="left-fil" />
                        <div className="search">
                            <InputGroup className="admin-search-input" onChange={(e) => setSearch(e.target.value)} >
                                <Form.Control placeholder="Search Author name" value={search || ""} />
                            </InputGroup>
                        </div>
                    </div>

                    <div className="admin-panel-data">
                        <div className="top-title all-plans">
                            <div className="order-no">
                                <p className="top-t">Name</p>
                            </div>
                            <div className="title">
                                <p className="top-t">Description</p>
                            </div>
                            <div className="e-edit-delete">
                                <p className="top-t">Actions</p>
                            </div>
                        </div>
                        <div className="book-list">
                            {
                                authors && authors.length ?
                                    authors.map((author) => <div className="book-row all-plans" key={author.author_id}>
                                        <div className="order-no-value">
                                            <p className="top-value">{author.name}</p>
                                        </div>
                                        <div className="title-value">
                                            <p className="top-value">{author.description}</p>
                                        </div>
                                        <div className="e-edit-delete-value">
                                            <a className="edit-action" onClick={() => navigate(urlPrefix() + `/admin/authors/edit/${author.author_id}`)}><img src={urlPrefix() + "/assets/image/edit-green.png"} alt='book-image' /></a>
                                            <a className="delete-action" onClick={() => setConfirmationModal({
                                                show:true,
                                                data:{id:author.author_id},
                                                acceptFx:deleteAuthorClick,
                                                message:`Are you sure, you want to delete author ?`,
                                                rejectFx:()=>{setConfirmationModal({ show:false, data: null, acceptFx: null, rejectFx: null, message:null})}
                                            })}><img src={urlPrefix() + "/assets/image/trash.svg"} alt='book-image' /></a>
                                        </div>
                                    </div>) :
                                    <div className='no-data'>
                                        No Authors found.
                                    </div>
                            }

                            {
                                authors && authors.length ?
                                    <div className="books-pagination">
                                        <MakePagination totalRecords={totalAuthors} limiter={10} selectFilter={selectFilter}></MakePagination>
                                    </div> : null
                            }

                        </div>
                    </div>
                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
             <ConfirmationModal confirmationModal={confirmationModal}/>
        </div>
    )
}

export default ListAuthors
