import React, {  } from "react";
import { CButton } from "../Components";
import PortalNavbar from "./Navbar";
import { urlPrefix } from "../Helpers/Common";

const PageNotFound = () => {

  return (
    <React.Fragment>
      {/* 404 page */}
      <PortalNavbar />
      <div className="page-not-found">
        <div className="thanku-page-main">
            <div className="icon-thumb">
                <img src={urlPrefix() + "/assets/image/thumbs-down.png"} alt="thumb up"></img>
            </div>
            <h2 className="heading-h2 thanku-title"><span>Sorry, we are facing some difficulties</span> <span>in loading the requested page.</span></h2>
            <h5 className="heading-h5 thanku-subtile">Please try again later.</h5>
            <div className="cta">
                <CButton redirectTo=" " label="Back to home" custClassName="cost-btn t-btn"></CButton>
            </div>
        </div>
      </div>
      {/* end */}
    </React.Fragment>
  );
};

export default PageNotFound;