import { useEffect, useRef } from 'react';

export const useClickAway = (callback, idToExclude = false) => {
    const ref = useRef(null);

    useEffect(() => {
        function handleClick(event) {
            if(idToExclude){
                if (ref.current && !ref.current.contains(event.target) && event.target !== document.getElementById(idToExclude)) {
                    callback(event);
                }
            }
            else{
                if (ref.current && !ref.current.contains(event.target)) {
                    callback(event);
                }
            }            
        }

        document.addEventListener('click', handleClick);
        
        return () => {
            document.removeEventListener('click', handleClick);
        };

    }, [callback]);
    
    return ref;
}

export default useClickAway;