import React, { useEffect, useState } from 'react';
import AdminNavbar from '../Navbar/AdminNavbar'
import { CButton, Loading } from '../../Components'
import { Form, FormGroup } from "react-bootstrap";
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import {  validateAddMembershipPlanForm } from './Validators';
import { planTypes } from '../../../Config/Constant';
import { AdminCreateMembershipPlan, getAdminPlans } from '../Services/Plans';
import { useNavigate } from 'react-router-dom';
import { urlPrefix } from '../../Helpers/Common';

const AddMembershipPlan = () => {
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState([]);
    const navigate = useNavigate();

    const getInitialPageData = async () => {
        setLoading(true)
        let result = await getAdminPlans({remove_limit_offset:true});
        if(result?.success){
            setPlans(result.data);
            formik.setValues({...formik.values, plan_id: result.data[0].plan_id, plan_type:[planTypes[0]]})
        }else{
            navigate(urlPrefix() + "/admin/membership-plans")
        }
        setLoading(false)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            plan_id: "",
            plan_type: "",
            price: 0.00,
            chargeable_price: 0.00,
            refundable_deposit: 0,
            borrow_limit: 2,
        },
        validate: validateAddMembershipPlanForm,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true)
            const { plan_type, ...rest } = values;
            let result = await AdminCreateMembershipPlan({...rest,...plan_type[0]});
            result?.success ? toast.success(result.message) : toast.error(result?.error?.message)
            navigate( urlPrefix() + "/admin/membership-plans")
            setLoading(false)
        }
    });

    const errorElement = (msg) => {
        return <div className='small text-danger'>{msg}</div>
    }

    const handleMultiSelect = (e, formikKey, check) => {
        const value = JSON.parse(e.target.value);
        //Run this code when ready for multi select
        // const exist = find(formik.values[formikKey] , (item) => item[check] === value[check])
        // if(!exist){
        //   formik.setFieldValue('publishers', [...formik.values.publishers,value]);
        // }
        //For now just do this
        formik.setFieldValue(formikKey, [value]);
    }

    useEffect(() => {
        getInitialPageData()
    }, [])


    return (
        <div className="admin-panel-main">
            <div className="panel-row">
                <div className="left">
                    <AdminNavbar></AdminNavbar>
                </div>
                <div className="right">
                    
                    <div className="add-book">
                        <div className="panel-title-top">
                            <div className="top-left">
                            <button className='top-back-12' onClick={()=> navigate( urlPrefix() + "/admin/membership-plans")}>Back</button>
                                <h3 className="heading-h3">Add Membership Plan</h3>
                            </div>
                            <div className="top-right">
                                <div className="right-side">
                                    {/* <CButton label="Add Book" custClassName="login-button t-btn"></CButton> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="add-book-form">
                        <Form className="book-panel-form" onSubmit={formik.handleSubmit}>
                            <div className="pan-form-row">

                                <div className="cost-form-input">
                                    <FormGroup className="cost-form-group">
                                        <Form.Label>Plan:</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            name="plan_id"
                                            onChange={(e) => { formik.setFieldValue("plan_id", e.target.value)}}
                                            onBlur={formik.handleBlur}
                                        >
                                            {
                                                plans.map((item) => <option value={item.plan_id}>{item.name}</option>)
                                            }
                                        </Form.Select>
                                        {formik.errors.plan_id ? errorElement(formik.errors.plan_id) : null}
                                    </FormGroup>
                                </div>

                                <div className="cost-form-input">
                                    <FormGroup className="cost-form-group">
                                        <Form.Label>Plan Type:</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            name="plan_type"
                                            onChange={(e) => { handleMultiSelect(e, "plan_type", "") }}
                                            onBlur={formik.handleBlur}
                                        >
                                            {
                                                planTypes.map((item) => <option value={JSON.stringify(item)}>{item.plan_type}</option>)
                                            }
                                        </Form.Select>
                                        {formik.errors.plan_type ? errorElement(formik.errors.plan_type) : null}
                                    </FormGroup>
                                </div>

                                <div className="cost-form-input">
                                    <FormGroup className="cost-form-group">
                                        <Form.Label>Price:</Form.Label>
                                        <input
                                            id="price"
                                            name="price"
                                            onChange={formik.handleChange}
                                            value={formik.values.price}
                                            type="number"
                                            min={0.00}
                                            placeholder="Choose the Price"
                                            className="form-control"
                                            autoComplete="new-password"
                                        />
                                        {formik.errors.price ? errorElement(formik.errors.price) : null}
                                    </FormGroup>
                                </div>

                                <div className="cost-form-input">
                                    <FormGroup className="cost-form-group">
                                        <Form.Label>Discounted Price:</Form.Label>
                                        <input
                                            id="chargeable_price"
                                            name="chargeable_price"
                                            onChange={formik.handleChange}
                                            value={formik.values.chargeable_price}
                                            type="number"
                                            min={0.00}
                                            placeholder="Choose the discounted price"
                                            className="form-control"
                                            autoComplete="new-password"
                                        />
                                        {formik.errors.chargeable_price ? errorElement(formik.errors.chargeable_price) : null}
                                    </FormGroup>
                                </div>

                                <div className="cost-form-input">
                                    <FormGroup className="cost-form-group">
                                        <Form.Label>Refundable Deposit:</Form.Label>
                                        <input
                                            id="refundable_deposit"
                                            name="refundable_deposit"
                                            onChange={formik.handleChange}
                                            value={formik.values.refundable_deposit}
                                            type="number"
                                            min={0.00}
                                            placeholder="Choose the refundable deposit"
                                            className="form-control"
                                            autoComplete="new-password"
                                        />
                                        {formik.errors.refundable_deposit ? errorElement(formik.errors.refundable_deposit) : null}
                                    </FormGroup>
                                </div>


                                <div className="cost-form-input">
                                    <FormGroup className="cost-form-group">
                                        <Form.Label>Borrow Limit:</Form.Label>
                                        <input
                                            id="borrow_limit"
                                            name="borrow_limit"
                                            onChange={formik.handleChange}
                                            value={formik.values.borrow_limit}
                                            type="number"
                                            min={1}
                                            placeholder="Choose the borrow limit"
                                            className="form-control"
                                            autoComplete="new-password"
                                        />
                                        {formik.errors.borrow_limit ? errorElement(formik.errors.borrow_limit) : null}
                                    </FormGroup>
                                </div>

                            </div>
                            <div className="submit-cta">
                                <CButton label="Submit" type="submit" custClassName="login-button t-btn"></CButton>
                            </div>

                        </Form>
                    </div>

                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
        </div>
    )
}

export default AddMembershipPlan