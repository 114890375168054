import axios from "axios";
import { provideToken } from "../../Helpers/Common";

export const getAdminOrders = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/admin/orders/`, { params, headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const getAdminCurrentlyBorrowed = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/admin/orders/borrowed`, { params, headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const getAdminBooksRetrievalOrders = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/admin/orders/retrieval`, { params, headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const AdminUpdateRetrievalOrderStatus = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/orders/retrieval/update-status`, params, {  headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const AdminInsertScheduledReturnDate = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/orders/retrieval/insert-scheduled-return-date`, params, {  headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const AdminUpdateOrderStatus = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/orders/update-status`, params, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}
