import React, { useEffect, useState } from "react";
import { CButton, Loading, LoginPopUp } from "../Components";
import { Form, FormGroup, Accordion, Modal } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckOutPage from "./CheckOutPage";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPlans } from "../Services/Plans";
import { createPaymentIntent } from "../Services/Subscription";
import { toast } from "react-toastify";
import { getCards } from "../Services/User";
import { getFaqs } from "../Services/Cms";
import { urlPrefix } from "../Helpers/Common";
import { upperCase,findIndex, startCase} from "lodash";

const JoinUs = () => {
    const [accKey,setAccKey] = useState(null);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const user = useSelector((state) => state.UserReducer.value);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedMembershipPlan, setSelectedMembershipPlan] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const [show, setShow] = useState(false);
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [cards, setCards] = useState([]);
    const [faqs, setFaqs] = useState([]);
    const [paymentDetails,setPaymentDetails] = useState(null);
    const [hasActivePlan,setHasActivePlan] = useState(false);
    const [refundableAmount,setRefundableAmount] = useState(0);

    const getInitialPageData = async () => {
        setLoading(true)
        const requests = [getPlans({ plan_details_child_form: true, membership_plans_child_form: true }), getFaqs({limit: 5})];
        const results = await Promise.all(requests);
        const activePlan = findIndex(results[0].data, (item) => { return item.user_id});
        if(activePlan !== -1){
            setHasActivePlan(true)
        }
        setSubscriptionPlans(results[0].data)
        setFaqs(results[1].data);
        setLoading(false)
    }

    const handlePaymentIntentCreation = async (plan_id) => {
        if (!selectedMembershipPlan) {
            toast.error("Please choose a plan first.")
            return
        }
        if(plan_id !== selectedPlan){
            toast.error("Please choose correct plan.")
            setSelectedMembershipPlan(null)
            setSelectedPlan(null)
            return
        }

        setLoading(true);
        const requests = [createPaymentIntent({ membership_plan_id: selectedMembershipPlan }), getCards()]
        const results = await Promise.all(requests);
        if (results[0]?.success) {
            setClientSecret(results[0].data.paymentIntent.client_secret)
            setPaymentDetails({
                amount : (results[0].data.paymentIntent.amount)/100,
                currency:  upperCase(results[0].data.paymentIntent.currency) ,
            })
            setCards(results[1].data.cards)
            // set refund deposit
            setRefundableAmount(results[0]?.data?.refundable);
            setShow(true)
        }
        else {
            setSelectedMembershipPlan(null)
            toast.error(results[0].error.message)
        }
        setLoading(false)
    }

    useEffect(() => {
        getInitialPageData()
    }, [])

    
  useEffect(()=>{
    setTimeout(()=>{
      const accordionItems = document.querySelectorAll('.accordion-item');
      accordionItems.forEach(item => {
        const accordionShow = item.querySelector('.show');
        if (accordionShow) {
          item.classList.add('item-show');
        }else{
          item.classList.remove('item-show');
        }
      });
    },400)
  },[accKey])

    return (
        <React.Fragment>
            <div className="library-overview-hero join-us">
                <div className="house_container">
                    <h5 className="heading-h5 overview-subtitle">Join House of Books</h5>
                    <h1 className="heading-h1 overview-title">Join our Readers Community</h1>
                    <h5 className="heading-h5 overview-detail">choose your membership</h5>
                </div>
            </div>

            <div className="choose-plan-main">
                <div className="house-container">
                    <div className="plan-row">
                        {
                            subscriptionPlans && subscriptionPlans.length ?
                                subscriptionPlans.map((plan,index) => <div key={index} className={`common-card ${plan.user_id ? "active" : ""}`}>
                                    <div className="plan-card">
                                        <div id="">
                                            <h3 className="heading-h3 card-title">{plan.name} {hasActivePlan}</h3>
                                            <ul className="card-detail">
                                                {
                                                    plan.plan_details.map((plan_detail,index) => <li key={index}>{plan_detail.description}</li>)
                                                }
                                            </ul>
                                            <div className="choose-plan">
                                                <h5 className="heading-h5">choose your plan:</h5>
                                                {
                                                    plan.membership_plans.map((membership_plan,index) => <div key={index} className="plan-detail" onClick={() => {
                                                        if (!hasActivePlan) {
                                                            setSelectedMembershipPlan(membership_plan.membership_plan_id);
                                                            setSelectedPlan(membership_plan.plan_id);
                                                        }
                                                    }}>
                                                        <div className="common-radio">
                                                            <FormGroup className="select-radio" id={membership_plan.membership_plan_id}>
                                                                <Form.Check checked={[plan.membership_plan_id, selectedMembershipPlan].includes(membership_plan.membership_plan_id)} className="active" type="radio" label={startCase(membership_plan.plan_type)} name="ChoseUserType" id={membership_plan.membership_plan_id} />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="plan-price">
                                                            {
                                                                membership_plan?.price && membership_plan?.price > 0 ? <p className="old-price"> AED {membership_plan.price}</p> : <p></p>
                                                            }
                                                            <p className="new-price">AED {membership_plan.chargeable_price}</p>
                                                        </div>
                                                    </div>)
                                                }
                                            </div>
                                            <div className="plan-cta">
                                                <CButton disabled={plan.user_id || hasActivePlan ? true : false} fxToRun={() => { user.authenticated ? handlePaymentIntentCreation(plan.plan_id) : navigate(urlPrefix() + "/register") }} label={plan.user_id ? "Active" : "Choose Plan"} custClassName="login-button t-btn"></CButton>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>) : <div className="no-data">No Plan found</div>
                        }
                    </div>
                </div>
            </div>

            <div className="join-questions-main">
                <div className="faq-row">
                    <div className="left">
                        <div className="q-card">
                            <p>Frequently Asked Questions</p>
                        </div>
                        <div className="q-card-cta">
                            <CButton fxToRun={() => navigate(urlPrefix() + "/faq")} label="Show more" custClassName="cost-btn t-btn"></CButton>
                        </div>
                    </div>
                    <div className="right">
                        <div className="faq-accordion">
                            <Accordion>
                                {faqs && faqs.length ? (
                                    faqs.map((faq, index) => (
                                        <Accordion.Item key={index} eventKey={index} onClick={()=>setAccKey(index === accKey ? null : index)}>
                                            <Accordion.Header>{index + 1}. {faq.question}</Accordion.Header>
                                            <Accordion.Body dangerouslySetInnerHTML={{__html: faq.answer}}></Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                ) : (
                                    <div className="no-data">
                                        No faqs found.
                                    </div>
                                )}
                            </Accordion>
                        </div>

                        <div class="cta">
                            <button type="submit" class="cost-btn t-btn s-mobile" onClick={() => navigate(urlPrefix() + "/faq")}>Explore Everything<span>&nbsp;</span></button>
                        </div>

                    </div>
                </div>
            </div>

            <Modal className="join-us-modal" show={show} size="lg" onHide={() => { setShow(false); setClientSecret(null);setSelectedMembershipPlan(null) }} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Payment Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <CheckOutPage setSelectedMembershipPlan={setSelectedMembershipPlan} getInitialPageData={getInitialPageData} setShow={setShow} cards={cards} client_secret={clientSecret} membership_plan_id={selectedMembershipPlan} paymentDetails={paymentDetails} refundableAmount={refundableAmount}/>
                    </Elements>
                </Modal.Body>
            </Modal>

            {
                loading ? <Loading /> : null
            }
            <LoginPopUp showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} />
        </React.Fragment>
    );
};
export default JoinUs;

