import axios from "axios";
import DOMPurify from "dompurify";
import { provideToken } from "../../Helpers/Common";

export const getAdminBooks = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/admin/books/`, { params, headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const uploadBooksCsv = async (file) => {
    try {
        const formData = new FormData();
        formData.append('file', file)
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/books/upload/`, formData, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return (typeof error?.response?.data === "string") ? { error: { message: DOMPurify.sanitize(error?.response?.data, { USE_PROFILES: { html: false } }) } } : error?.response?.data
    }
}

export const AdminCreateBook = async (params, file) => {
    try {
        let { authors, publishers, categories, dimensions, ...rest } = params
        const formData = new FormData();
        formData.append("file", file)
        formData.append("authors", JSON.stringify(authors))
        formData.append("publishers", JSON.stringify(publishers))
        formData.append("categories", JSON.stringify(categories))
        Object.entries(rest).map(([key, value]) => formData.append(key, value));
        formData.append("dimensions", dimensions);
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/books/create/`, formData, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return (typeof error?.response?.data === "string") ? { error: { message: DOMPurify.sanitize(error?.response?.data, { USE_PROFILES: { html: false } }) } } : error?.response?.data
    }
}

export const getAdminSingleBook = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/admin/books/single`, { params, headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const AdminUpdateBookStatus = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/books/update-status/`, params, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const AdminUpdateMostInDemandBook = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/books/update-most-in-demand`, params, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const AdminDeleteBook = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/books/delete/`, params, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}