import React, { useEffect, useState } from "react";
import { MobileMenu, CButton, LoginPopUp } from "../Components";
import { useDispatch, useSelector } from "react-redux";
import { terminateSession } from "../../Redux/Slices/UserSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { urlPrefix } from "../Helpers/Common";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Button } from "react-bootstrap";
import useClickAway from "../../CustomHooks/ClickAwayHook";
import useScrollPosition from "../../CustomHooks/ScrollPostionHook";
import { useDebounce } from "../../CustomHooks/DebounceHook";
import { getBooks, getSearchedBooks } from "../Services/Books";
import {findIndex} from "lodash"

const PortalNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [closeMobileMenu, setCloseMobileMenu] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const user = useSelector((state) => state.UserReducer.value);
  const activePath = useSelector((state) => state.ActivePathReducer.value);
  const clickAwayRef = useClickAway(() => { setIsOpen(false);setBooks([]) }, "search_button_exclude_for_click_away")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const scrollY = useScrollPosition();
  const location = useLocation();
  const [linkVisible, setLinkVisible] = useState(false);
  const [whiteBgClass, setwhiteBgClass] = useState("");
  const [registerlinkVisible, setRegisterlinkVisible] = useState(false);
  const [loginlinkVisible, setLoginlinkVisible] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search,500);
  const [books,setBooks] = useState([]);

  const fxToRun = () => {
    if (user.authenticated) {
      dispatch(terminateSession())
      navigate(urlPrefix() + "/")
    } else {
      navigate(urlPrefix() + "/login")
    }
    setIsOpen(false)
  }

  const searchBook = (searchInput) => {
    searchInput = searchInput && searchInput.length ? searchInput.trim() : null
    if (searchInput && searchInput.length) {
      navigate(urlPrefix() + `/library-overview?book=${searchInput}`)
    }
    setIsOpen(false)
    setSearch(null)
  }

  const fetchSearchedBooks = async () => {
    if (search && search.length > 2) {
      const result = await getSearchedBooks({ search: search })
      if (result?.success) {
        setBooks(result.data)
      }else{
        setBooks([])
      }
    }
    else{
      setBooks([])
    }
  }

  const handleSecondaryButton = () => {
    const path = location.pathname
    let index = findIndex(['/forgot-password', '/login', '/register', '/subscription-account'], (i) => { return i == path });

    setLinkVisible(index < 1 ? false : true)
    setRegisterlinkVisible(index == 2 ? false : true)
    setLoginlinkVisible(index == 1 ? false : true)
    setwhiteBgClass(index > -1 ? "green-hover" : "")
  }

  const handleLinkClick = () => {
    const path = urlPrefix() + "/library-overview";
    navigate(path); // This will update the URL without reloading the page
    window.location.reload(); // This will reload the page
  };

  useEffect( () => {
    handleSecondaryButton()
  },[location.pathname]);


  useEffect(()=>{
    fetchSearchedBooks()
  },[debouncedSearch])

  return (
    <React.Fragment>
      <div className="mob mobile-menu-main">
        <MobileMenu closeMobileMenu={closeMobileMenu} setCloseMobileMenu={setCloseMobileMenu}></MobileMenu>
      </div>
      <div className="web-header">
        <div className={`house-header ${whiteBgClass} ${scrollY > 244 ? "fix-header" : ""}`}>
          <div className="header-logo-main">
            <a onClick={() => navigate(urlPrefix() + "/")} className="header-logo">
              <img className="desktop-logo" src={urlPrefix() + "/assets/image/MainLogo.svg"} alt='login-logo' />
              <img className="fixed-menu-logo" src={urlPrefix() + "/assets/image/mobile-logo.png"} alt='login-logo' />
              <img className="mobile20-logo" src={urlPrefix() + "/assets/image/mobile-logo-a.svg"} alt='login-logo' />
            </a>
            <div className="show-on-mobile">
              <img className="fixed-menu-logo" src={urlPrefix() + "/assets/image/mobile-logo.png"} alt='login-logo' />
            </div>
            <div className="new-cta-s1">
              {
                user.authenticated && !linkVisible ?
                  <CButton fxToRun={() => navigate(urlPrefix() + "/subscription-account")} label={user.authenticated ? "My Account" : "Register"} custClassName="header-btn-y t-btn n-o-mobile"></CButton>  : null    
              }

              {
                !user.authenticated && registerlinkVisible ?
                  <CButton fxToRun={() => navigate(urlPrefix() + "/register")} label={"Register"} custClassName="header-btn-y t-btn n-o-mobile"></CButton> : null
              }
              {
              !user.authenticated && loginlinkVisible ?
              <CButton fxToRun={fxToRun} label={user.authenticated ? "Logout" : "Login"} custClassName="header-btn-y t-btn n-o-mobile"></CButton>
              : null
              }
              {
              user.authenticated ?
              <CButton fxToRun={fxToRun} label={user.authenticated ? "Logout" : "Login"} custClassName="header-btn-y t-btn n-o-mobile"></CButton>
              : null
              }
            </div>
            {/* <CButton fxToRun={fxToRun} label={user.authenticated ? "Logout" : "Become a Member"} custClassName="header-btn-y t-btn n-o-mobile"></CButton> */}
          </div>
          <div className="left-sidebar">
            <div className="cost-navbar">
              <div className="nav-list">
                <ul className="cost-nav">
                  <li className="link"><a className={`cost-link ${activePath[1] === "" ? "active" : null}`} onClick={() => { navigate(urlPrefix() + "/") }}>Home</a></li>
                  <li className="link"><a className={`cost-link ${activePath[1] === "our-story" ? "active" : null}`} onClick={() => { navigate(urlPrefix() + "/our-story") }}>Our Story</a></li>
                  <li className="link"><a className={`cost-link ${activePath[1] === "library-overview" ? "active" : null}`} onClick={handleLinkClick}>The Library</a></li>
                  <li className="link"><a className={`cost-link ${activePath[1] === "join-us" ? "active" : null}`} onClick={() => { navigate(urlPrefix() + "/join-us") }}>Membership Plans</a></li>
                </ul>
              </div>
            </div>

            <div className="social-left">
              <div className="top">
                <ul className="social-top">
                  <li className="s-top">
                    <a className="header-search" onClick={() => {
                      setIsOpen(!isOpen);
                      setSearch(null);
                      setCloseMobileMenu(true)
                    }}>
                      <img src={urlPrefix() + "/assets/image/Search.png"} alt="search" className="real-img"></img>
                      <img id="search_button_exclude_for_click_away" src={urlPrefix() + "/assets/image/search-green.png"} alt="search" className="hover-img"></img>
                    </a>
                    {/* <button onClick={toggleDiv}>Toggle Div</button> */}

                    {isOpen && (
                      <form className="he-serach-v1">
                        <div className="searchdiv" ref={clickAwayRef}>
                          <InputGroup className="" value={search} onChange={(e) => setSearch(e.target.value)} aria-required>
                            <Form.Control
                              placeholder="Search Book, ISBN, Author"
                              aria-label="Search Book, ISBN, Author"
                              aria-describedby="basic-addon2"
                            />
                            <Button type="submit" onClick={(e) => {e.preventDefault();searchBook(search)}} variant="outline-secondary" id="button-addon2">
                              <img src={urlPrefix() + "/assets/image/search-green.png"} alt="search" className="hover-img"></img>
                            </Button>
                          </InputGroup>
                        </div>
                        {
                          books && books.length ?
                            <div className="show-on-click">
                              {
                                books.map((item)=><div className="content">
                                <p onClick={()=>searchBook(item.title)}>{item.title}</p>
                              </div>)
                              }
                            </div> : null
                        }
                      </form>
                    )}

                  </li>
                  <li className="s-top">
                    <a className="header-cart" onClick={() => {
                      user.authenticated ? navigate(urlPrefix() + "/order") : navigate(urlPrefix() + "/login");
                      setCloseMobileMenu(true)
                    }}
                    >
                      <img src={urlPrefix() + "/assets/image/ShoppingCart.png"} alt="search" className="real-img"></img>
                      <img src={urlPrefix() + "/assets/image/shopping-cart-green.png"} alt="search" className="hover-img"></img>
                      {
                        user.authenticated && user.cart_quantity > 0 ?
                          <span className="cart-value">{user.cart_quantity}</span> : null
                      }
                    </a>
                  </li>
                  <li className="s-top mobile-left">
                    <a className="header-account" onClick={() => {
                      user.authenticated ? navigate(urlPrefix() + "/subscription-account") : navigate(urlPrefix() + "/login");
                      setCloseMobileMenu(true)
                    }}
                    >
                      <img src={urlPrefix() + "/assets/image/MyAccount.png"} alt="search" className="real-img"></img>
                      <img src={urlPrefix() + "/assets/image/my-account-green.png"} alt="search" className="hover-img"></img>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="bottom">
                <a className="header-whatsapp" href="#">
                  <img src={urlPrefix() + "/assets/image/WhatsApp.png"} alt="search" className="real-img"></img>
                  <img src={urlPrefix() + "/assets/image/whats-app-green.png"} alt="search" className="hover-img"></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginPopUp showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} />
    </React.Fragment>
  );
};

export default PortalNavbar;
