import React from 'react';
import { useNavigate } from 'react-router-dom';
import { urlPrefix } from '../Helpers/Common';

const PreviousOrders = ({previousOrders}) => {

    const navigate = useNavigate()

    return (
        <div className="previous-orders">
            {
                previousOrders && previousOrders.length ? 
                previousOrders.map((order,index) => <div className="order-book-card" key={index}>
                <div className="book">
                    <img src={order.image_url} alt="book imag" onClick={()=> navigate(urlPrefix() + `/library-detail/${order.slug}`)}></img>
                </div>
                <div className="detail">
                    <div className='top-row previous-v1'>
                        <div className="order-n">
                            <p className="o-title">Order No:</p>
                            <p className="o-sub-title">#{order.order_id}</p>
                        </div><div className="order-n">
                            <p className="o-title">Status:</p>
                            <p className="o-sub-title">{order.order_status} {order.order_status === "cancelled" && `(${order.detail})` ?  order.detail : null}</p>
                            
                        </div>
                        <div className="order-s-date">
                            <p className="o-title">Borrowed on:</p>
                            <p className="o-sub-title">{order.borrowed_date ?? "-------"}</p>
                        </div>
                        <div className="order-exp-date">
                            <p className="o-title">Returned on:</p>
                            <p className="o-sub-title">{order.scheduled_return_date ?? "-------" }</p>
                        </div>
                    </div>
                    <div className="book-name" onClick={()=> navigate(urlPrefix() + `/library-detail/${order.slug}`)}>
                        <h3 className="heading-h3 title">{order.title}</h3>
                    </div>
                    <div className="book-detail">
                        <p className="s-title">By {order?.authors?.map((author) => author).join(" ,")}</p>
                    </div>
                    {/* <div className="cta">
                        <CButton label="Reissue order" custClassName="cost-btn t-btn"></CButton>
                    </div> */}
                </div>
            </div>) : <div className='no-data'>No orders yet.</div>
            }
        </div>
    )
}
export default PreviousOrders;