import React, { useEffect, useState } from 'react'
import { Loading } from '../Components';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getBooks } from '../Services/Books';
import { placeRenewBookOrder } from '../Services/Order';
import { useNavigate } from 'react-router-dom';
import { urlPrefix } from '../Helpers/Common';

const CurrentStack = () => {
    const user = useSelector((state) => state.UserReducer.value);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [books, setBooks] = useState([]);

    const getInitialPageData = async () => {
        setLoading(true)
        let result = await getBooks({ remove_limit_offset: true, borrowed_books: true, include_authors: true })
        result?.success ? setBooks(result.data) : setBooks([]);
        setLoading(false)
    }

    const handelRenewDate = async (book_id) => {
        let result = await placeRenewBookOrder({ book_id });
        if (result.success) {
            getInitialPageData()
            toast.success(result.message)
        }
        else {
            toast.error(result.error.message)
        }
    }

    useEffect(() => {
        getInitialPageData()
    }, [])

    return (
        <div className="profile1-m1">
            <h3 className="heading-h3 pro1-title">Current books</h3>
            <div className="reading-list15">
                {
                    books && books.length ?
                        books.map((book,index) => <div className="order-book-card" key={index}>
                            <div className="book">
                                <img src={book.image_url} alt="book imag" onClick={()=> navigate(urlPrefix() + `/library-detail/${book.slug}`)}></img>
                            </div>
                            <div className="detail">
                                <div className='top-row'>
                                    <div className="order-n">
                                        <p className="o-title">Title: </p>
                                        <p className="o-sub-title" onClick={()=> navigate(urlPrefix() + `/library-detail/${book.slug}`)}>{book.title}</p>
                                        <p className="o-sub-title">By {book?.authors?.map((author) => author).join(" ,")}</p>
                                    </div>
                                    <div className="order-n">
                                        <p className="o-title">Issue Date: </p>
                                        <p className="o-sub-title">{book?.borrowed_date}</p>
                                    </div>
                                    <div className="order-n">
                                        <p className="o-title">Due Date:</p>
                                        <p className="o-sub-title">{book?.due_date}</p>
                                    </div>
                                    <div className="order-n new15">
                                        <p className="o-title">Actions</p>
                                        {
                                            book?.renew_date ? <p className="o-sub-title">Renewed</p>: 
                                            <p className="o-sub-title">
                                                <button className="accept-reject" onClick={()=>handelRenewDate(book.book_id)}>Extend date</button>
                                            </p> 
                                        }
                                        <p className="o-sub-title">
                                            <button className="accept-reject"  onClick={()=>navigate(urlPrefix() + "/order?key=ReturnNow")}>Return</button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>) : <div className='no-data'>No Books borrowed yet.</div>
                }
            </div>

            {
                loading ? <Loading /> : null
            }

        </div>
    )
}

export default CurrentStack;