import React, { useEffect, useState } from 'react';
import AdminNavbar from '../Navbar/AdminNavbar'
import { CButton, Loading } from '../../Components'
import { Form, FormGroup } from "react-bootstrap";
import { getAdminAuthors } from '../Services/Author';
import { getAdminPublishers } from '../Services/Publisher';
import { getAdminCategories } from '../Services/Categories';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { validateAddBookForm } from './Validators';
import { useNavigate } from "react-router-dom"
import { AdminCreateBook } from '../Services/Books';
import { urlPrefix } from '../../Helpers/Common';
import { findIndex, pullAt } from 'lodash';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

const AddBook = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [selectedCategories,setSelectedCategories] = useState({});
  const [selectedAuthors,setSelectedAuthors] = useState({});
  const [initialFormValues, setInitialFormValues] = useState(
    {
      title: "",
      publishers: [],
      categories: [],
      authors: [],
      date_published: "",
      ISBN10: "",
      ISBN13: "",
      synopsis: "",
      quantity_available: 1,
      rating: 0,
      language: "",
      total_pages: 0,
      edition: "",
      binding: "",
      dimensions: " 1 X 1 X 1",
      most_in_demand: false,
      top_book: false,
      why_we_love_it: "",
    }
  )

  const uploadFunction = () => {
    document.getElementById("uploadBtn").onchange = function () {
      document.getElementById("uploadFile").value = this.value.replace("C:\\fakepath\\", "");
      formik.setFieldValue("image", this.files[0])
    };
  }

  const getInitialPageData = async () => {
    setLoading(true);
    const requests = [getAdminAuthors({ order_by: [{ column: 'authors.name', order: 'asc' }], remove_limit_offset: true }), getAdminPublishers({ order_by: [{ column: 'publishers.name', order: 'asc' }], remove_limit_offset: true }), getAdminCategories({ order_by: [{ column: 'categories.name', order: 'asc' }], remove_limit_offset: true })];
    const results = await Promise.all(requests);
    setAuthors(results[0].data)
    setPublishers(results[1].data)
    setCategories(results[2].data)
    // publishers: [{ ...results[1].data[0] }]
    setInitialFormValues({ ...initialFormValues,publishers: [{...results[1].data[0] }], authors: [{ ...results[0].data[0] }], categories: [{ ...results[2].data[0] }] })
    setSelectedCategories({...categories,[`${results[2].data[0].category_id}`]:true})
    setSelectedAuthors({...authors,[`${results[0].data[0].author_id}`]:true })
    setLoading(false);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    validate: validateAddBookForm,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const { image, ...rest } = values;
      let result = await AdminCreateBook(rest, image);
      setLoading(false)
      if (result?.success) {
        toast.success(result.message)
        resetForm()
        navigate(urlPrefix() + "/admin/books")
      } else {
        toast.error(result?.error?.message)
      }
    }
  });

  const errorElement = (msg) => {
    return <div className='small text-danger'>{msg}</div>
  }

  const handleMultiSelect = (e, formikKey, check) => {
    const value = JSON.parse(e.target.value);

    //Run this code when ready for multi select
    // const exist = find(formik.values[formikKey] , (item) => item[check] === value[check])
    // if(!exist){
    //   formik.setFieldValue('publishers', [...formik.values.publishers,value]);
    // }

    //For now just do this
    formik.setFieldValue(formikKey, [value]);
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    formik.setFieldValue('synopsis', data);
  }

  useEffect(() => {
    getInitialPageData()
  }, [])


  const handleMultipleSelection = (formikKey,list,key_to_check,e,item_to_remove = null)=>{

    if(!e?.target?.value && !item_to_remove){
      return
    }

    const new_item = item_to_remove || JSON.parse(e.target.value)  

    const index = findIndex(list, (obj) => obj[key_to_check] == new_item[key_to_check] );

    if(index === -1){
      formik.setFieldValue(formikKey,[...list,new_item]);

      if(formikKey === "categories"){
        setSelectedCategories({...selectedCategories,[`${new_item[key_to_check]}`] : true})
      }
      if(formikKey === "authors"){
        setSelectedAuthors({...selectedAuthors,[`${new_item[key_to_check]}`] : true})
      }
    }
    else{
      pullAt(list, index)
      formik.setFieldValue(formikKey,list);

      if(formikKey === "categories"){
        setSelectedCategories({...selectedCategories,[`${new_item[key_to_check]}`] : false})
      }

      if(formikKey === "authors"){
        setSelectedAuthors({...selectedAuthors,[`${new_item[key_to_check]}`] : false})
      }
    }
  }

  return (
    <div className="admin-panel-main">
      <div className="panel-row">
        <div className="left">
          <AdminNavbar></AdminNavbar>
        </div>
        <div className="right">

          <div className="add-book">
            <div className="panel-title-top">
              <div className="top-left">
                <button className='top-back-12' onClick={() => navigate(urlPrefix() + "/admin/books")}>Back</button>
                <h3 className="heading-h3">Add book</h3>
              </div>
              <div className="top-right">
                <div className="right-side">
                  {/* <CButton label="Add Book" custClassName="login-button t-btn"></CButton> */}
                </div>
              </div>
            </div>
          </div>

          <div className="add-book-form">
            <Form className="book-panel-form" onSubmit={formik.handleSubmit}>
              <div className="pan-form-row">

                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Book Name:</Form.Label>
                    <input
                      id="title"
                      name="title"
                      type="title"
                      onChange={formik.handleChange}
                      value={formik.values.title}
                      placeholder="The Handsmade’s Tail"
                      className="form-control"
                      autoComplete="new-password"
                    />
                    {formik.errors.title ? errorElement(formik.errors.title) : null}
                  </FormGroup>
                </div>

                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Publisher:</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="publishers"
                      onChange={(e) => { handleMultiSelect(e, "publishers", "publisher_id") }}
                      onBlur={formik.handleBlur}
                    >
                      {
                        publishers.map((item) => <option value={JSON.stringify(item)}>{item.name}</option>)
                      }
                    </Form.Select>
                    {formik.errors.publishers ? errorElement(formik.errors.publishers) : null}
                  </FormGroup>
                </div>

                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Category:</Form.Label>
                    {
                      formik.values.categories.length ? 
                      <div className="this-v1">
                        {
                          formik.values.categories.map((i)=> <p>{i.name}<span onClick={()=>handleMultipleSelection("categories",formik.values.categories,"category_id","",i)}>&nbsp;</span></p>)
                        }
                      </div>
                      :null
                    }
                    {/* <input value={formik.values.categories.map((item)=> item.name).join(" ,")}></input> */}
                    <Form.Select
                      aria-label="Default select example"
                      name="categories"
                      onChange={(e) => { handleMultipleSelection("categories",formik.values.categories,"category_id",e) }}
                      onBlur={formik.handleBlur}
                      // multiple
                    >
                      {
                        categories.map((item) => <option value={JSON.stringify(item)} className={`${selectedCategories[item.category_id] ? "fw-bold":""}`} >{item.name}</option>)
                      }
                    </Form.Select>
                    {formik.errors.categories ? errorElement(formik.errors.categories) : null}
                  </FormGroup>
                  
                </div>

                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Author:</Form.Label>
                    {
                      formik.values.authors.length ? 
                      <div className="this-v1">
                        {
                          formik.values.authors.map((i)=> <p>{i.name}<span onClick={()=>handleMultipleSelection("authors",formik.values.authors,"author_id","",i) }>&nbsp;</span></p>)
                        }
                      </div>
                      :null
                    }
                    {/* new <input value={formik.values.authors.map((item)=> item.name).join(" ,")}></input> */}
                    <Form.Select
                      aria-label="Default select example"
                      name="authors"
                      onChange={(e) => { handleMultipleSelection("authors",formik.values.authors,"author_id",e) }}
                      onBlur={formik.handleBlur}
                    >
                      {
                        authors.map((item) => <option value={JSON.stringify(item)} className={`${selectedAuthors[item.author_id] ? "fw-bold":""}`}>{item.name}</option>)
                      }
                    </Form.Select>
                    {formik.errors.authors ? errorElement(formik.errors.authors) : null}
                  </FormGroup>
                </div>
                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Publish date:</Form.Label>
                    <input
                      id="date_published"
                      name="date_published"
                      onChange={formik.handleChange}
                      value={formik.values.date_published}
                      type="date"
                      placeholder="Choose the date"
                      className="form-control"
                      autoComplete="new-password"
                    />
                    {formik.errors.date_published ? errorElement(formik.errors.date_published) : null}
                  </FormGroup>
                </div>

                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Why we love it:</Form.Label>
                    <textarea
                      id="why_we_love_it"
                      name="why_we_love_it"
                      onChange={formik.handleChange}
                      value={formik.values.why_we_love_it}
                      type="text"
                      placeholder="Start typing…"
                      className="form-control"
                      autoComplete="new-password"
                    />
                    {formik.errors.why_we_love_it ? errorElement(formik.errors.why_we_love_it) : null}
                  </FormGroup>
                </div>

                <div className="cost-form-input ck-editor-v1">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Synopsis:</Form.Label>
                    {/* <textarea
                      id="synopsis"
                      name="synopsis"
                      onChange={formik.handleChange}
                      value={formik.values.synopsis}
                      type="text"
                      placeholder="Start typing…"
                      className="form-control"
                      autoComplete="new-password"
                      rows={2}
                    /> */}
                    <CKEditor editor={ClassicEditor} config={{ toolbar: {
                                items: ['undo', 'redo', '|', 'bold', 'italic'],
                            },
                            plugins: [Bold, Essentials, Italic, Mention, Paragraph, Undo],
                        }}
                        data={formik.values.synopsis}
                        onChange={handleEditorChange}
                    />
                    {formik.errors.synopsis ? errorElement(formik.errors.synopsis) : null}
                  </FormGroup>
                </div>

                <div className="cost-form-input">
                  <Form.Label>Image: <small><i>Dimensions(400 X 600) & Max Size: 5MB</i></small></Form.Label>
                  <div className="custom-upload-file">
                    <input id="uploadFile" class="f-input" placeholder="Select your image" />
                    <div class="fileUpload btn btn--browse">
                      <span>Browse</span>
                      <input onClick={() => { uploadFunction() }} id="uploadBtn" type="file" class="upload" />
                    </div>
                  </div>
                  {formik.errors.image ? errorElement(formik.errors.image) : null}
                </div>

                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>ISBN10:</Form.Label>
                    <input
                      id="ISBN10"
                      name="ISBN10"
                      onChange={formik.handleChange}
                      value={formik.values.ISBN10}
                      type="text"
                      placeholder="Fill in the 10-digit ISBN-nr."
                      className="form-control"
                      autoComplete="new-password"
                    />
                    {formik.errors.ISBN10 ? errorElement(formik.errors.ISBN10) : null}
                  </FormGroup>
                </div>

                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>ISBN13:</Form.Label>
                    <input
                      id="ISBN13"
                      name="ISBN13"
                      onChange={formik.handleChange}
                      value={formik.values.ISBN13}
                      type="text"
                      placeholder="Fill in the 13-digit ISBN-nr."
                      className="form-control"
                      autoComplete="new-password"
                    />
                    {formik.errors.ISBN13 ? errorElement(formik.errors.ISBN13) : null}
                  </FormGroup>
                </div>
                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Quantity:</Form.Label>
                    <input
                      id="quantity_available"
                      name="quantity_available"
                      onChange={formik.handleChange}
                      value={formik.values.quantity_available}
                      type="number"
                      min={1}
                      placeholder="Fill in quantity"
                      className="form-control"
                      autoComplete="new-password"
                    />
                    {formik.errors.quantity_available ? errorElement(formik.errors.quantity_available) : null}
                  </FormGroup>
                </div>
                {/* <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Rating:</Form.Label>
                    <input
                      id="rating"
                      name="rating"
                      onChange={formik.handleChange}
                      value={formik.values.rating}
                      min={0}
                      max={5}
                      type="number"
                      placeholder="Fill in rating"
                      className="form-control"
                      autoComplete="new-password"
                    />
                    {formik.errors.rating ? errorElement(formik.errors.rating) : null}
                  </FormGroup>
                </div> */}
                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Edition:</Form.Label>
                    <input
                      id="edition"
                      name="edition"
                      onChange={formik.handleChange}
                      value={formik.values.edition}
                      type="text"
                      placeholder="Fill in edition"
                      className="form-control"
                      autoComplete="new-password"
                    />
                    {formik.errors.edition ? errorElement(formik.errors.edition) : null}
                  </FormGroup>
                </div>
                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Binding:</Form.Label>
                    <input
                      id="binding"
                      name="binding"
                      onChange={formik.handleChange}
                      value={formik.values.binding}
                      type="text"
                      placeholder="Fill in binding"
                      className="form-control"
                      autoComplete="new-password"
                    />
                    {formik.errors.binding ? errorElement(formik.errors.binding) : null}
                  </FormGroup>
                </div>
                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Pages:</Form.Label>
                    <input
                      id="total_pages"
                      name="total_pages"
                      onChange={formik.handleChange}
                      value={formik.values.total_pages}
                      min={0}
                      type='number'
                      placeholder="Fill inn total pages"
                      className="form-control"
                      autoComplete="new-password"
                    />
                    {formik.errors.total_pages ? errorElement(formik.errors.total_pages) : null}
                  </FormGroup>
                </div>
                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Dimensions:</Form.Label>
                    <input
                      id="dimensions"
                      name="dimensions"
                      onChange={formik.handleChange}
                      value={formik.values.dimensions}
                      type="text"
                      placeholder="Fill in dimensions"
                      className="form-control"
                      autoComplete="new-password"
                    />
                    {formik.errors.dimensions ? errorElement(formik.errors.dimensions) : null}
                  </FormGroup>
                </div>

                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Language:</Form.Label>
                    <input
                      id="language"
                      name="language"
                      onChange={formik.handleChange}
                      value={formik.values.language}
                      type="text"
                      placeholder="Fill in language"
                      className="form-control"
                      autoComplete="new-password"
                    />
                    {formik.errors.language ? errorElement(formik.errors.language) : null}
                  </FormGroup>
                </div>

                <div className="cost-form-input">
                    <div className="d-flex">
                      <Form.Group className="mb-3 mt-5 mr-v1" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" id="most_in_demand" name="most_in_demand" checked={formik.values.most_in_demand} onClick={() => formik.setFieldValue("most_in_demand", !formik.values.most_in_demand)} label="Most In Demand" />
                        {formik.errors.most_in_demand ? errorElement(formik.errors.most_in_demand) : null}
                      </Form.Group>

                      <Form.Group className="mb-3 mt-5" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" id="top_book" name="top_book" checked={formik.values.top_book} onClick={() => formik.setFieldValue("top_book", !formik.values.top_book)} label="Top Book" />
                        {formik.errors.top_book ? errorElement(formik.errors.top_book) : null}
                      </Form.Group>
                    </div>
                </div>

                {/* <div className="cost-form-input">

                  <Form.Group className="mb-3 mt-5" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" id="top_book" name="top_book" checked={formik.values.top_book} onClick={() => formik.setFieldValue("top_book", !formik.values.top_book)} label="Top Book" />
                    {formik.errors.top_book ? errorElement(formik.errors.top_book) : null}
                  </Form.Group>

                </div> */}

              </div>
              <div className="submit-cta">
                <CButton label="Submit" type="submit" custClassName="login-button t-btn"></CButton>
              </div>
            </Form>
          </div>
        </div>
      </div>
      {
        loading ? <Loading /> : null
      }
    </div>
  )
}

export default AddBook
