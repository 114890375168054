
export const validateResetPasswordForm = values => {
    const errors = {};

    if (!values.currentPassword) {
        errors.currentPassword = 'This field is required';
    }

    if (!values.newPassword) {
        errors.newPassword = 'This field is required';
    }else if (values.newPassword.length < 8) {
        errors.newPassword = 'Must be 8 characters or more';
    } else if (values.newPassword.length > 21) {
        errors.newPassword = 'Must be at most 20 characters';
    } else if (!/[a-z]/.test(values.newPassword)) {
        errors.newPassword = 'Must contain at least one lowercase letter';
    } else if (!/[A-Z]/.test(values.newPassword)) {
        errors.newPassword = 'Must contain at least one uppercase letter';
    } else if (!/\d/.test(values.newPassword)) {
        errors.newPassword = 'Must contain at least one digit';
    } else if (!/\W/.test(values.newPassword)) {
        errors.newPassword = 'Must contain at least one special character';
    }else if (values.newPassword === values.currentPassword) {
        errors.newPassword = 'Must not be same as current password';
    }

    if (!values.confirmPassword) {
        errors.confirmPassword = 'This field is required';
    } else if (values.newPassword && values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = 'Invalid confirm password';
    }

    return errors;
};


export const validateAddressForm = values => {
    const errors = {};

    if (!values.first_name) {
        errors.first_name = 'This field is required';
    }
    
    if (!values.street_address) {
        errors.street_address = 'This field is required';
    }
    if (!values.city) {
        errors.city = 'This field is required';
    }
    if (!values.zipcode) {
        errors.zipcode = 'This field is required';
    }

    if (!values.state) {
        errors.state = 'This field is required';
    }
    return errors;
};