import React, { useRef, useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import SlideMoreInfo from './SlideHover';
import { urlPrefix } from '../Helpers/Common';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getBooks, insertBookInReadingList } from '../Services/Books';
import { increaseCartQuantity } from '../../Redux/Slices/UserSlice';
import { toast } from 'react-toastify';
import { addToCart } from '../Services/Cart';
import Loading from './Loading';
import { LoginPopUp } from '.';

const MostDemandCarousel = () => {
  const [loading, setLoading] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const dispatch = useDispatch()
  const user = useSelector((state) => state.UserReducer.value);
  const splideRef = useRef(null);
  const navigate = useNavigate()
  const [books, setBooks] = useState([]);
  const [splideOptions,setSplideOptions] = useState({
    loop: true,
    start: 0,
    perPage: 2.5,
    perMove: 1,
    snap: false,
    interval: 5000,
    speed: 3000,  
    dragSpeed: 10000,
    autoplay: true,
    arrows: true,
    pagination: false,
    rewind: true,
    rewindByDrag: true,
    lazyLoad: true,
    drag: true,
    gap: '0px',
    type: 'loop',
    pauseOnHover: true,
    breakpoints: {
      768: { 
        perPage: 1.5,
        // snap: true
      },

      1366: {
        perPage: 2.5,
        gap: '30px',
      },

      1280: {
        perPage: 2.5,
        gap: '20px',
      },

      430: {
        perPage: 1.5,
        gap: '10px',
        padding: '0rem',
        interval: 5000,
        speed: 5000,
        dragSpeed  : 5000,
        pauseOnFocus:  true,
        autoplay: false,
      },
      414: {
        perPage: 1.5,
        gap: '10px', 
        padding: '0rem',
        interval: 5000,
        speed: 5000,
        dragSpeed  : 5000,
        pauseOnFocus:  true,
        autoplay: false,
      },
      375: {
        perPage: 1.5,
        gap: '10px',
        padding: '0rem',
        interval: 5000,
        speed: 5000,
        dragSpeed  : 5000,
        pauseOnFocus:  true,
        autoplay: false,
      },
    }
  })

  const getInitialPageData = async () => {
    setLoading(true)
    const result = await getBooks({ limit:9, include_categories:true, most_in_demand: true });
    if(result?.success){
      if(result.data.length < 3 ){
        setSplideOptions({...splideOptions,type:"fade"})
      }
      setBooks(result.data)
    }
    else{
      setBooks([])
    }
    setLoading(false)
  }

  const fxToRun = (data) => {
    navigate(urlPrefix() + data)
  }

  const addToReadingList = async (data) => {
    const { reading_list_id, book_id } = data;
    if (!user.authenticated) {
      navigate(urlPrefix() + "/login")
    }
    else if (reading_list_id) {
      navigate(urlPrefix() + "/subscription-account?key=Reading List");
      return
    }
    else {
      setLoading(true)
      let result = await insertBookInReadingList({ book_id });
      if (result?.success) {
        toast.success(result.message)
        getInitialPageData()
      } else {
        toast.error(result.error.message)
      }
      setLoading(false)
    }
  }

  const addToUsersCart = async (data) => {
    const { book_id, cart_id, order_id, order_status, returned_date, borrowing_id, user_id } = data;

    if (!user.authenticated) {
      navigate(urlPrefix() + "/login")
    }
    else if (cart_id) {
      navigate(urlPrefix() + "/order?key=OrderNow");
    }
    else if (order_id && user_id && ["pending", "approved"].includes(order_status)) {
      navigate(urlPrefix() + "/subscription-account?key=Order History");
    }
    else if (borrowing_id && !returned_date) {
      navigate(urlPrefix() + "/order?key=ReturnNow");
    }
    else {
      setLoading(true)
      const result = await addToCart({ book_id, cart_id: user.cart_id, quantity: 1 });
      if (result?.success) {
        toast.success(result.message)
        dispatch(increaseCartQuantity())
        getInitialPageData()
      } else {
        toast.error(result.error.message)
      }
      setLoading(false)
    }
  }

  const bookLabel = (data) => {
    const { cart_id, order_id, order_status, returned_date, borrowing_id, user_id } = data
    if (borrowing_id && !returned_date) {
      return "Borrowed"
    }
    else if (order_id && user_id && ["pending", "approved"].includes(order_status)) {
      return "Ordered"
    }
    else if (cart_id) {
      return "Go to Cart"
    }
    else {
      return "Add to Cart"
    }
  }

  useEffect(() => {
    getInitialPageData()
  }, [user.cart_quantity])

  return (
    <>
      {
        books && books.length ?
          <Splide
            hasTrack={true}
            aria-label="demand-sliders"
            options={splideOptions}
            ref={splideRef}
          >
            {
              books.map((item, index) => <SplideSlide key={index}>
                <div className="demand-card">
                  <div className="number">
                    <p>{index + 1}</p>
                  </div>
                  <div className="content">
                    <div className="card-image img-box">
                      <img src={item.image_url} alt="book imag"></img>
                    </div>
                    <div className="hero-hover">
                      <div className="hover-content">
                        <SlideMoreInfo label="More info" custClassName="info more-info" data={`/library-detail/${item.slug}`} fxToRun={fxToRun}></SlideMoreInfo>
                        <SlideMoreInfo label={item.reading_list_id ? "Reading List" : "Add to List"} fxToRun={addToReadingList} data={item} custClassName="info reading"></SlideMoreInfo>
                        <SlideMoreInfo label={bookLabel(item)} fxToRun={addToUsersCart} data={item} custClassName="info cart"></SlideMoreInfo>
                      </div>
                    </div>
                    <div className="detail">
                      <p className="category">{item?.categories.filter((category, index)=> index < 3).join(", ")}</p>
                      <h3 className="heading-h3 book-name" onClick={()=> navigate(urlPrefix() + `/library-detail/${item.slug}`)}>{item.title}</h3>
                      <p class="more-info"><div dangerouslySetInnerHTML={{ __html: item.synopsis }} /></p>
                    </div>
                  </div>
                </div>
              </SplideSlide>)
            }
          </Splide> :
          <div className="no-data">
            No Books found.
          </div>
      }

      {
        loading ? <Loading /> : null
      }
      <LoginPopUp showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal}/>
    </>
  );
};

export default MostDemandCarousel;
