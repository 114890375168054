import React, { useEffect, useState } from 'react'
import AdminNavbar from '../Navbar/AdminNavbar'
import { AuthorFilter, CButton, CategoryFilter, GeneralFilter, Loading, PublisherFilter } from '../../Components'
import { AdminDeleteBook, AdminUpdateBookStatus, AdminUpdateMostInDemandBook, getAdminBooks, uploadBooksCsv } from '../Services/Books';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { getAdminAuthors } from '../Services/Author';
import { getAdminPublishers } from '../Services/Publisher';
import { getAdminCategories } from '../Services/Categories';
import MakePagination from '../../Components/MakePagination';
import { urlPrefix } from '../../Helpers/Common';
import ConfirmationModal from './Components/ConfirmationModal';
import { useDebounce } from '../../../CustomHooks/DebounceHook';
import { useDispatch, useSelector } from 'react-redux';
import { changePage } from '../../../Redux/Slices/PaginationSlice';

const ListBooks = () => {
    const bookPagination = useSelector((state) => state.PaginationReducer.value)
    const [openedDropdown, setOpenedDropDown] = useState(null)
    const navigate = useNavigate();
    const [totalBooks,setTotalBooks] = useState(0);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [confirmationModal,setConfirmationModal] = useState({show:false, data: null, acceptFx: null, rejectFx: null,message:null});
    const [books, setBooks] = useState([]);
    const [categories, setCategories] = useState([bookPagination.categories]);
    const [authors, setAuthors] = useState([]);
    const [publishers, setPublishers] = useState([]);
    const [pageFilters, setPageFilters] = useState({ categories: [bookPagination.categories], authors: [bookPagination.author], publishers: [bookPagination.publisher] })
    const [pagination, setPagination] = useState({ limit: 10, start_from: bookPagination.startFrom, search: bookPagination.search , order_by: [{ column: 'books.id', order: 'desc' }] });
    const [search,setSearch] = useState(bookPagination.search);
    const debouncedSearch = useDebounce(search,500);
    const [csvError,setCsvError] = useState(false);
    const special = [{ id: 1, name: "Most in Demand", filter: "special" },{ id: 2, name: "Top Book", filter: "special" }]
    const dispatch = useDispatch();
    const [catName, setCatName] = useState(null);
    const [publisherName, setPublisherName] = useState(null);
    const [authorName, setAuthorName] = useState(null);
    const [specialName, setSpecialName] = useState(null);

    const selectFilter = (filter, data) => {
        if (filter === "category") {
            setPageFilters({ ...pageFilters, categories: [data ?? null] })
            setPagination({ ...pagination, start_from: 0 })
            dispatch(changePage({...bookPagination,categories:data, startFrom: 0, pageNumber:1}))
        }
        else if (filter === "author") {
            setPageFilters({ ...pageFilters, authors: [data ?? null] })
            setPagination({ ...pagination, start_from: 0 })
            dispatch(changePage({...bookPagination,author:data, startFrom: 0, pageNumber:1}))
        }
        else if (filter === "publisher") {
            setPageFilters({ ...pageFilters, publishers: [data ?? null] })
            setPagination({ ...pagination, start_from: 0 })
            dispatch(changePage({...bookPagination,publisher:data, startFrom: 0, pageNumber:1}))
        }
        else if (filter === "order") {
            setPagination({ ...pagination, order_by: [data] })
        }
        else if(filter === "pagination"){
            setPagination({...pagination, start_from:data})
        }
        else if (filter === "special") {
            delete pagination["most_in_demand"]
            delete pagination["top_book"]
            setPagination({
                ...pagination, start_from: 0,
                ...(data === "Most in Demand" ? { "most_in_demand": true } : {}),
                ...(data === "Top Book" ? { "top_book": true } : {})
            })
        }
        else if(filter === "search"){
            const getStartFrom = ((data && data.length) && bookPagination.pageNumber < 2) ? 0 : bookPagination.startFrom;
            console.log("searchval:- ",data)
            setPagination({ ...pagination, start_from: getStartFrom, search: data && data.length ? data.trim() : null })
            dispatch(changePage({...bookPagination,search: data}))
        }
    }


    const getBooksWithFilters = async () => {
        setLoading(true);
        var setFilterPagination = pagination;
        if (bookPagination?.special) {
            setFilterPagination = {...setFilterPagination, ...(bookPagination?.special?.name === "Most in Demand" ? { "most_in_demand": true } : {}),
            ...(bookPagination?.special?.name === "Top Book" ? { "top_book": true } : {})}
        }
        let result = await getAdminBooks({  filters: pageFilters, ...setFilterPagination, include_authors: true ,total_count_of:"books.id"})
        setBooks(result.data.books)
        setTotalBooks(result.data.totalBookRecords)
        setLoading(false);
    }


    const [csvToUpload, setCsvToUpload] = useState(null);

    const getInitialPageData = async () => {
        setLoading(true)
        const requests = [getAdminAuthors({ order_by: [{ column: 'authors.name', order: 'asc' }], remove_limit_offset: true }), getAdminPublishers({ order_by: [{ column: 'publishers.name', order: 'asc' }], remove_limit_offset: true }), getAdminCategories({order_by: [{ column: 'categories.name', order: 'asc' }], remove_limit_offset: true })];
        const result = await Promise.all(requests)
        setAuthors(result[0].data)
        setPublishers(result[1].data)
        setCategories(result[2].data)
        
        // for categories
        if (bookPagination?.categories) {
            const sortableOptions = result[2].data;
            for(var i=0; i < sortableOptions.length; i++) {
              if (sortableOptions[i].category_id == bookPagination?.categories) {
                  setCatName(sortableOptions[i].name);
                  break;
              }
            }
        }
        // for publishers
        if (bookPagination?.publisher) {
            const sortableOptions = result[1].data;
            for(var i=0; i < sortableOptions.length; i++) {
              if (sortableOptions[i].publisher_id == bookPagination?.publisher) {
                  setPublisherName(sortableOptions[i].name);
                  break;
              }
            }
        }
        // for authors
        if (bookPagination?.author) {
            const sortableOptions = result[0].data;
            for(var i=0; i < sortableOptions.length; i++) {
              if (sortableOptions[i].author_id == bookPagination?.author) {
                  setAuthorName(sortableOptions[i].name);
                  break;
              }
            }
        }

        setSpecialName(bookPagination.special)
        setLoading(false)
    }

    const updateBookStatus = async (data) => {
        const {book_id,status} = data
        setLoading(true);
        let result = await AdminUpdateBookStatus({book_id,status});
        if (result.success) {
            toast.success(result.message)
            getBooksWithFilters();
            getInitialPageData();
        }
        else {
            toast.error(result.error.message)
        }
        setConfirmationModal({show:false, data: null, acceptFx: null, rejectFx: null,message:null})
        setLoading(false);
    }

    const updateMostInDemandBook = async (data) => {
        const {book_id,most_in_demand} = data
        setLoading(true);
        let result = await AdminUpdateMostInDemandBook({book_id,most_in_demand});
        if (result.success) {
            toast.success(result.message)
            getBooksWithFilters();
            getInitialPageData();
        }
        else {
            toast.error(result.error.message)
        }
        setConfirmationModal({show:false, data: null, acceptFx: null, rejectFx: null,message:null})
        setLoading(false);
    }

    const uploadFunction = () => {
        document.getElementById("uploadBtn").onchange = function () {
            document.getElementById("uploadFile").value = this.value.replace("C:\\fakepath\\", "");
            setCsvToUpload(this.files[0])
        };
    }

    const uploadBooks = async (csv) => {
        if (csv) {
            setCsvError(false)
            setLoading(true);
            setShow(false);
            let result = await uploadBooksCsv(csv);
            if (result.success) {
                setPageFilters({ categories: [], authors: [], publishers: [] })
                setPagination({ limit: 10, start_from: bookPagination.startFrom, search: null , order_by: [{ column: 'books.id', order: 'desc' }] })
                getInitialPageData()
                toast.success(result.message)
            }
            else {
                toast.error(result.error.message)
            }
            setLoading(false);
        }
        else{
            setCsvError(true)
        }
    }

    const deleteBook = async (data) => {
        const {book_id} = data;
        setLoading(true);
        let result = await AdminDeleteBook({book_id});
        if (result.success) {
            if(totalBooks > 1){
                getBooksWithFilters()
            }
            else{
                setPageFilters({ categories: [], authors: [], publishers: [] })
                setPagination({ limit: 10, start_from: bookPagination.startFrom, search: null , order_by: [{ column: 'books.id', order: 'desc' }] })
            }
            toast.success(result.message)
        }
        else {
            toast.error(result.error.message)
        }
        setConfirmationModal({show:false, data: null, acceptFx: null, rejectFx: null,message:null})
        setLoading(false);
    }

    const generalFilterFx = (option) => {
        selectFilter("special", option?.name)
        dispatch(changePage({...bookPagination, special:option, startFrom: 0, pageNumber:1}))
    }

    useEffect(() => {
        getInitialPageData()
    }, [])

    useEffect(() => {
        getBooksWithFilters()
    }, [pageFilters, pagination])

    useEffect(()=>{
        selectFilter("search",search)
    },[debouncedSearch])


    return (
        <div className="admin-panel-main">
            <div className="panel-row">
                <div className="left">
                    <AdminNavbar></AdminNavbar>
                </div>
                <div className="right">

                    <div className="panel-title-top all-book">
                        <div className="top-left">
                            <h3 className="heading-h3">Books list</h3>
                        </div>
                        <div className="top-right admin-filter">
                            <div className="right-side">
                                <CButton fxToRun={() => window.location.reload()} label="Reset" custClassName="login-button t-btn clear-btn-v1"></CButton>
                                <CButton fxToRun={() => setShow(true)} label="Upload" custClassName="login-button t-btn upload-b"></CButton>
                                <CButton fxToRun={() => { navigate(urlPrefix() + "/admin/books/insert") }} data={csvToUpload} label="Add" custClassName="login-button t-btn add-b"></CButton>
                            </div>
                        </div>
                    </div>

                    <div className="dash-filter filter">
                        <div className="left-fil">
                            <div className="space">
                                <CategoryFilter openedDropdown={openedDropdown} dropdownId={1} setOpenedDropDown={setOpenedDropDown} options={categories} selectFilter={selectFilter} catid={catName}></CategoryFilter>
                            </div>
                            <div className="space">
                                <PublisherFilter openedDropdown={openedDropdown} dropdownId={2} setOpenedDropDown={setOpenedDropDown} options={publishers} selectFilter={selectFilter} publisherName={publisherName}></PublisherFilter>
                            </div>

                            <div className="space">
                                <AuthorFilter openedDropdown={openedDropdown} dropdownId={3} setOpenedDropDown={setOpenedDropDown} options={authors} selectFilter={selectFilter} author={authorName}></AuthorFilter>
                            </div>
                            <div className="space">
                                <GeneralFilter options={special} value="name" defaultValue="Special" fxToRun={generalFilterFx} clearText={"Select Special"} specialName={specialName} />
                            </div>
                        </div>
                        <div className="search">    
                            <InputGroup className="admin-search-input" onChange={(e)=>setSearch(e.target.value)} >
                                <Form.Control placeholder="Search ISBN, Title" value={search || ""}/>
                            </InputGroup>
                        </div>
                    </div>

                    <div className="admin-panel-data">
                        <div className="top-title">
                            <div className="p-img">
                                <p>&nbsp;</p>
                            </div>
                            <div className="order-no">
                                <p className="top-t">ISBN</p>
                            </div>
                            <div className="title">
                                <p className="top-t">Title</p>
                            </div>
                            <div className="author">
                                <p className="top-t">Author</p>
                            </div>
                            <div className="s-date">
                                <p className="top-t">Published Date</p>
                            </div>
                            <div className="e-date">
                                <p className="top-t">Quantity</p>
                            </div>
                            <div className="e-status">
                                <p className="top-t">In Demand</p>
                            </div>
                            <div className="e-status">
                                <p className="top-t">status</p>
                            </div>
                            <div className="e-edit-delete">
                                <p className="top-t">Actions</p>
                            </div>
                        </div>
                        <div className="book-list">
                            {
                                books && books.length ?
                                    books.map((book) => <div className="book-row" key={book.book_id}>
                                        <div className="p-img-value">
                                            <div className="book-image">
                                                <img src={book.image_url} alt='book-image' />
                                            </div>
                                        </div>
                                        <div className="order-no-value">
                                            <p className="top-value">{book.ISBN13 ?? book.ISBN10}</p>
                                        </div>
                                        <div className="title-value">
                                            <p className="top-value">{book.title}</p>
                                        </div>
                                        <div className="author-value">
                                            <p className="top-value">{ book?.authors ? book.authors.map((author) => author).join(", ") : "No author found."}</p>
                                        </div>
                                        <div className="s-date-value">
                                            <p className="top-value">{book?.date_published}</p>
                                        </div>
                                        <div className="e-date-value">
                                            <p className="top-value">{book.quantity_available}</p>
                                        </div>
                                        <div className="e-status-value">
                                            <button onClick={() => setConfirmationModal({
                                                show:true,
                                                data:{book_id:book.book_id, most_in_demand: !book.most_in_demand},
                                                acceptFx:updateMostInDemandBook,
                                                message:`Are you sure, you want to ${book.most_in_demand ? "Remove" : "Add"} book from most in demand ?`,
                                                rejectFx:()=>{setConfirmationModal({show:false, data: null, acceptFx: null, rejectFx: null, message:null})}
                                            }) 
                                            } className={ book.most_in_demand ? "toggle-on" : "toggle-off"}>
                                            </button>
                                            <div className="d-none">
                                                {
                                                    book.most_in_demand ? <div className="on">on</div> : <div className="off">of</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="e-status-value">
                                            <button onClick={() => setConfirmationModal({
                                                show:true,
                                                data:{book_id:book.book_id, status: !book.status},
                                                acceptFx:updateBookStatus,
                                                message:`Are you sure, you want to ${book.status ? "Inactive" : "Active"} book ?`,
                                                rejectFx:()=>{setConfirmationModal({ show:false, data: null, acceptFx: null, rejectFx: null, message:null})}
                                            })} className={ book.status ? "toggle-on" : "toggle-off"}>
                                            </button>
                                            <div className="d-none">
                                                {
                                                    book.status ? <div className="on">on</div> : <div className="off">of</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="e-edit-delete-value">
                                            <a className="edit-action" onClick={()=>navigate(urlPrefix() + `/admin/books/edit/${book.book_id}`)}><img src={urlPrefix() + "/assets/image/edit-green.png"} alt='book-image' /></a>
                                            <a className="delete-action" onClick={() => setConfirmationModal({
                                                show: true,
                                                data: { book_id: book.book_id },
                                                acceptFx: deleteBook ,
                                                message: `Are you sure, you want to delete book ?`,
                                                rejectFx: () => { setConfirmationModal({ show: false, data: null, acceptFx: null, rejectFx: null, message: null }) }
                                            })}
                                                href="#"><img src={urlPrefix() + "/assets/image/trash.svg"} alt='book-image' /></a>
                                        </div>
                                    </div>) :
                                    <div className="no-data">
                                        No Books found.
                                    </div>
                            }

                        </div>

                        {
                            books && books.length ?
                                <div className="books-pagination">
                                    <MakePagination totalRecords={totalBooks} limiter={10} selectFilter={selectFilter} startFrom={pagination.start_from}></MakePagination>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>

            <Modal className="cost-book-modal" show={show} size="md" onHide={() => {setShow(false);setCsvError(false)}} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Upload File
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="add-file-modal">
                        <div className="down-sample">
                        <a className="accept-reject" href={`${process.env.PUBLIC_URL}/sampleFiles/upload_csv_sample_file.csv`} download="upload_csv_sample_file" target="_blank" rel="noreferrer" >
                            Download Sample
                        </a>
                        </div>
                        <div className="custom-upload-file">
                            <input id="uploadFile" class="f-input" placeholder="Select your csv file" />
                            <div class="fileUpload btn btn--browse">
                                <span>Browse</span>
                                <input onClick={()=>uploadFunction()} id="uploadBtn" type="file" class="upload" accept=".csv"/>
                            </div>
                        </div>
                        <span className="text-danger">{csvError ? "Please choose a csv file" : ""}</span>
                        <div className="cta-book-mocal">
                            <CButton fxToRun={()=>{uploadBooks(csvToUpload)}} label="Upload" custClassName="login-button t-btn"></CButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {
                loading ? <Loading /> : null
            }
            <ConfirmationModal confirmationModal={confirmationModal}/>
        </div>

    )
}

export default ListBooks