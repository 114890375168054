import React from "react";
import { CButton } from "../Components";
import { urlPrefix } from "../Helpers/Common";
import {useNavigate} from "react-router-dom";

const OurStory = () => {
    const navigate = useNavigate()
  return (
    <React.Fragment>
   {/* our-story */}
    <div className="our-story-main">
        <div className="content">
            <h5 className="heading-h5 story-subtitle">Our Story</h5>
            <h1 className="heading-h1 story-title">Once Upon a Time</h1>
            <div className="blank-bottom">
                <p>&nbsp;</p>
            </div>
        </div>
    </div>

    <div className="our-story-s1">
        <div className="s1-row">
            <div className="left">
                <div className="s1-left">
                    {/* <h2 className="heading-h2 s-title">&nbsp;</h2> */}
                    <p className="s-content heading-h5">
                    House of Books was established to create a community of readers. As an affordable means for people to rediscover the pleasure of reading a good book.
                    </p>
                    <p className="s-bottom heading-h5">It started as a private collection of books we cherished but over the years as the collection grew we longed to share it with a wider community of book lovers.</p>
                </div>
            </div>
            <div className="right">
                <div className="r-image">
                    <div className="img-1">
                        <img src={urlPrefix() + "/assets/image/our-story-v1.png"} alt="book imag"></img>
                    </div>
                    <div className="img-1">
                        <img src={urlPrefix() + "/assets/image/our-story-v2.png"} alt="book imag"></img>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="our-story-s2">
        <h3 className="heading-h3 top-main">
        Every book that has a place in our library has a place in our hearts.
        </h3>
        <div className="row-s2">
            <div className="left-s2">
                <div className="image1">
                    <img src={urlPrefix() + "/assets/image/our-story-v3.png"} alt="book imag"></img>
                </div>
                <div className="image2">
                    <img src={urlPrefix() + "/assets/image/our-story-v4.png"} alt="book imag"></img>
                </div>
            </div>
            <div className="right-s2">
                <p className="s2-right heading-h5">Our library houses a wide range of titles from world classics to contemporary fiction, from biographies to poetry, from history to science fiction. We want to cater to the discerning reader who is looking for something beyond the standard offering of big chain bookstores and a more curated collection than online stores.</p>

                <div className="image2 for-mobile">
                    <img src={urlPrefix() + "/assets/image/brittney-weng.png"} alt="book imag"></img>
                </div>

                <h5 className="heading-h5 s2-content">Our recommendations are approved by readers not pushed by publishers.</h5>

                <p className="s2-bottom heading-h5">Today there are many ways to consume literature but ask any book lover and they’ll agree there’s something irreplaceable about a printed book. Perhaps it’s the tactile nature of a book in your hands or its ability to transport you back in time with the unmistakable woody scent of its well-worn pages.
At House of Books, our aim is to combine the convenience that today’s world demands, with the undeniable pleasure of reading a book by offering a fully
curated library and immediate delivery and pick up of your selected books. Join our library and cosy up with your next treasured read.</p>
            </div>
        </div>
    </div>

    <section className="picked-titles">
        <div className="house_container">
            <div className="picked">
            <div className="pic-cell">
                <div className="picked-card dark">
                <img src={urlPrefix() + "/assets/image/MainLogo.svg"} alt="book imag"></img>
                </div>
            </div>
            <div className="pic-cell border-right">
                <div className="picked-card">
                <div className="picked-main">
                    <p className="picked-title">500+</p>
                    <p className="detail">Hand-picked titles</p>
                    <button type="submit" onClick={()=>navigate(urlPrefix() +  "/library-overview")} className="cost-btn-w t-btn mobile">See All <span>&nbsp;</span></button>
                </div>
                </div>
            </div>
            <div className="pic-cell">
                <div className="picked-card">
                <div className="cta">
                    <CButton fxToRun={()=>navigate(urlPrefix() +  "/library-overview")} label="See All" custClassName="cost-btn-w t-btn"></CButton>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>

    <section className="becoming-member">
        <div className="house_container">
            <div className="image">
                <img src={urlPrefix() + "/assets/image/christin-hume.png"} alt="book imag"></img>
            </div>
            <div className="content">
            <h2 className="heading-h2">Becoming a Member is Easy.</h2>
            <p className="detail">Our members can borrow from a wide range of specially selected books with free pick-up and delivery.</p>
            <div className="cta">
                <CButton fxToRun={()=>navigate(urlPrefix() +  "/join-us")} label="Join Us" custClassName="white-btn-w t-btn"></CButton>
            </div>
            </div>
        </div>
    </section>

    {/* <section className="h-before-footer">
        <div className="house-row">
          <div className="title">
            <h4 className="heading-h2"><span>About</span> House of Books</h4>
          </div>
          <div className="detail">
            <p>House of Books was established to create a community of readers. As an affordable means for people to rediscover the pleasure of reading a… <a onClick={()=>navigate(urlPrefix() + "/our-story")}>Read More</a></p>
          </div>
          <div className="cta">
            <CButton fxToRun={()=>navigate(urlPrefix() +  "/library-overview")} label="Explore Everything" custClassName="cost-btn t-btn"></CButton>
          </div>
        </div>
      </section> */}

   {/* end */}
    </React.Fragment>
  );
};

export default OurStory;