import React from 'react'
import { Modal } from 'react-bootstrap'

const ConfirmationModal = ({ confirmationModal }) => {

    const { show, data, acceptFx, rejectFx, message = null } = confirmationModal;
    return (
        <Modal className="cost-book-modal con-check" show={show} size="md" onHide={() => rejectFx()} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title>{message ?? "Are you sure, You want to proceed?"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="accept-main">
                    <button onClick={() => acceptFx(data)} className="accept-reject">Yes</button>
                    <button onClick={() => rejectFx(data)} className="accept-reject">No</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmationModal;



