import React, { useEffect, useState } from 'react';
import CButton from './Buttons';
import { urlPrefix } from '../Helpers/Common';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { terminateSession } from '../../Redux/Slices/UserSlice';

const MobileMenu = ({closeMobileMenu,setCloseMobileMenu}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer.value);
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);

  const fxToRun = () => {
    if(user.authenticated){
      dispatch(terminateSession())
      navigate(urlPrefix() + "/")
    }else{
      navigate(urlPrefix() + "/login")
    }
    setIsOpen(false)
  }


  useEffect(()=>{
    if(closeMobileMenu){
      setIsOpen(false);
    }
  },[closeMobileMenu])


  return (
    <div className={`mobile-menu ${isOpen ? 'active' : ''}`}>
      {/* Button to toggle the menu */}
      <button onClick={()=>{setIsOpen(!isOpen);setCloseMobileMenu(isOpen)}}>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
      </button>
      
      {/* Menu items */}
      {isOpen && (
        <div className="mobile-menu-link">
          <div className="top-main">
            <a href="#" className="header-logo">
              <img className="fixed-menu-logo dsk-s1" src={urlPrefix() + "/assets/image/MainLogo.svg"} alt='login-logo' />
              <img onClick={() => { navigate(urlPrefix() + "/"); setIsOpen(false) }} className="fixed-for-ms1" src={urlPrefix() + "/assets/image/mobile-logo-a.svg"} alt='login-logo' />
            </a>
            <a href="#" className="sub-logo">
              <img className="fixed-menu-logo" src={urlPrefix() + "/assets/image/mobile-logo.png"} alt='login-logo' />
            </a>
            <div className="cta">
              <CButton fxToRun={fxToRun} label={user.authenticated ? "Logout" : "Become a Member"} custClassName="header-btn-y t-btn"></CButton>
            </div>
          </div>

          <ul className="mobile-link">
            <li className="link"><a className="cost-link active" onClick={() => { navigate(urlPrefix() + "/"); setIsOpen(false) }}>Home</a></li>
            <li className="link"><a className="cost-link" onClick={() => { navigate(urlPrefix() + "/our-story"); setIsOpen(false) }}>Our Story</a></li>
            <li className="link"><a className="cost-link" onClick={() => { navigate(urlPrefix() + "/library-overview"); setIsOpen(false) }}>The Library</a></li>
            <li className="link"><a className="cost-link" onClick={() => { navigate(urlPrefix() + "/join-us"); setIsOpen(false) }}>Join House of Books</a></li>
          </ul>

          <div className="mobile-social">
          <div className="f-right">
              <div className="f-pvc">
                <ul className="privacy-policy">
                  <li><a onClick={() => { navigate(urlPrefix() + "/privacy-policy"); setIsOpen(false) }}>Privacy Policy</a></li>
                  <li><a onClick={() => { navigate(urlPrefix() + "/faq"); setIsOpen(false) }}>FAQs</a></li>
                  <li><a onClick={() => { navigate(urlPrefix() + "/term"); setIsOpen(false) }}>Terms and Conditions</a></li>
                </ul>
              </div>
              <div className="f-social">
                <ul className="social-links">
                  <li className="insta-ico">
                      <a href="https://www.instagram.com/houseofbooks_ae/" target="_blank" title="instagram"></a>
                  </li>
                  <li className="facebook-ico">
                      <a href="https://www.facebook.com/HOB.library" target="_blank" title="facebook"></a>
                  </li>
                  <li className="linkedin-ico">
                      <a href="#" title="linkedin"></a>
                  </li>
                  <li className="x-logo-ico ">
                      <a href="https://twitter.com/houseofbooks_ae" target="_blank" title="twitter"></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      )}
    </div>
  );
};

export default MobileMenu;
