import axios from "axios";
import { provideToken } from "../Helpers/Common";

export const getBooks = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/books`, { params, headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const getSearchedBooks = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/searched-books`, { params, headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const getBooksMostInDemand = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/books/most-in-demand`, { params, headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const getSingleBook = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/books/single`,  { params, headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const insertBookInReadingList = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/books/reading-list/create`, params, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const removeBookFromReadingList = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/books/reading-list/remove`, params, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const sendOutOfStockNotification = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/books/send-notification`, params, { headers: { authorization: `Bearer ${provideToken()}` } });
        return data
    } catch (error) {
        return error?.response?.data
    }
}
